<template>
  <div class="content-checker__dashboard" style="padding-bottom: 400px">
    <v-card
      v-if="isShowProblemResolveForm"
      class="styled-card--light my-shadow--e4"
      style="position: fixed; left: 84px; bottom: 16px; z-index: 5"
    >
      <v-card-title class="d-flex" style="gap: 8px">
        <template v-if="selectedProblemExistInUploadedProblems">
          <span class="mr-2">I know this problem</span>
        </template>
        <template v-else> Problem</template>
        <v-chip
          label
          v-if="
            selectedProblems !== null &&
            selectedProblems !== undefined &&
            selectedProblemExistInUploadedProblems
          "
          close
          @click:close="selectedProblems = null"
        >
          <v-icon left small>mdi-tag</v-icon>
          {{ getSelectedProblem }}
        </v-chip>

        <v-progress-circular
          v-if="loadings.saving"
          indeterminate
          size="16"
          width="2"
          class="mr-2"
        />
        <v-chip
          label
          style="width: 88px"
          :color="`${!selectedProblemExistInUploadedProblems ? '' : 'success'}`"
          class="d-flex justify-center"
          @click="handleSaveProblems"
          :disabled="validateSaveProblem"
        >
          Save
        </v-chip>

        <v-divider vertical />

        <template v-if="!selectedProblemExistInUploadedProblems">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-chip label class="flat-chip flat-chip--warning" v-on="on">
                <v-icon small left>mdi-alert-outline</v-icon>
                Unable to mark
              </v-chip>
            </template>
            <div>
              This problem type does not exist in the list of available
              problems.
            </div>
          </v-tooltip>
        </template>
        <template v-else>
          <v-tooltip
            top
            v-if="
              Array.isArray(selectedTableItems) &&
              selectedTableItems?.length === 0
            "
          >
            <template #activator="{ on }">
              <v-chip label class="flat-chip flat-chip--warning" v-on="on">
                <v-icon small left>mdi-alert-outline</v-icon>
                Select urls
              </v-chip>
            </template>
            <div>Select URLs from the table.</div>
          </v-tooltip>
          <v-tooltip
            top
            v-if="selectedProblems === null || selectedProblems === undefined"
          >
            <template #activator="{ on }">
              <v-chip label class="flat-chip flat-chip--warning" v-on="on">
                <v-icon small left>mdi-alert-outline</v-icon>
                Select problem
              </v-chip>
            </template>
            <div>Select the problem from the input above.</div>
          </v-tooltip>
        </template>
      </v-card-title>

      <template
        v-if="selectedProblems === null || selectedProblemData === undefined"
      >
        <v-divider />
        <v-card-text>
          {{ selectedProblems }}
          <v-autocomplete
            v-model="selectedProblems"
            :items="problemsItems"
            filled
            clearable
            :disabled="!problemsItems"
            item-text="text"
            item-value="value"
            placeholder="Select problem"
            hide-details
            dense
          ></v-autocomplete>
        </v-card-text>
        <v-divider />
      </template>
    </v-card>
    <v-row dense>
      <v-col>
        <h2>Pages info - 4DX</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col
        v-if="!loadings.table"
        cols="12"
        class="d-flex"
        style="flex-wrap: wrap; gap: 8px"
      >
        <v-chip
          v-if="
            selectedProblemData && Object.keys(selectedProblemData)?.length > 0
          "
          :color="selectedProblemData.type"
          close
          style="font-size: 16px"
          @click:close="clearFilters(true)"
        >
          {{ selectedProblemData.name }} ({{ selectedProblemData.count }})
        </v-chip>
        <v-chip
          v-for="problem in tableProblemsData"
          :key="problem.id"
          style="font-size: 16px"
          :color="problem.type"
          @click="handleClickProblemAlert(problem)"
        >
          <v-icon
            :small="!settings.isBetterVisibilityModeOn"
            left
            style="color: inherit"
            >{{ getErrorIcon(problem.type) }}
          </v-icon>
          {{ problem.name }} ({{ problem.count }})
        </v-chip>
      </v-col>
      <v-col v-else cols="12" class="d-flex">
        <div
          v-for="i in [0, 1, 2, 3]"
          :key="i"
          class="skeleton mr-2"
          style="border-radius: 100px; width: 230px; height: 32px"
        ></div>
      </v-col>
    </v-row>
    <v-row dense class="mt-8" style="position: relative">
      <v-col cols="12">
        <v-row>
          <v-col class="d-flex" style="gap: 0.25rem">
            <v-chip
              :disabled="
                (Array.isArray(selectedTableItems) &&
                  selectedTableItems?.length === 0) ||
                loadings.table
              "
              label
              outlined
              @click="handleIgnoreProblems"
            >
              <v-icon small left>mdi-pause-octagon</v-icon>
              Ignore selected pages
            </v-chip>
            <v-chip
              label
              outlined
              @click="handleCreateTask"
              :disabled="
                (Array.isArray(selectedProblemData) &&
                  selectedProblemData?.length === 0) ||
                loadings.table
              "
            >
              <v-icon left small>mdi-plus</v-icon>
              Create a task on this problem
            </v-chip>

            <v-tooltip v-if="lastDateCrawl" bottom>
              <template v-slot:activator="{ on }">
                <v-chip style="border-style: dashed" label outlined v-on="on">
                  {{ lastDateCrawl }}
                  <v-icon small right>mdi-information-outline</v-icon>
                </v-chip>
              </template>
              <span>Last date of crawling</span>
            </v-tooltip>

            <tasks-list-button
              :loading="loadings.taskProblems"
              :items="taskProblemsData"
            />
          </v-col>
          <v-col class="d-flex justify-end" style="gap: 0.25rem">
            <v-item-group class="my-group">
              <v-chip outlined label @click="filtersDialogModel = true">
                <v-icon left small>mdi-filter</v-icon>
                Filters
                <v-tooltip v-if="getAmountOfFilters" top>
                  <template #activator="{ on }">
                    <div v-on="on">
                      <my-badge class="success white--text ml-1">
                        {{ getAmountOfFilters }}
                      </my-badge>
                    </div>
                  </template>
                  <div>{{ getAmountOfFilters }} active filters</div>
                </v-tooltip>
                <v-icon right small>mdi-chevron-down</v-icon>
              </v-chip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-chip
                    v-on="on"
                    outlined
                    label
                    class="error--text"
                    @click="clearFilters(true)"
                  >
                    <v-icon small>mdi-close</v-icon>
                  </v-chip>
                </template>
                <div>Clear all filters</div>
              </v-tooltip>
            </v-item-group>
            <v-chip
              class="d-flex justify-center"
              style="width: 100px"
              color="primary"
              label
              @click="handleGetData"
              :disabled="loadings.table"
            >
              <template v-if="loadings.table">
                <v-progress-circular indeterminate size="18" width="2" />
              </template>
              <template v-else> Get data</template>
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <v-card flat outlined rounded="lg">
              <v-card-title class="pa-3">
                <v-text-field
                  v-model="searchModel"
                  hide-details
                  dense
                  outlined
                  placeholder="Type to search"
                  append-icon="mdi-magnify"
                  style="max-width: 320px"
                />
              </v-card-title>
              <v-divider />
              <v-card-text class="px-0 py-3">
                <template v-if="loadings.table">
                  <v-skeleton-loader type="table" />
                </template>
                <template v-else>
                  <v-data-table
                    v-model="selectedTableItems"
                    show-select
                    :loading="loadings.table"
                    :search="searchModel"
                    :page.sync="tablePage"
                    :headers="tableHeaders"
                    :items="tableItems"
                    :custom-sort="customSort"
                    :sort-by="[
                      'priority',
                      'need_top',
                      'depth',
                      'links',
                      'cl',
                      'imr',
                    ]"
                    :sort-desc="[false, false, false, true, false, false]"
                    :footer-props="{
                      itemsPerPageOptions: [15, 25, 50, 100, 200, 500, -1],
                    }"
                    :items-per-page="100"
                    multi-sort
                  >
                    <template #[`item.anchor`]="{ item: { anchor } }">
                      <div style="max-width: 200px">
                        {{ anchor }}
                      </div>
                    </template>

                    <template #[`item.is_ignored`]="{ value }">
                      {{ String(value) !== "0" ? "Yes" : "" }}
                    </template>

                    <template #[`item.url`]="{ item: { url } }">
                      <div class="d-flex" style="max-width: 340px">
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <div v-on="on">{{ clearDomen(url) }}</div>
                          </template>
                          <div>{{ url }}</div>
                        </v-tooltip>
                        <v-btn @click="goTo(url)" icon x-small class="mr-3">
                          <v-icon>mdi-open-in-new</v-icon>
                        </v-btn>
                      </div>
                    </template>

                    <template #[`item.need_top`]="{ item: { need_top } }">
                      <div :class="`colored-td ${getNeedTopColor(need_top)}`">
                        {{ need_top }}
                      </div>
                    </template>

                    <template #[`item.google_index`]="{ value }">
                      <v-icon v-if="value === 1" color="success">
                        mdi-check
                      </v-icon>
                      <v-icon v-else-if="value === 0" color="error">
                        mdi-close
                      </v-icon>
                    </template>

                    <template #[`item.code`]="{ item }">
                      <template v-if="item.code !== '200'">
                        <a
                          :href="`https://bertal.ru/index.php?url=${item.url}`"
                          target="_blank"
                        >
                          <div :class="`colored-td ${getCodeColor(item.code)}`">
                            {{ item.code }}
                          </div>
                        </a>
                      </template>
                      <template v-else>
                        <div :class="`colored-td ${getCodeColor(item.code)}`">
                          {{ item.code }}
                        </div>
                      </template>
                    </template>

                    <template #[`item.depth`]="{ item: { depth } }">
                      <div :class="`colored-td ${getDepthColor(depth)}`">
                        {{ depth }}
                      </div>
                    </template>

                    <template #[`item.impressions`]="{ value }">
                      <div :class="`colored-td ${getImpressionsColor(value)}`">
                        {{ value }}
                      </div>
                    </template>

                    <template #[`item.uniq_anchors`]="{ item }">
                      <v-menu
                        content-class="remove-dialog-shadow"
                        nudge-right="54px"
                        nudge-bottom="-4px"
                        right
                      >
                        <template #activator="{ on }">
                          <div
                            v-on="on"
                            :class="`colored-td ${getAnchorColor(
                              item.uniq_anchors
                            )}`"
                            style="cursor: pointer"
                          >
                            {{ item.uniq_anchors }}
                          </div>
                        </template>
                        <div>
                          <v-btn
                            color="primary"
                            @click="goToLinkUrlControl(item)"
                            class="mr-4"
                            >Link URL control
                          </v-btn>
                          <v-btn color="primary" @click="goToInfoByPage(item)"
                            >Info by page
                          </v-btn>
                        </div>
                      </v-menu>
                    </template>

                    <template #[`item.url_length`]="{ item: { url_length } }">
                      <template
                        v-if="url_length === null || url_length === 'null'"
                      >
                        <!--                    <div class="colored-td red">none</div>-->
                        -
                      </template>
                      <template v-else>
                        <div
                          :class="`colored-td ${getUrlLengthColor(url_length)}`"
                        >
                          {{ url_length }}
                        </div>
                      </template>
                    </template>

                    <template #[`item.sensitivity_amount`]="{ item }">
                      <td-sensitive-amount :data="item" />
                    </template>

                    <template #[`item.amount`]="{ item }">
                      <td-amount :data="item" />
                    </template>
                    <template #[`item.ascii_in_html`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.ascii_in_text`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.ascii_in_url`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.ltt_domains`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.sl_domains`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.in_sm`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>

                    <template #[`item.crawled_at`]="{ item: { crawled_at } }">
                      <div
                        :class="`colored-td ${getDateColor(crawled_at)}`"
                        style="white-space: nowrap"
                      >
                        {{ $moment(crawled_at).format("MMM DD") }}
                      </div>
                    </template>
                    <template #[`item.sw_date`]="{ value }">
                      {{ value ? $moment(value).format("MMM DD") : "" }}
                    </template>
                    <template #[`item.first_seen`]="{ item: { first_seen } }">
                      <div
                        :class="`colored-td ${getFirstSeenColor(first_seen)}`"
                        style="white-space: nowrap"
                      >
                        {{
                          first_seen
                            ? $moment(first_seen).format("MMM DD YYYY")
                            : ""
                        }}
                      </div>
                    </template>
                  </v-data-table>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <agree-dialog
      :model-value="agreeDialogModel"
      @update:modelValue="agreeDialogModel = $event"
    >
      <template #title> Are you sure?</template>
      <template #default>
        <v-row dense>
          <v-col cols="12">
            <p>You want to ignore selected url's</p>
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <v-row dense no-gutters>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn @click="disagree" text color="grey">Back</v-btn>
            <v-btn @click="agree" color="success" class="ml-4 b-rb-shaped-4"
              >Yes
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </agree-dialog>
    <v-dialog
      v-model="filtersDialogModel"
      content-class="my-shadow--e2"
      scrollable
      eager
      width="880"
    >
      <v-card flat outlined rounded="lg">
        <v-card-title class="text-body-2">
          <b>Select filters</b>
          <v-spacer />
          <v-icon @click="filtersDialogModel = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4">
          <v-form ref="formRef">
            <v-card flat outlined rounded="lg">
              <v-card-title class="text-body-2"> Main filters</v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="filters.project"
                      label="Project"
                      :items="filtersData.projects"
                      outlined
                      dense
                      clearable
                      :loading="filtersDataPendingKeys?.projects"
                      :rules="computedProjectAndCategoriesRules"
                      hide-details="auto"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="filters.sub_project"
                      label="Subproject"
                      :items="filtersData.subprojects"
                      outlined
                      multiple
                      dense
                      clearable
                      :loading="filtersDataPendingKeys?.subprojects"
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="3">
                    <easy-range-input
                      label="Priority"
                      :model-value="filters.priority"
                      @update:modelValue="filters.priority = $event"
                      :presets="rangePresets"
                    />
                  </v-col>
                  <v-col cols="3">
                    <easy-range-input
                      label="Need top"
                      :model-value="filters.need_top"
                      @update:modelValue="filters.need_top = $event"
                      :presets="rangePresets"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete
                      v-model="filters.page_type"
                      label="Page type"
                      multiple
                      :items="filtersData.page_type"
                      :loading="loadings.filters"
                      item-text="text"
                      item-value="value"
                      outlined
                      dense
                      clearable
                      hide-details
                    >
                      <template #selection="{ item, index }">
                        <span v-if="index === 0">{{ item.text }}</span>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ filters.page_type?.length - 1 }} more)
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete
                      v-model="filters.code"
                      label="Codes"
                      multiple
                      :items="filtersData.code"
                      :loading="loadings.filters"
                      item-text="text"
                      item-value="value"
                      outlined
                      dense
                      chips
                      deletable-chips
                      clearable
                      hide-details
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="filters.ni"
                      label="Ni"
                      :items="filtersData.ni"
                      :loading="loadings.filters"
                      item-text="text"
                      item-value="value"
                      outlined
                      dense
                      multiple
                      clearable
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="filters.problems"
                      :items="filtersData.problems"
                      hide-details
                      dense
                      item-value="value"
                      item-text="text"
                      :loading="loadings.filters"
                      label="Problems"
                      chips
                      deletable-chips
                      clearable
                      outlined
                      multiple
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="filters.error_type"
                      :items="filtersData.error_type"
                      hide-details
                      dense
                      item-value="value"
                      item-text="text"
                      :loading="loadings.filters"
                      label="Error type"
                      chips
                      deletable-chips
                      clearable
                      outlined
                      multiple
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="filters.project_categories"
                      :items="filtersData.project_categories"
                      :loading="filtersDataPendingKeys?.project_categories"
                      :rules="computedProjectAndCategoriesRules"
                      multiple
                      hide-details
                      dense
                      outlined
                      label="Project categories"
                      placeholder="Type to search"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card flat outlined rounded="lg" class="mt-4">
              <v-card-title class="text-body-2">
                Additional filters</v-card-title
              >
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filters.relinking"
                          hide-details
                          dense
                          :items="filtersData.relinking"
                          outlined
                          :loading="loadings.filters"
                          label="Relinking"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <easy-range-input
                          label="Content Length"
                          :model-value="filters.content_length"
                          @update:modelValue="filters.content_length = $event"
                        />
                      </v-col>
                      <v-col cols="12">
                        <easy-range-input
                          label="Title Length"
                          :model-value="filters.title_length"
                          @update:modelValue="filters.title_length = $event"
                        />
                      </v-col>
                      <v-col cols="12">
                        <easy-range-input
                          label="Description Length"
                          :model-value="filters.description_length"
                          @update:modelValue="
                            filters.description_length = $event
                          "
                        />
                      </v-col>
                      <v-col cols="12">
                        <smart-date-filter
                          label="First seen"
                          :model-value="filters.first_seen"
                          @update:modelValue="filters.first_seen = $event"
                        />
                      </v-col>
                      <v-col cols="12">
                        <easy-range-input
                          label="Links"
                          :model-value="filters.links"
                          @update:modelValue="filters.links = $event"
                        />
                      </v-col>
                      <v-col cols="12">
                        <easy-range-input
                          label="Impression"
                          :model-value="filters.impression"
                          @update:modelValue="filters.impression = $event"
                        />
                      </v-col>
                      <v-col cols="12">
                        <easy-range-input
                          label="Depth"
                          :model-value="filters.depth"
                          @update:modelValue="filters.depth = $event"
                        />
                      </v-col>
                      <v-col cols="12">
                        <easy-range-input
                          label="Anchors"
                          :model-value="filters.anchors"
                          @update:modelValue="filters.anchors = $event"
                        />
                      </v-col>
                      <v-col cols="12">
                        <easy-range-input
                          label="Ltt Links, n"
                          :model-value="filters.ltt_links_n"
                          @update:modelValue="filters.ltt_links_n = $event"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col cols="12">
                        <easy-range-input
                          label="SL Links, n"
                          :model-value="filters.sl_links_n"
                          @update:modelValue="filters.sl_links_n = $event"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filters.is_ignored"
                          label="Is ignored"
                          dense
                          outlined
                          hide-details
                          clearable
                          :items="[
                            { text: 'Yes', value: 1 },
                            { text: 'No', value: 0 },
                          ]"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filters.sitemap"
                          label="Sitemap"
                          dense
                          outlined
                          hide-details
                          clearable
                          :items="filtersData.sitemap"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filters.non_ascii_text"
                          label="!ascii text"
                          dense
                          outlined
                          hide-details
                          clearable
                          :items="filtersData.non_ascii_text"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filters.non_ascii_html"
                          label="!ascii html"
                          dense
                          outlined
                          hide-details
                          clearable
                          :items="filtersData.non_ascii_html"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filters.non_ascii_url"
                          label="!ascii url"
                          dense
                          outlined
                          hide-details
                          clearable
                          :items="filtersData.non_ascii_url"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          dense
                          hide-details
                          :true-value="1"
                          :false-value="0"
                          v-model="filters.hide_ignore_pages"
                          style="padding-top: 0; margin: 0; height: 40px"
                          label="Hide ignore pages"
                          class="d-flex align-center"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          dense
                          hide-details
                          :true-value="1"
                          :false-value="0"
                          v-model="filters.hide_know_problems"
                          style="padding-top: 0; margin: 0; height: 40px"
                          label="Hide Kwon Problems"
                          class="d-flex align-center"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          dense
                          hide-details
                          :true-value="1"
                          :false-value="0"
                          v-model="filters.hide_whole_directories"
                          style="padding-top: 0; margin: 0; height: 40px"
                          label="Hide Whole Directories"
                          class="d-flex align-center"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          dense
                          hide-details
                          :true-value="1"
                          :false-value="0"
                          v-model="filters.without_keys"
                          style="padding-top: 0; margin: 0; height: 40px"
                          label="Without keys"
                          class="d-flex align-center"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="px-6 text-body-2"
            large
            @click="filtersDialogModel = false"
            :loading="loadings.table"
          >
            Back
          </v-btn>
          <v-btn
            class="px-6 text-body-2"
            large
            color="primary"
            @click="handleGetData"
            :loading="loadings.table"
          >
            Get data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DEFAULT_MENU_PROPS } from "@/utils/defaultData";
import Location from "@/mixins/Location";
import TdSensitiveAmount from "@/components/Dashboards/AnchorsInfoByPages/td/TdSensitiveAmount";
import TdAmount from "@/components/Dashboards/AnchorsInfoByPages/td/TdAmount";
import service from "@/plugins/service";
import EasyRangeInput from "@/components/UI/EasyRangeInput";
import Project from "@/mixins/Project";
import CopyUrlMixin from "@/mixins/CopyUrlMixin";
import AgreeDialogMixin from "@/mixins/AgreeDialogMixin";
import AgreeDialog from "@/components/UI/AgreeDialog";
import SmartDateFilter from "@/components/UI/SmartDateFilter";
import { DEFAULT_MOMENT_DATE_FORMAT } from "@/utils/defaultData";
import MyBadge from "@/components/UI/MyBadge.vue";
import TasksListButton from "@/components/Dashboards/ContentChecker/components/TasksListButton.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";

import {
  CONTENT_CHECKER_TABLE_HEADERS,
  PAGES_INFO_EMPTY_FILTERS,
} from "./defaults";

export default {
  components: {
    TasksListButton,
    MyBadge,
    SmartDateFilter,
    EasyRangeInput,
    TdAmount,
    TdSensitiveAmount,
    AgreeDialog,
  },
  mixins: [
    Location,
    FiltersHandling,
    UploadFiltersDataMixin,
    Project,
    CopyUrlMixin,
    AgreeDialogMixin,
  ],
  data: () => ({
    localStorageId: "content-checker-settings-14sdj8a",
    settings: {
      isBetterVisibilityModeOn: false,
    },
    filtersDialogModel: false,
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Dashboards",
        disabled: true,
      },
      {
        text: "Pages info",
        disabled: true,
      },
    ],
    selectedProblemData: null,
    selectedProblems: null,
    isShowProblemResolveForm: false,
    problemsItems: [],
    selectedTableItems: [],
    tableHeaders: CONTENT_CHECKER_TABLE_HEADERS,
    menuProps: DEFAULT_MENU_PROPS,
    searchModel: "",
    tableItems: [],
    problemsDialogModal: false,
    filtersData: {
      project_categories: [],
      sub_project: [],
      error_type: [],
      page_type: [],
      code: [],
      ni: [],
      relinking: [],
      problems: [],
      non_ascii_url: [],
      non_ascii_html: [],
      non_ascii_text: [],
      sitemap: [],
    },
    lastDateCrawl: null,
    tableProblemsData: null,
    taskProblemsData: null,
    loadings: {
      table: false,
      export: [],
      filters: false,
      problems: false,
      sub_project: false,
      taskProblems: false,
      saving: false,
    },
    tablePage: 1,
    priorityModel: [],
    rangePresets: [
      ["1 - 3", [1, 3]],
      ["1 - 5", [1, 5]],
      ["1 - 10", [1, 10]],
      ["1 - 50", [1, 50]],
      ["1 - 100", [1, 100]],
    ],
    problems: [],
    activeProblemId: null,
    filters: {
      project: null,
      project_categories: [],
      problems: [],
      sub_project: [],
      error_type: [],
      problem_id: null,
      priority: [null, null],
      need_top: [null, null],
      page_type: [],
      code: [],
      ni: [],
      relinking: null,
      content_length: [null, null],
      title_length: [null, null],
      description_length: [null, null],
      first_seen: [],
      links: [null, null],
      impression: [null, null],
      depth: [null, null],
      anchors: [null, null],
      is_ignored: 0,
      hide_ignore_pages: 1,
      hide_know_problems: 1,
      hide_whole_directories: 1,
      without_keys: 0,
      non_ascii_url: null,
      non_ascii_html: null,
      non_ascii_text: null,
      sitemap: null,
      ltt_links_n: [null, null],
      sl_links_n: [null, null],
    },
    asciiFilterItems: [
      {
        text: "Yes",
        value: 1,
      },
      {
        text: "No",
        value: null,
      },
    ],
  }),
  async mounted() {
    await this.parseQuery("filters");

    await this.uploadFiltersData(
      ["project_categories", "projects", "subprojects"],
      {
        project: this.filters.project,
      }
    );
    await this.fetchFilters(false);

    // Always run after filtersData.projects are initialized
    this.initializeDefaultProjectFilter();

    if (this.$route.query.problem_id) {
      this.selectedProblems = parseInt(this.$route.query.problem_id);
      this.isShowProblemResolveForm = true;
    }

    const localStorageSettings = localStorage.getItem(this.localStorageId);

    if (
      !["null", "undefined"].includes(localStorageSettings) &&
      localStorageSettings
    ) {
      this.initSettingsFromJsonString(localStorageSettings);
    }

    this.fetchTasksProblems();
    this.fetchProblemItems();
    this.fetchTableData();

    this.$watch("activeTaskId", () => {
      this.handleUpdateActiveProject();
      this.uploadFiltersData(["subprojects"], {
        project: this.filters.project,
      });
    });

    this.$watch("computedStoreTaskId", () => {
      this.initializeDefaultProjectFilter();
    });
  },
  computed: {
    computedProjectAndCategoriesRules() {
      let rules = [];

      if (!this.filters.project)
        rules.push("Select project or Project category.");
      if (!this.filters.project_categories?.length)
        rules.push("Select project or Project category.");

      if (this.filters.project || !!this.filters.project_categories?.length)
        rules = [];

      return rules;
    },
    activeTaskId() {
      return this.filters.project;
    },
    computedStoreTaskId() {
      return this.$store.getters["project/active_project"];
    },
    validateSaveProblem() {
      return (
        this.loadings.saving ||
        this.selectedProblems === null ||
        this.selectedProblems === undefined ||
        !this.selectedProblemExistInUploadedProblems ||
        (Array.isArray(this.selectedTableItems) &&
          this.selectedTableItems?.length === 0)
      );
    },
    selectedProblemExistInUploadedProblems() {
      if (
        this.selectedPrgoblems === null ||
        this.selectedProblems === undefined
      )
        return true;

      return (
        this.problemsItems.find((v) => v.value === this.selectedProblems) !==
        undefined
      );
    },
    getSelectedProblem() {
      try {
        return String(
          this.problemsItems.find((v) => v.value === this.selectedProblems).text
        ).toLowerCase();
      } catch {
        return "";
      }
    },
    getAmountOfFilters() {
      try {
        const filterNames = [
          "relinking",
          "content_length",
          "title_length",
          "description_length",
          "first_seen",
          "links",
          "impression",
          "depth",
          "anchors",
          "is_ignored",
          "sub_project",
          "priority",
          "need_top",
          "page_type",
          "code",
          "ni",
        ];

        let count = 0;

        const emptyRange = JSON.stringify([null, null]);
        const emptyArr = JSON.stringify([]);

        Object.keys(this.filters).forEach((key) => {
          if (!filterNames.includes(key)) return;
          const json = JSON.stringify(this.filters[key]);
          const value = this.filters[key];
          if (json !== emptyRange && json !== emptyArr && value !== null)
            count++;
        });

        return count;
      } catch {
        return null;
      }
    },
  },
  watch: {
    settings: {
      deep: true,
      handler(settings) {
        localStorage.setItem(this.localStorageId, JSON.stringify(settings));
      },
    },
  },
  methods: {
    initializeDefaultProjectFilter() {
      const queryProjectIdString = this.computedStoreTaskId;

      const isProjectIdFromQueryExistsInProjectsList =
        this.filtersData.projects.find(
          (project) => project.value === Number(queryProjectIdString)
        );

      if (isProjectIdFromQueryExistsInProjectsList) {
        this.filters.project = Number(this.computedStoreTaskId);
      }
    },
    async fetchTasksProblems() {
      if (!this.activeTaskId) {
        this.$refs.formRef?.validate();
        return (this.filtersDialogModel = true);
      }

      try {
        const url = `/content-checker/all-pages/problems/tasks/${this.activeTaskId}`;

        const payload = {
          error_name: this.selectedProblemData?.name,
        };

        this.loadings.taskProblems = true;

        const resp = await service.post(url, payload);

        if (resp && resp.data && resp.data.success) {
          this.taskProblemsData = resp.data.data;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.taskProblems = false;
      }
    },
    initSettingsFromJsonString(jsonString) {
      try {
        this.settings = JSON.parse(jsonString);
      } catch {
        console.error("Invalid settings json string");
      }
    },
    getErrorIcon(type) {
      const types = {
        error: "mdi-alert-circle",
        warning: "mdi-alert-circle-outline",
        info: "mdi-information-variant",
      };

      return types[type];
    },
    handleCreateTask() {
      try {
        const isErrorTechnical =
          this.selectedProblemData.category === "Technical";
        const today = this.$moment().format(DEFAULT_MOMENT_DATE_FORMAT);
        const weekAfter = this.$moment()
          .add(1, "weeks")
          .format(DEFAULT_MOMENT_DATE_FORMAT);

        const date = isErrorTechnical ? [today, null] : [today, weekAfter];

        const payload = {
          task_title: this.selectedProblemData.name + " - fix errors:",
          tags: "470853,994171",
          date,
          task_desc: window.location.href,
          priority: 8,
        };

        // TODO Потенциальный баг
        const routeData = this.$router.resolve({
          name: "similar-task-creating.to-those-responsible",
          query: { ...payload, project: this.tableItems[0].project_id },
        });
        window.open(routeData.href, "_blank");
      } catch (e) {
        console.error("Error while redirecting to create task page.", e);
      }
    },
    async handleIgnoreProblems() {
      try {
        const url = "/content-checker/all-pages/hide-ignore-pages";

        const payload = {
          urls: this.selectedTableItems.map((v) => v.id),
        };

        const resp = await service.post(url, payload);

        if (resp && resp.data && resp.data.status) {
          return this.$message.notification({
            title: "Success",
            text: "Selected URLs are ignored.",
            type: "success",
            duration: 6000,
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    clearFilters(fetch = false) {
      this._$clearQueryParams();
      this.activeProblemId = null;
      this.selectedProblems = null;
      this.isShowProblemResolveForm = false;

      this.filters = { ...this.filters, ...PAGES_INFO_EMPTY_FILTERS };
      if (fetch) this.fetchTableData();
    },
    async handleClickProblemAlert(problem) {
      try {
        const { filter } = problem;

        if (this.activeProblemId === problem.id) {
          this.activeProblemId = null;
          return;
        }

        this.clearFilters();

        this.activeProblemId = problem.id;

        Object.keys(filter).forEach((key) => {
          if (typeof filter[key] === "boolean") {
            this.filters[key] = filter[key] ? 1 : 0;
          } else {
            this.filters[key] = filter[key];
          }
        });

        if (filter.problem_id) {
          this.selectedProblems = parseInt(filter.problem_id);
          this.isShowProblemResolveForm = true;
        }

        await this.fetchTableData();
        this.fetchTasksProblems();
      } catch (e) {
        console.error(e);
      }
    },
    async fetchProblemItems() {
      this.loadings.problems = true;

      const url = "/content-checker/all-pages/problems";

      const resp = await service.post(url, {});

      this.loadings.problems = false;

      if (resp && resp.data && resp.data.status) {
        this.problemsItems = resp.data.items;
      }
    },
    async handleSaveProblems() {
      this.loadings.saving = true;

      const url = "/content-checker/all-pages/mark-problems";

      const payload = {
        urls: this.selectedTableItems.map((v) => v.id),
        problem: this.selectedProblems,
      };

      const resp = await service.post(url, payload);

      this.loadings.saving = false;

      if (resp && resp.data && resp.data.status) {
        this.$message.notification({
          title: "Saving",
          text: "Saving successfully done",
          type: "success",
        });
        this.selectedTableItems = [];
        this.selectedProblems = null;
        this.problemsDialogModal = false;
      }
    },
    getImpressionsColor(value) {
      if (parseInt(value) <= 50) {
        return "red";
      }
      return "";
    },
    getFirstSeenColor(date) {
      const today = this.$moment(new Date());
      const diffMonths = this.$moment(date).diff(today, "months");

      if (diffMonths >= -2 && diffMonths < 0) {
        return "green";
      }

      return "transparent";
    },
    goToLinkUrlControl(item) {
      const url = `/ltt/link-url-control/${item.id}`;
      window.open(url, "_blank");
    },
    goToInfoByPage(item) {
      if (!this.activeTaskId) {
        this.$refs.formRef?.validate();
        return (this.filtersDialogModel = true);
      }
      const routeData = this.$router.resolve({
        name: "dashboards.anchors-info-by-pages",
        query: {
          urlID: item.id,
          projectID: this.activeTaskId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    clearDomen(urlString = "") {
      const url = new URL(urlString);
      return url.pathname;
    },
    async handleUpdateActiveProject() {
      const selectedProject = this.activeTaskId;

      if (!selectedProject) return;

      this.filters.project = selectedProject;
      this.filters.sub_project = [];

      // this.fetchTableData();

      await this.fetchFilters(true);

      this.fetchProblemItems();
      this.fetchTasksProblems();
    },
    goTo(path) {
      window.open(path, "_blank");
    },
    handleRangeChange(event, query, valueType) {
      this[query] = event;
      if (this.isDefaultRange(event)) {
        event = null;
      }
      this._$setQueryParams(valueType, event);
    },
    handleGetData() {
      this.fetchTableData();
    },
    getDateColor(date) {
      const currDate = this.$moment(new Date());
      date = this.$moment(date);
      const diff = currDate.diff(date, "days");
      if (diff >= 1 && diff <= 7) return "yellow";
      if (diff > 8) return "red";
      return "";
    },
    getUrlLengthColor(value) {
      if (value < 500) return "red";
      if (value >= 501 && value <= 1000) return "yellow";
      return "";
    },
    getNeedTopColor(value) {
      if (value >= 1 && value <= 3) return "green";
      return "";
    },
    getCodeColor(value) {
      if (!value) return "";
      if (value[0] === "3" || value[0] === "5") return "yellow";
      if (value[0] === "4") return "red";
      return "";
    },
    getDepthColor(value) {
      if (String(value) === "-1") return "red";
      if (value <= 1) return "green";
      if (value >= 2 && value <= 3) return "yellow";
      if (value >= 4) return "red";
      return "";
    },
    getAnchorColor(value) {
      let cssClass = "default";
      if (value >= 1 && value <= 3) {
        cssClass = "red";
      }
      if (value >= 4 && value <= 5) {
        cssClass = "yellow";
      }
      if (value >= 6) {
        cssClass = "green";
      }
      return cssClass;
    },
    async fetchFilters(refresh = false) {
      if (!this.activeTaskId) {
        this.$refs.formRef?.validate();
        return (this.filtersDialogModel = true);
      }

      this.loadings.filters = true;
      const filters = [
        "sub_project",
        "non_ascii_url",
        "non_ascii_html",
        "non_ascii_text",
        "sitemap",
        "page_type",
        "code",
        "ni",
        "problems",
        "relinking",
        "error_type",
      ];
      const payload = {
        type: "/content-checker/all-pages",
        take: filters,
        filter: {
          projectID: this.activeTaskId,
        },
      };

      const storeFilters = this.$store.getters["contentChecker/getFiltersData"];

      if (storeFilters && !refresh) {
        this.loadings.filters = false;
        return (this.filtersData = { ...this.filtersData, ...storeFilters });
      }

      const resp = await this.$store.dispatch("global/getFilters", payload);

      if (resp) {
        const keys = Object.keys(resp);
        keys.forEach((key) => {
          this.filtersData[key] = resp[key];
        });
        this.$store.commit("contentChecker/setFilters", this.filtersData);
      }
      this.loadings.filters = false;
    },
    getRangeData(range) {
      return JSON.stringify(range) === JSON.stringify([null, null])
        ? []
        : range;
    },
    async fetchTableData() {
      if (!this.$refs.formRef.validate())
        return (this.filtersDialogModel = true);

      try {
        this.selectedTableItems = [];
        const project = this.activeTaskId;
        const priority = this.getRangeData(this.filters.priority);
        const need_top = this.getRangeData(this.filters.need_top);
        const content_length = this.getRangeData(this.filters.content_length);
        const title_length = this.getRangeData(this.filters.title_length);
        const description_length = this.getRangeData(
          this.filters.description_length
        );
        const links = this.getRangeData(this.filters.links);
        const impression = this.getRangeData(this.filters.impression);

        // Hardcode removing project_id from filters
        if (this.filters.project_id !== undefined)
          delete this.filters.project_id;

        const formData = {
          projectID: parseInt(project),
          filter: {
            ...this.filters,
            priority,
            need_top,
            content_length,
            title_length,
            description_length,
            links,
            impression,
          },
        };

        this.loadings.table = true;

        const resp = await this.$store.dispatch(
          "contentChecker/fetchTableData",
          formData
        );

        if (resp.success) {
          this.tableItems = resp.items;
          this.lastDateCrawl = resp.last_date_crawl;
          this.selectedProblemData = resp.problem_data;
          this.tableProblemsData = resp.problems;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.table = false;
        this.filtersDialogModel = false;
      }
    },
    isDefaultRange(data) {
      const defaultArr = JSON.stringify([null, null]);
      return JSON.stringify(data) === defaultArr;
    },
    customSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (sortBy[i] === "priority") {
            let aVal, bVal;
            if (isNaN(parseInt(a[sortBy[i]]))) aVal = 0;
            if (isNaN(parseInt(b[sortBy[i]]))) bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;
          }
          if (sortBy[i] === "need_top") {
            let aVal, bVal;
            if (isNaN(parseInt(a[sortBy[i]]))) aVal = 0;
            if (isNaN(parseInt(b[sortBy[i]]))) bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;
          }
          if (sortBy[i] === "cl") {
            let aVal, bVal;
            if (isNaN(parseInt(a[sortBy[i]]))) aVal = 0;
            if (isNaN(parseInt(b[sortBy[i]]))) bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;
          }
          if (sortBy[i] === "imr") {
            let aVal, bVal;
            if (isNaN(parseInt(a[sortBy[i]]))) aVal = 0;
            if (isNaN(parseInt(b[sortBy[i]]))) bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;
          }

          if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
          if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
  },
};
</script>
