<template>
  <v-card flat outlined rounded="lg">
    <v-card-title class="text-body-2 px-4 pb-2 pt-3">
      <template v-if="loading">
        <div class="skeleton" style="height: 20px; width: 130px" />
      </template>
      <template v-else>
        <span class="primary--text">{{ computedUrl?.host }}</span
        ><span class="success--text">{{ computedUrl?.pathname }}</span>
        <a :href="computedUrl?.href" target="_blank">
          <v-icon small right>mdi-open-in-new</v-icon>
        </a>
      </template>
      <v-spacer />
      <div class="d-flex" style="gap: 0.25rem">
        <v-btn
          class="px-2 text-normal"
          @click="isActionsDialogVisible = !isActionsDialogVisible"
        >
          Actions menu <v-icon small right>mdi-menu</v-icon>
        </v-btn>

        <v-btn
          style="min-width: 36px"
          class="px-2"
          :disabled="!!loadings.updateStatus"
          @click="$emit('closeDialog')"
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
        <v-dialog
          v-model="isActionsDialogVisible"
          bottom
          content-class="my-shadow--e2"
          rounded="lg"
          width="480"
        >
          <v-card flat outlined rounded="lg">
            <v-card-title class="text-body-2">
              Actions
              <v-spacer />

              <a href="#" target="_blank"
                >Statuses guide <v-icon small right>mdi-open-in-new</v-icon></a
              >
            </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="6">
                  <show-statuses-history-button :form="form" />
                </v-col>
                <v-col cols="6">
                  <show-fields-history-button :form="form" />
                </v-col>
                <v-col cols="6"> <problems-history :form="form" /> </v-col>
                <v-col cols="12">
                  <chat-button :form="form" />
                </v-col>
                <v-col cols="12">
                  <status-comment-form-component
                    :form="form"
                    @sent="handleCommentSent"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-card-title>
    <v-card-title class="px-4 pb-2 pt-0">
      <v-card
        flat
        outlined
        rounded="lg"
        width="100%"
        class="pa-0"
        style="font-size: 16px"
      >
        <div class="pa-4">
          <v-row dense>
            <v-col cols="4">
              <div style="line-height: 100%">
                <small>Current status</small>
              </div>
              <div>
                <template v-if="loading">
                  <div class="skeleton" style="height: 20px; width: 130px" />
                </template>
                <template v-else>
                  <b>{{ formValue?.status?.name }}</b>
                  <status-icon small color="primary" />
                </template>
              </div>
            </v-col>
            <v-col cols="4">
              <div style="line-height: 100%">
                <small>Page intent</small>
              </div>
              <div>
                <template v-if="loading">
                  <div class="skeleton" style="height: 20px; width: 130px" />
                </template>
                <template v-else>
                  <b v-if="formValue?.url_info?.intent">{{
                    formValue?.url_info?.intent
                  }}</b>
                  <small v-else style="opacity: 0.5">No intent...</small>
                </template>
              </div>
            </v-col>
            <v-col cols="4">
              <div style="line-height: 100%">
                <small>Page type</small>
              </div>
              <div>
                <template v-if="loading">
                  <div class="skeleton" style="height: 20px; width: 130px" />
                </template>
                <template v-else>
                  <b v-if="formValue?.url_info?.page_type">{{
                    formValue?.url_info?.page_type?.name
                  }}</b>
                  <small v-else style="opacity: 0.5">No intent...</small>
                </template>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-divider />
        <div class="pa-4 d-flex justify-space-between" style="gap: 0.25rem">
          <div style="flex-basis: 50%">
            <div class="d-flex flex-wrap" style="gap: 0.25rem">
              <template v-if="loading">
                <div
                  v-for="i in [0, 1]"
                  :key="i"
                  class="skeleton"
                  style="height: 36px; width: 120px"
                />
              </template>
              <template v-else-if="formValue?.recheck_items?.length">
                <v-btn
                  v-for="(button, idx) in formValue?.recheck_items"
                  :key="`recheck-${idx}`"
                  class="text-normal flat-chip flat-chip--warning"
                  @click="handleRecheck(button)"
                  :loading="loadings.updateStatus === button.pivot.button_text"
                  :disabled="
                    loadings.updateStatus !== null &&
                    loadings.updateStatus !== button.pivot.button_text
                  "
                >
                  <v-icon small left>mdi-arrow-left</v-icon>
                  {{ button?.pivot?.button_text }}
                </v-btn>
              </template>
              <template v-else>
                <small style="opacity: 0.5">No rechecks was assigned...</small>
              </template>
            </div>
          </div>
          <div style="flex-basis: 50%">
            <div class="d-flex justify-end flex-wrap" style="gap: 0.25rem">
              <template v-if="loading">
                <div
                  v-for="i in [0, 1, 2]"
                  :key="i"
                  class="skeleton"
                  style="height: 36px; width: 120px"
                />
              </template>
              <template v-else-if="formValue?.approve_items?.length">
                <v-btn
                  v-for="(button, idx) in formValue?.approve_items"
                  :key="`recheck-${idx}`"
                  class="text-normal flat-chip"
                  :class="{
                    'flat-chip--success': idx === 0,
                  }"
                  @click="handleApprove(button)"
                  :loading="loadings.updateStatus === button.pivot.button_text"
                  :disabled="
                    loadings.updateStatus !== null &&
                    loadings.updateStatus !== button.pivot.button_text
                  "
                >
                  {{ button?.pivot?.button_text }}
                  <v-icon small right>mdi-arrow-right</v-icon>
                </v-btn>
              </template>
              <template v-else>
                <small style="opacity: 0.5">No approves was assigned...</small>
              </template>
            </div>
          </div>
        </div>
      </v-card>
    </v-card-title>
    <v-card-text
      class="d-flex flex-column pa-0"
      style="gap: 0.5rem; font-size: 16px"
    >
      <div class="px-4 pb-4">
        <v-row dense>
          <v-col cols="3">
            <v-card flat outlined rounded="lg" style="height: 100%">
              <v-card-title v-if="false" class="text-body-2">
                Info fields
              </v-card-title>
              <v-card-text
                class="pa-0 pt-2"
                style="
                  font-size: 16px;
                  color: inherit;
                  overflow-x: hidden;
                  scrollbar-width: none;
                  height: 100%;
                "
              >
                <info-fields-zone :loading="loading" :form-value="formValue" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card flat outlined rounded="lg" style="height: 100%">
              <v-card-title v-if="false" class="text-body-2">
                Content fields
              </v-card-title>
              <v-card-text style="font-size: 16px; height: 100%">
                <template v-if="loading">
                  <div class="skeleton" style="height: 120px; width: 100%" />
                </template>
                <template v-else-if="formValue?.content_fields?.length">
                  <v-form ref="formRef">
                    <v-row>
                      <v-col
                        v-for="(field, idx) in formValue.content_fields"
                        cols="12"
                        :key="`field-${idx}`"
                      >
                        <element-control-field-factory
                          :field="field"
                          v-model="field.value"
                          :validation-result="validationResult"
                          @update:validationResult="validationResult = $event"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </template>
                <template v-else>
                  <div
                    class="text-center d-flex flex-column align-center pt-6"
                    style="height: 100%; opacity: 0.5"
                  >
                    <v-icon>mdi-border-none-variant</v-icon>
                    <small>
                      There are no fields to fill in at this step...
                    </small>
                  </div>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card flat outlined rounded="lg" style="height: 100%">
              <v-card-title class="pa-2 justify-center">
                <v-btn-toggle
                  v-model="visibleSidebarKey"
                  class="grouped-buttons d-flex w-full"
                  mandatory
                >
                  <v-btn
                    :disabled="!formValue?.status_comments?.length"
                    height="38"
                    text
                    class="text-normal flex-fill"
                    :value="SIDEBAR_KEYS.COMMENTS"
                  >
                    Comments
                    <v-badge
                      v-if="formValue?.status_comments?.length"
                      :content="formValue?.status_comments?.length"
                      inline
                    />
                  </v-btn>
                  <v-btn
                    :disabled="!formValue?.problems?.length"
                    height="38"
                    text
                    class="text-normal flex-fill"
                    :value="SIDEBAR_KEYS.PROBLEMS"
                  >
                    Problems
                    <v-badge
                      v-if="formValue?.problems?.length"
                      :content="formValue?.problems?.length"
                      inline
                    />
                  </v-btn>
                </v-btn-toggle>
              </v-card-title>
              <v-card-text
                v-if="
                  !visibleSidebarKey ||
                  visibleSidebarKey === SIDEBAR_KEYS.NOTHING
                "
              >
                <div
                  class="text-center d-flex flex-column align-center pt-6"
                  style="height: 100%; opacity: 0.5"
                >
                  <v-icon>mdi-border-none-variant</v-icon>
                  <small> No content available... </small>
                </div>
              </v-card-text>
              <v-card-text
                v-show="visibleSidebarKey === SIDEBAR_KEYS.COMMENTS"
                style="font-size: 16px; height: 100%"
                class="px-2"
              >
                <template v-if="loading">
                  <div class="d-flex flex-column" style="gap: 0.5rem">
                    <div class="skeleton" style="height: 160px; width: 100%" />
                    <div class="skeleton" style="height: 120px; width: 100%" />
                    <div class="skeleton" style="height: 120px; width: 100%" />
                  </div>
                </template>
                <template
                  v-else-if="!loading && formValue?.status_comments?.length"
                >
                  <v-row dense ref="commentsListRef">
                    <v-col
                      v-for="(comment, idx) in [
                        ...formValue.status_comments,
                      ].reverse()"
                      cols="12"
                      :key="`comment-${idx}`"
                    >
                      <v-card
                        flat
                        style="background: var(--card-darken-body-color)"
                        class="text-body-2 d-flex flex-column"
                      >
                        <v-tooltip
                          bottom
                          open-delay="100"
                          content-class="my-shadow--e2 pa-0 rounded-lg"
                        >
                          <template #activator="{ on }">
                            <div
                              v-on="on"
                              class="d-flex flex-column px-3 pb-0 pt-2"
                            >
                              <b>{{ comment?.user?.name || "Unknown user" }}</b>
                            </div>
                          </template>
                          <v-card
                            flat
                            outlined
                            rounded="lg"
                            class="pa-4 text-body-2"
                          >
                            <v-row dense>
                              <v-col cols="auto">
                                <v-avatar size="38">
                                  <v-img :src="comment?.user?.photo" />
                                </v-avatar>
                              </v-col>
                              <v-col cols="fill" class="d-flex flex-column">
                                <template v-if="comment?.user?.name">
                                  <div
                                    class="text-body-2"
                                    style="
                                      max-width: 200px;
                                      white-space: nowrap;
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                    "
                                    :title="comment?.user?.email"
                                  >
                                    <b>{{ comment?.user?.email }}</b>
                                  </div>
                                  <div class="text-body-2">
                                    {{ comment?.user?.name }}
                                  </div>
                                </template>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-tooltip>

                        <div class="d-flex flex-column">
                          <div class="px-3 pt-1">
                            {{ comment.comment }}
                          </div>
                          <div
                            class="pl-3 pt-0 pr-1 pb-1 text-end"
                            style="opacity: 0.5; line-height: 100%"
                          >
                            <small>
                              {{
                                $moment(comment?.created_at).isSame(
                                  new Date(),
                                  "day"
                                )
                                  ? $moment(comment?.created_at).format("hh:mm")
                                  : $moment(comment?.created_at).format("ll")
                              }}
                            </small>
                          </div>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <div
                    class="text-center d-flex flex-column align-center pt-6"
                    style="height: 100%; opacity: 0.5"
                  >
                    <v-icon>mdi-border-none-variant</v-icon>
                    <small> No comments... </small>
                  </div>
                </template>
              </v-card-text>
              <v-card-text
                v-show="visibleSidebarKey === SIDEBAR_KEYS.PROBLEMS"
                style="font-size: 16px; height: 100%"
                class="px-2 pt-0"
              >
                <div
                  v-if="formValue?.problems?.length"
                  class="d-flex flex-column px-0 pb-2"
                  style="gap: 0.25rem"
                >
                  <template v-for="(problem, idx) in formValue?.problems">
                    <problem-card :problem="problem" :key="`problem-${idx}`" />
                  </template>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <problem-dialog
      ref="problemDialog"
      v-model="aamIsAwaiting['select-problem']"
      @confirm="aamResolveAwaiting('resolve', 'select-problem', $event)"
      @close="aamResolveAwaiting('reject', 'select-problem', null)"
      :formData="form"
      :recheckButtonData="aamActiveSessionData"
    />
  </v-card>
</template>

<script>
import ElementControlFieldFactory from "./ElementControlFieldFactory.vue";
import { SiteWorkSheetApiService } from "../services/SiteWorkSheetApiService";
import StatusIcon from "@/components/SiteWorkplace/SiteWorksheet/components/StatusIcon.vue";
import { CONTENT_FIELD_TYPES } from "../defaults";
import ApiErrorHandlingMixin from "../../../../mixins/ApiErrorHandlingMixin";
import AwaitResolveMixin from "../../../../mixins/utils/AwaitResolveMixin";
import ProblemDialog from "./ProblemDialog.vue";
import ShowStatusesHistoryButton from "./ShowStatusesHistoryButton.vue";
import ShowFieldsHistoryButton from "./ShowFieldsHistoryButton.vue";
import InfoFieldsZone from "./InfoFieldsZone.vue";
import ChatButton from "./ChatButton.vue";
import StatusCommentFormComponent from "./StatusCommentFormComponent.vue";
import uploadFiltersDataMixin from "../../../../mixins/UploadFiltersDataMixin";
import ProblemsHistory from "./ProblemsHistory.vue";
import ProblemCard from "./ProblemCard.vue";

const SIDEBAR_KEYS = Object.freeze({
  COMMENTS: "comments",
  PROBLEMS: "problems",
  NOTHING: "nothing",
});

export default {
  components: {
    ProblemCard,
    ProblemsHistory,
    StatusCommentFormComponent,
    ChatButton,
    InfoFieldsZone,
    ShowFieldsHistoryButton,
    ShowStatusesHistoryButton,
    ProblemDialog,
    StatusIcon,
    ElementControlFieldFactory,
  },

  mixins: [ApiErrorHandlingMixin, AwaitResolveMixin, uploadFiltersDataMixin],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: ["statusSuccessfullyUpdated"],

  provide() {
    return {
      provideValidationResult: () => this.validationResult,
      provideFiltersData: () => ({
        filtersData: this.filtersData,
        filtersDataPendingKeys: this.filtersDataPendingKeys,
      }),
    };
  },

  mounted() {
    this.uploadFiltersData(["page_statuses"]);
  },

  data: () => ({
    loadings: {
      updateStatus: null,
    },
    SIDEBAR_KEYS,
    visibleSidebarKey: null,
    isActionsDialogVisible: false,
    isStatusesHistoryDialogVisible: false,
    isFieldsHistoryDialogVisible: false,
    filtersData: {
      page_statuses: [],
    },
  }),

  computed: {
    computedUrl() {
      return new URL(this.formValue?._ctx?.u);
    },
    formValue: {
      get() {
        return this.form;
      },
      set(value) {
        this.$emit("update:form", value);
      },
    },
  },

  watch: {
    formValue: {
      handler(formData) {
        this.handleFormChange(formData);
      },
      deep: true,
      immediate: true,
    },
    validationResult: {
      handler(newData) {
        this.$store.commit(
          "site-worksheets/setContentFieldValidationResults",
          newData
        );
      },
      deep: true,
    },
  },

  methods: {
    handleFormChange(formData) {
      this.initializeSelectedVisibilityKey(formData);
    },
    initializeSelectedVisibilityKey(formData) {
      const amountOfComments = formData?.status_comments?.length || 0;
      const amountOfProblems = formData?.problems?.length || 0;

      if (amountOfComments > 0)
        this.visibleSidebarKey = this.SIDEBAR_KEYS.COMMENTS;

      if (amountOfProblems > 0 && amountOfComments === 0)
        this.visibleSidebarKey = this.SIDEBAR_KEYS.PROBLEMS;

      this.visibleSidebarKey = this.SIDEBAR_KEYS.NOTHING;
    },
    handleCommentSent(newComments) {
      this.formValue.status_comments = newComments;
      this.isActionsDialogVisible = false;

      this.$nextTick(() => {
        this.$refs.commentsListRef.scrollTop =
          this.$refs.commentsListRef.scrollHeight;
      });
    },
    getValueFromContentField(fieldData) {
      if (fieldData?.type === CONTENT_FIELD_TYPES.PRICE) {
        try {
          const { currency, value } = fieldData;

          return {
            currency,
            value,
          };
        } catch (e) {
          console.error(
            "Trying to get currency and value values from fieldData.",
            e
          );
        }
      }

      return fieldData.value;
    },
    getPayload(buttonData) {
      const fields = {};

      this.formValue.content_fields.forEach((field) => {
        fields[field.id] = this.getValueFromContentField(field);
      });

      return {
        fields,
        status: buttonData.id,
      };
    },
    async handleRecheck(buttonData) {
      // Disable validation for rechecks
      // if (this.form.content_fields?.length && !this.$refs.formRef?.validate())
      //   return;

      try {
        const { payload: problemPayload } = await this.aamWait(
          "select-problem",
          { payload: buttonData }
        );

        this.loadings.updateStatus = buttonData.pivot.button_text;
        const { data } = await SiteWorkSheetApiService().updateStatus(
          this.form._ctx.url_id,
          {
            ...this.getPayload(buttonData),
            type: "recheck",
            problem_id: problemPayload.problem,
            comment: problemPayload.comment,
          }
        );

        if (data.status) {
          this.$emit("statusSuccessfullyUpdated");
          this.$refs.problemDialog?.resetForm();
        }
      } catch (e) {
        console.error("Error while rechecking status.", e);
        this.handleApiError(e);
      } finally {
        this.loadings.updateStatus = null;
      }
    },
    async handleApprove(buttonData) {
      if (this.form.content_fields?.length && !this.$refs.formRef?.validate())
        return;

      try {
        this.loadings.updateStatus = buttonData.pivot.button_text;
        const { data } = await SiteWorkSheetApiService().updateStatus(
          this.form._ctx.url_id,
          { ...this.getPayload(buttonData), type: "approve" }
        );

        if (data.status) this.$emit("statusSuccessfullyUpdated");
      } catch (e) {
        console.error("Error while approving status.", e);
        this.handleApiError(e);
      } finally {
        this.loadings.updateStatus = null;
      }
    },
  },
};
</script>
