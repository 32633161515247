<template>
  <v-container fluid style="padding-bottom: 200px" class="pa-0">
    <v-row>
      <v-col cols="12">
        <page-header
          title="Snippet Optimization Top 5 Pages"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'Snippet Optimization Top 5 Pages', disabled: true },
          ]"
        />
      </v-col>
    </v-row>
    <v-row dense class="mt-4">
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.executive"
          label="Executive"
          :items="filtersData.executive"
          item-text="text"
          item-value="value"
          outlined
          dense
          clearable
          hide-details
          @change="_$handleFilterChange($event, 'executive')"
          prepend-inner-icon="mdi-account-outline"
          :menu-props="menuProps"
          :loading="loadings.filters"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.niche"
          label="Niche"
          :items="filtersData.niche"
          item-value="id"
          item-text="name"
          outlined
          hide-details
          dense
          clearable
          @change="_$handleFilterChange($event, 'niche')"
          :menu-props="menuProps"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.page_type"
          label="Page Type"
          :items="filtersData.page_type"
          item-text="text"
          item-value="value"
          outlined
          dense
          clearable
          multiple
          @change="_$handleFilterChange($event, 'page_type')"
          hide-details
          :menu-props="menuProps"
          :loading="loadings.filters"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-checkbox
          v-model="filters.only_top_5"
          hide-details
          dense
          label="Only Top 5"
          @change="_$handleFilterChange($event, 'only_top_5')"
        >
        </v-checkbox>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-btn
          :loading="loadings.table"
          @click="fetchData"
          color="primary"
          height="40"
          block
          class="text-normal"
          >Get data
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              hide-details
              dense
              class="align-end"
            ></v-text-field>
          </v-card-title>
          <v-divider />
          <v-card-text class="headline font-weight-bold px-0">
            <v-row align="center" justify="center">
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :search="search"
                  :loading="loadings.table"
                  :sort-by="['prio', 'cl', 'imp']"
                  :sort-desc="[true, true, true]"
                  class="top-5-table"
                  multi-sort
                  :items-per-page="200"
                  :footer-props="{
                    'items-per-page-options': [100, 200, 300, 500, 1000, -1],
                  }"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr
                        v-for="item in items"
                        :key="item.url"
                        style="white-space: nowrap"
                        :class="checkIfDone(item)"
                      >
                        <td class="text-left">
                          {{ item.url }}
                          <div class="url-links">
                            <v-btn
                              icon
                              small
                              @click="goToGoogleGraph(item.url)"
                            >
                              <v-icon small>mdi-google</v-icon>
                            </v-btn>
                            <v-btn
                              icon
                              @click="goToSiteworkplace(item.url)"
                              small
                              class="ml-2"
                            >
                              <v-icon small>mdi-tab-search</v-icon>
                            </v-btn>
                          </div>
                        </td>
                        <td class="text-center">{{ item.cl }}</td>
                        <td class="text-center">{{ item.imp }}</td>
                        <td class="text-center">{{ item.phrases_top_1 }}</td>
                        <td class="text-center">{{ item.phrases_top_3 }}</td>
                        <td class="text-center">{{ item.phrases_top_5 }}</td>
                        <td class="text-center">
                          <v-icon v-if="item.title" color="success"
                            >mdi-check
                          </v-icon>
                          <v-icon v-else color="error">mdi-close</v-icon>
                        </td>
                        <td class="text-center">
                          <v-icon v-if="item.description" color="success"
                            >mdi-check
                          </v-icon>
                          <v-icon v-else color="error">mdi-close</v-icon>
                        </td>
                        <td class="text-center">
                          <v-icon v-if="item.table_of_contents" color="success"
                            >mdi-check
                          </v-icon>
                          <v-icon v-else color="error">mdi-close</v-icon>
                        </td>
                        <td class="text-center">
                          <v-icon v-if="item.faq" color="success"
                            >mdi-check
                          </v-icon>
                          <v-icon v-else color="error">mdi-close</v-icon>
                        </td>
                        <td class="text-center">
                          <v-icon v-if="item.introduction" color="success"
                            >mdi-check
                          </v-icon>
                          <v-icon v-else color="error">mdi-close</v-icon>
                        </td>
                        <td class="text-center">
                          <v-icon v-if="item.table" color="success"
                            >mdi-check
                          </v-icon>
                          <v-icon v-else color="error">mdi-close</v-icon>
                        </td>
                        <td class="text-center">
                          <v-icon v-if="item.table_casinos" color="success"
                            >mdi-check
                          </v-icon>
                          <v-icon v-else color="error">mdi-close</v-icon>
                        </td>
                        <td class="text-center">{{ item.page_type }}</td>
                        <td class="text-center">{{ item.prio }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DEFAULT_MENU_PROPS, TOP_5_TABLE_HEADERS } from "@/utils/defaultData";
import Location from "@/mixins/Location";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import PageHeader from "../../UI/PageHeader.vue";

export default {
  name: "Top5PageOptimization",
  components: { PageHeader },
  mixins: [Location, FiltersHandling],
  data: () => ({
    menuProps: DEFAULT_MENU_PROPS,
    loadings: {
      filters: false,
      table: false,
    },
    search: "",
    headers: TOP_5_TABLE_HEADERS,
    items: [],
    filter: {
      niche: 2,
    },
    menu_filter: false,
    filtersData: {
      niche: null,
      executive: null,
      page_type: [],
    },
    filters: {
      niche: null,
      only_top_5: false,
      executive: [],
      page_type: [],
    },
  }),
  computed: {
    getNiches() {
      return this.$store.state.reuse.niches;
    },
  },
  methods: {
    goToSiteworkplace(url = "") {
      const fixedUrl = url.replaceAll("https://", "").replaceAll("http://", "");
      const allowedProjects = this.$store.state.project.allowed_projects;
      let project = allowedProjects.find(
        (item) => item.domain === fixedUrl.split("/")[0]
      );
      if (!project) {
        project = fixedUrl.split("/")[0];
        const query =
          "/" +
          fixedUrl
            .split("/")
            .filter((item) => item !== project)
            .join("/");
        this.$message.notification({
          title: "Warning ✋",
          text: `<div>You do not have access to this project.</div><div class="button__wrapper"><button><a href="/site-workplace/dashboard?url=${query}" target="_blank">Go anyway</a></button></div>`,
          duration: 10000,
          type: "warning",
        });
        return;
      }
      const query =
        "/" +
        fixedUrl
          .split("/")
          .filter((item) => item !== project.domain)
          .join("/");
      const path = `/site-workplace/dashboard?url=${query}&project_id=${project.id}`;

      window.open(path, "_blank");
    },
    checkIfDone(item) {
      const {
        faq,
        introduction,
        table,
        table_casinos,
        table_of_contents,
        title,
        description,
      } = item;
      if (
        faq &&
        introduction &&
        table &&
        table_casinos &&
        table_of_contents &&
        title &&
        description
      ) {
        return "";
      }
      return "not-done";
    },
    goToGoogleGraph(url) {
      const fixedURL = url.replaceAll("https://", "").replace("http://", "");
      const certificate =
        url.indexOf("https://") === -1 ? "http://" : "https://";

      const splitPath = fixedURL.split("/");

      const domen = splitPath[0];
      const page = splitPath.join("/");

      const params = [
        `?resouce_id=${encodeURIComponent(certificate + domen + "/")}`,
        "&breakdown=page",
        `&page=!${encodeURIComponent(certificate + page)}`,
        "&num_of_days=28",
      ];
      const googleSearchCondoleURL =
        "https://search.google.com/search-console/performance/search-analytics";
      console.dir(googleSearchCondoleURL + params.join(""));
      window.open(googleSearchCondoleURL + params.join(""), "_blank");
    },
    handleFilterChange(newValue, type) {
      const isArray = Array.isArray(newValue);
      if (isArray) {
        newValue = newValue.map((item) => {
          if (item === null) {
            item = "null";
          }
          return String(item);
        });
      }
      if (isArray && newValue.length === 0) {
        newValue = null;
      }
      this._$setQueryParams(type, newValue);
    },
    customSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        if (!sortDesc) {
          return this.compare(a, b);
        } else {
          return this.compare(b, a);
        }
      });
      return items;
    },
    compare(a, b) {
      let a_prio = a.prio === 0 ? -1 : a.prio;
      let b_prio = b.prio === 0 ? -1 : b.prio;

      if (a_prio < b_prio) return 1;
      if (a_prio > b_prio) return -1;

      if (a.cl < b.cl) return 1;
      if (a.cl > b.cl) return -1;

      if (a.imp < b.imp) return 1;
      if (a.imp > b.imp) return -1;

      return 0;
    },
    collectParamsFromQuery() {
      const keys = Object.keys(this.$route.query);
      keys.forEach((key) => {
        const value = this.$route.query[key];
        const numeric = !isNaN(value);

        if (key === this.FILTERS_QUERY_IDS.PAGE_TYPE) {
          const result = value.split(",").map((item) => Number(item));
          return (this[key] = result);
        }

        if (numeric) {
          return (this[key] = Number(value));
        }
        return (this[key] = value);
      });
      if (keys.length > 0) {
        this.fetchData();
      }
    },
    async fetchFilters() {
      this.filtersData.niche = this.getNiches;
      //
      this.loadings.filters = true;
      const filters = ["executive", "page_type"];
      const payload = {
        type: "/dashboards/summary/top-5-page-optimization",
        take: filters,
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        const keys = Object.keys(resp);
        keys.forEach((key) => {
          this.filtersData[key] = resp[key];
        });
      }
      this.loadings.filters = false;
    },
    async fetchData() {
      try {
        this.loadings.table = true;

        const formData = {
          filter: this.filters,
        };

        const resp = await this.$store.dispatch(
          "top5PageOptimization/fetchTableData",
          formData
        );
        this.loadings.table = false;

        if (resp) {
          this.items = resp.items;
        }
      } catch {
        this.loadings.table = false;
      }
    },
  },
  created() {
    this._$collectParams(["page_type", "executive"], "filters");

    this.fetchFilters();
    this.fetchData();
  },
};
</script>

<style scoped></style>
