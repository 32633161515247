<template>
  <v-dialog
    v-model="dialog"
    content-class="my-shadow--e2"
    width="420"
    scrollable
  >
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2 bold">
        Edit selected item <v-icon small right>mdi-pen</v-icon>
        <v-spacer />
        <v-icon @click="dialog = false" small>mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-form ref="formRef">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.name"
                hide-details="auto"
                dense
                outlined
                label="Name"
                :error-messages="validationResult['name']"
                @change="resetErrors('name')"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.pattern"
                hide-details="auto"
                dense
                outlined
                label="Pattern"
                :error-messages="validationResult['pattern']"
                @change="resetErrors('pattern')"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="form.country_id"
                :items="filtersData?.countries"
                hide-details="auto"
                dense
                outlined
                label="Country"
                :error-messages="validationResult['country_id']"
                @change="resetErrors('country_id')"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="form.language_id"
                :items="filtersData?.languages"
                hide-details="auto"
                dense
                outlined
                label="Language"
                :error-messages="validationResult['language_id']"
                @change="resetErrors('language_id')"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.priority"
                type="number"
                hide-details="auto"
                dense
                outlined
                label="Priority"
                :error-messages="validationResult['priority']"
                @change="resetErrors('priority')"
              />
            </v-col>
            <v-col cols="12">
              <pretty-checkbox-filter
                v-model="form.is_active"
                :true-value="1"
                :false-value="0"
                label="Is active"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn large class="px-6 text-normal" @click="dialog = false">
          Back
        </v-btn>
        <v-btn
          large
          class="px-6 text-normal"
          color="success"
          @click="handleSave"
          :loading="pending"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../../../mixins/Dialog";
import { DomainSubfolderApiService } from "../../../services/DomainSubfolderApiService";
import Notification from "../../../../../mixins/Notification";
import ApiErrorHandlingMixin from "../../../../../mixins/ApiErrorHandlingMixin";
import PrettyCheckboxFilter from "../../../../../components/UI/PrettyCheckboxFilter.vue";

export default {
  components: { PrettyCheckboxFilter },
  props: ["filtersData", "data"],

  mixins: [Dialog, Notification, ApiErrorHandlingMixin],

  data: () => ({
    pending: false,
    form: {
      pattern: null,
      name: null,
      country_id: null,
      language_id: null,
      priority: 0,
      is_active: 0,
      id: null,
    },
  }),

  watch: {
    data: {
      handler(value) {
        if (!value) return;
        this.form = { ...value };
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    getFixedForm(formData) {
      const result = { ...formData };

      Object.keys(formData).forEach((key) => {
        if (
          result[key] === undefined ||
          result[key] === null ||
          result[key] === ""
        )
          delete result[key];
      });

      return result;
    },
    async handleSave() {
      try {
        this.pending = true;

        const fixedForm = this.getFixedForm(this.form);

        await DomainSubfolderApiService().update(fixedForm);

        this.dialog = false;

        this.mSuccess({
          title: "Success",
          text: "Changes saved.",
        });

        this.$emit("success");
      } catch (e) {
        console.error("Error while saving new items.", e);
        this.handleApiError(e);
      } finally {
        this.pending = false;
      }
    },
  },
};
</script>
