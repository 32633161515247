<template>
  <div>
    <v-container fluid v-if="isset_active_project">
      <v-row>
        <v-col cols="12">
          <page-header
            title="Progress Links Dashboard"
            :breadcrumbs="[
              { text: 'Home', to: '/' },
              { text: 'Progress Links Dashboard', disabled: true },
            ]"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-card flat outlined rounded="lg" v-if="isset_active_project">
            <v-card-title style="gap: 0.5rem">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                outlined
                hide-details
                clearable
                dense
                placeholder="Type to search"
                class="align-end"
              ></v-text-field>
              <v-menu
                transition="slide-x-transition"
                :close-on-content-click="false"
                :nudge-right="200"
                v-model="modal.shown"
                :value="modal.shown"
              >
                <template v-slot:activator="{ on }">
                  <v-btn class="text-normal" color="primary" v-on="on">
                    Filters<v-icon small right>mdi-filter</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <v-list>
                      <v-list-item>
                        <smart-date-filter
                          :model-value="filter.date_range"
                          @update:modelValue="filter.date_range = $event"
                        />
                      </v-list-item>
                      <v-list-item>
                        <v-select
                          dense
                          v-model="filter.niche"
                          :items="niches"
                          item-value="id"
                          outlined
                          item-text="name"
                          label="Niche"
                          hide-details
                        ></v-select>
                      </v-list-item>
                      <v-list-item>
                        <v-select
                          dense
                          v-model="filter.projects"
                          :items="projects"
                          item-value="id"
                          outlined
                          item-text="name"
                          label="Projects"
                          hide-details
                          multiple
                        ></v-select>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action>
                          <v-switch
                            v-model="filter.last_month"
                            :false-value="0"
                            :true-value="1"
                          ></v-switch>
                        </v-list-item-action>
                        <v-list-item-title>Last month</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action>
                          <v-switch
                            v-model="filter.hide_disavowed_links"
                            :false-value="0"
                            :true-value="1"
                          ></v-switch>
                        </v-list-item-action>
                        <v-list-item-title
                          >Hide disavowed links</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action>
                          <v-switch
                            v-model="filter.on_page"
                            :false-value="0"
                            :true-value="1"
                          ></v-switch>
                        </v-list-item-action>
                        <v-list-item-title>On page</v-list-item-title>
                      </v-list-item>
                    </v-list>
                    <div class="text-center">
                      <v-btn @click="fetch_data" color="primary"> Apply </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-data-table
                :search="search"
                :headers="table.headers"
                :items="table.items"
                :sort-by="['priority']"
                :sort-desc="[true]"
                :items-per-page="-1"
                dense
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr
                      v-for="(item, index) in items"
                      :key="index"
                      style="white-space: nowrap"
                    >
                      <td style="padding: 1px 6px">
                        {{ item.project }}
                      </td>
                      <td class="text-center" style="padding: 1px 6px">
                        {{ item.plan }}
                      </td>
                      <td
                        class="text-center test"
                        style="padding: 1px 6px"
                        :style="get_style_row(item.lag)"
                      >
                        <span v-if="item.fact && item.fact.all"
                          ><a
                            :href="get_link_to_ltt(item.id)"
                            target="_blank"
                            >{{ item.fact.all }}</a
                          ><sup v-if="item.fact && item.fact.nofollow">{{
                            item.fact.nofollow
                          }}</sup>
                        </span>
                        <span v-if="item.fact && item.fact.on_page">
                          ({{ item.fact.on_page
                          }}<span v-if="item.fact && item.fact.gi"
                            >/{{ item.fact.gi }}</span
                          >)</span
                        >
                      </td>
                      <td
                        class="text-center test"
                        :style="get_style_row(item.lag)"
                        style="padding: 1px 6px"
                      >
                        {{ item.done }}% ({{ item.done_on_page }}%)
                      </td>
                      <td
                        class="text-center test"
                        :style="get_style_row(item.lag)"
                        style="padding: 1px 6px"
                      >
                        {{ item.lag }}% ({{ item.lag_on_page }}%)
                      </td>
                      <td
                        v-for="n in table.columns.type"
                        :key="n"
                        class="text-center"
                        style="padding: 1px 6px"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              label
                              small
                              v-bind="attrs"
                              v-on="on"
                              :style="
                                get_style_row(
                                  get_lag(
                                    item.type[n],
                                    item.percentage_of_completion
                                  )
                                )
                              "
                              v-if="item.type[n] && item.type[n].all"
                            >
                              <div>
                                <span v-if="item.type[n] && item.type[n].all"
                                  ><a
                                    :href="
                                      get_link_to_ltt(
                                        item.id,
                                        item.type[n].type_id
                                      )
                                    "
                                    target="_blank"
                                    >{{ item.type[n].all.all }}</a
                                  ><sup
                                    v-if="
                                      item.type[n] &&
                                      item.type[n].all &&
                                      item.type[n].all.nofollow
                                    "
                                    >{{ item.type[n].all.nofollow }}</sup
                                  > </span
                                ><span
                                  v-if="item.type[n] && item.type[n].on_page"
                                >
                                  ({{ item.type[n].on_page.all
                                  }}<sup
                                    v-if="
                                      item.type[n] &&
                                      item.type[n].on_page &&
                                      item.type[n].on_page.nofollow
                                    "
                                    >{{ item.type[n].on_page.nofollow }}</sup
                                  > </span
                                ><span v-if="item.type[n] && item.type[n].gi"
                                  >-{{ item.type[n].gi.all
                                  }}<sup
                                    v-if="
                                      item.type[n] &&
                                      item.type[n].gi &&
                                      item.type[n].gi.nofollow
                                    "
                                    >{{ item.type[n].gi.nofollow }}</sup
                                  >)</span
                                >
                              </div>
                            </v-chip>
                            <v-chip
                              label
                              small
                              v-bind="attrs"
                              v-on="on"
                              :style="
                                get_style_row(
                                  get_lag(
                                    item.type[n],
                                    item.percentage_of_completion
                                  )
                                )
                              "
                              v-else-if="item.type[n] && item.type[n].plan"
                            >
                              0
                            </v-chip>
                          </template>
                          <span v-if="item.type[n] && item.type[n].plan">{{
                            item.type[n].plan
                          }}</span>
                        </v-tooltip>
                        <div
                          v-if="item.type[n] && item.type[n].check"
                          style="color: green"
                        >
                          {{ item.type[n].check }}
                        </div>
                        <div v-if="item.type[n] && item.type[n].plan">
                          {{ item.type[n].plan }}:<span
                            v-for="(item, index) in item.type[n].link_task"
                            :key="index"
                          >
                            <a :href="item.link" target="_blank">
                              <v-icon small>{{ item.icon }}</v-icon>
                            </a></span
                          >
                        </div>
                      </td>
                      <td style="padding: 1px 6px">
                        {{ item.niche }}
                      </td>
                      <td style="padding: 1px 6px">
                        {{ item.seo }}
                      </td>
                      <td class="text-center" style="padding: 1px 6px">
                        {{ item.priority }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
      v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            class="text-normal"
            dark
            @click="$store.dispatch('toggle_select_project_menu')"
            >Select project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import axios from "axios";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
import PageHeader from "../../UI/PageHeader.vue";

let dateFormat = require("dateformat");
let date = new Date();

export default {
  name: "ProgressLinksDashboardIndex",
  components: { PageHeader, SmartDateFilter },
  metaInfo: {
    title: "Progress Links Dashboard - LTT - SI",
  },
  mixins: [FiltersHandling],
  data() {
    return {
      date_modal: false,
      search: "",
      modal: {
        shown: false,
      },
      loadings: {
        fetch_data: false,
      },
      filter: {
        date_range: [
          dateFormat(
            new Date(date.getFullYear(), date.getMonth(), 1),
            "yyyy-mm-dd"
          ),
          dateFormat(
            new Date(date.getFullYear(), date.getMonth() + 1, 0),
            "yyyy-mm-dd"
          ),
        ],
        niche: false,
        projects: [],
        last_month: false,
        hide_disavowed_links: true,
        on_page: false,
      },
      table: {
        headers: [],
        items: [],
        columns: [],
      },
    };
  },
  methods: {
    fetch_data() {
      let self = this;
      self.modal.shown = false;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("fetch_data", true);

        let url = `${self.$store.state.server_url}/ltt/progress-links-dashboard`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          filter: this.filter,
        };

        self.table.items = [];
        self.table.headers = [];
        self.table.columns = [];

        axios
          .post(url, data, config)
          .then(function (response) {
            self.table.items = response.data.data;
            self.table.headers = response.data.headers;
            self.table.columns = response.data.columns;
            self.update_loading("fetch_data", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    hash_code(str) {
      let hash = 0,
        i,
        chr;
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    to_color(num) {
      num >>>= 0;
      var b = num & 0xff,
        g = (num & 0xff00) >>> 8,
        r = (num & 0xff0000) >>> 16,
        a = ((num & 0xff000000) >>> 24) / 10;
      return "rgba(" + [r, g, b, a].join(",") + ")";
    },
    get_style_project(name) {
      let val = this.hash_code(name);
      return (
        "border: 1px solid " +
        this.to_color(val) +
        "; padding: 7px;border-radius: 10px; "
      );
    },
    get_link_to_ltt(project, type = false) {
      let url =
        "/ltt/index?date_range=" +
        this.filter.date_range +
        "&project=" +
        project;
      if (type) {
        url += "&type=" + type;
      }
      return url;
    },
    get_lag(item, percentage_of_completion) {
      let lag = 100;
      if (item && item.plan && item.on_page) {
        lag =
          ((item.plan * percentage_of_completion - item.on_page.all) /
            (item.plan * percentage_of_completion)) *
          100;
      }
      return lag;
    },
    get_style_row(val) {
      let color = "";
      if (val) {
        if (val > 50) {
          color = "rgba(255,112,112,0.14)";
        } else if (val > 20) {
          color = "rgba(255,244,131,0.14)";
        }
      }
      return "background-color:" + color;
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
    niches() {
      return this.$store.state.reuse.niches;
    },
    projects() {
      return this.$store.state.project.allowed_projects;
    },
  },
  async mounted() {
    this.parseQuery("filter");

    if (this.niches.length === 0) this.$store.dispatch("fetch_niches_list");

    this.fetch_data();

    this.$watch("id_project", (newProjectid) => {
      if (newProjectid) this.fetch_data();
    });
  },
};
</script>

<style scoped></style>
