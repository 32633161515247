<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          title="Base of freelance"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'Base of freelance', disabled: true },
          ]"
        />
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.languages"
              :items="filtersData.languages"
              hide-details="auto"
              dense
              outlined
              label="Languages"
              multiple
              :error-messages="validationResult?.['languages']"
              @change="resetErrors('languages')"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.niches"
              :items="filtersData.niches"
              hide-details="auto"
              dense
              outlined
              label="Niches"
              multiple
              :error-messages="validationResult?.['niches']"
              @change="resetErrors('niches')"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.statuses"
              :items="filtersData.freelancer_statuses"
              hide-details="auto"
              dense
              outlined
              label="Statuses"
              multiple
              :error-messages="validationResult?.['statuses']"
              @change="resetErrors('statuses')"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <easy-range-input
              :model-value="filters.rate"
              @update:modelValue="filters.rate = $event"
              label="Rate"
              :error-messages="validationResult?.['rate']"
              @change="resetErrors('rate')"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <easy-range-input
              :model-value="filters.optimisation_rate"
              @update:modelValue="filters.optimisation_rate = $event"
              label="Optimization rate"
              :error-messages="validationResult?.['optimisation_rate']"
              @change="resetErrors('optimisation_rate')"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <easy-range-input
              :model-value="filters.urgency_rate"
              @update:modelValue="filters.urgency_rate = $event"
              label="Urgency rate"
              :error-messages="validationResult?.['urgency_rate']"
              @change="resetErrors('urgency_rate')"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.payment_methods"
              :items="filtersData.payment_methods"
              hide-details="auto"
              dense
              outlined
              label="Payment methods"
              multiple
              :error-messages="validationResult?.['payment_methods']"
              @change="resetErrors('payment_methods')"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.seo_knowledge"
              :items="filtersData.freelancer_base_selects"
              hide-details="auto"
              dense
              outlined
              label="SEO Knowledge"
              multiple
              :error-messages="validationResult?.['seo_knowledge']"
              @change="resetErrors('seo_knowledge')"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.niche_knowledge"
              :items="filtersData.freelancer_base_selects"
              hide-details="auto"
              dense
              outlined
              label="Niche knowledge"
              multiple
              :error-messages="validationResult?.['niche_knowledge']"
              @change="resetErrors('niche_knowledge')"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.text_quality"
              :items="filtersData.freelancer_text_qualities"
              hide-details="auto"
              dense
              outlined
              label="Text quality"
              multiple
              :error-messages="validationResult?.['text_quality']"
              @change="resetErrors('text_quality')"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <smart-date-filter
              label="Date added"
              :model-value="filters.date_added"
              @update:modelValue="filters.date_added = $event"
              :error-messages="validationResult?.['date_added']"
              @change="resetErrors('date_added')"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-btn
              @click="initializeTableData"
              :loading="loadings.table"
              height="40"
              color="primary"
              class="text-normal"
              block
            >
              Apply <v-icon small right>mdi-filter</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="d-flex pb-0" style="gap: 0.5rem">
        <v-btn
          class="text-normal"
          height="40"
          @click="
            isCreateNewFreelancerDialogVisible =
              !isCreateNewFreelancerDialogVisible
          "
        >
          Add freelancer <v-icon small right>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title style="gap: 0.5rem">
            <v-text-field
              v-model="search"
              hide-details
              dense
              outlined
              placeholder="Type to search"
              append-icon="mdi-magnify"
              clearable
            />
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0">
            <v-data-table
              :search="search"
              :headers="tableData.headers"
              :items="tableData.items"
              :loading="loadings.table"
              multi-sort
            >
              <template
                v-for="h in tableData.headers"
                #[`header.${h.value}`]="{ header }"
              >
                <template v-if="header.title">
                  <v-tooltip bottom :key="h.value">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        {{ header.text }}
                        <v-icon small>mdi-information-outline</v-icon>
                      </span>
                    </template>
                    <div style="max-width: 150px">
                      {{ header.title }}
                    </div>
                  </v-tooltip>
                </template>
                <template v-else>
                  <span :key="h.value">{{ header.text }}</span>
                </template>
              </template>
              <template #[`item.name`]="{ value }">
                <span style="white-space: nowrap">{{ value }}</span>
              </template>
              <template #[`item._actions`]="{ item }">
                <v-menu
                  bottom
                  nudge-bottom="32"
                  content-class="my-shadow--e2"
                  :close-on-content-click="false"
                >
                  <template #activator="{ on }">
                    <v-btn v-on="on" style="min-width: 32px" class="px-2">
                      <v-progress-circular
                        v-if="
                          [loadings.edit, loadings.delete].includes(item.id)
                        "
                        size="16"
                        width="2"
                        indeterminate
                      />
                      <v-icon v-else small>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-card flat outlined rounded="lg">
                    <v-card-title class="text-body-2"> Actions </v-card-title>
                    <v-card-text class="px-0">
                      <v-list dense class="pa-0">
                        <v-list-item @click="handleEditFreelancer(item)">
                          <v-list-item-icon class="mr-2">
                            <v-progress-circular
                              v-if="loadings.edit === item.id"
                              width="2"
                              size="16"
                              indeterminate
                            />
                            <v-icon v-else small>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title> Edit </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="handleDeleteFreelancer(item)"
                          class="error--text"
                        >
                          <v-list-item-icon class="mr-2">
                            <v-progress-circular
                              v-if="loadings.delete === item.id"
                              width="2"
                              size="16"
                              indeterminate
                            />
                            <v-icon v-else small color="error">
                              mdi-trash-can
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title> Delete </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
              <template #[`item.niches`]="{ value }">
                <div
                  class="d-flex flex-wrap justify-center py-1"
                  style="gap: 0.25rem"
                  v-if="value?.length"
                >
                  <v-chip
                    v-for="(chip, idx) in value"
                    :key="`${idx}${chip.id}`"
                    small
                  >
                    {{ chip.name }}
                  </v-chip>
                </div>
              </template>
              <template #[`item.payment_methods`]="{ value }">
                <div
                  class="d-flex flex-wrap justify-center py-1"
                  style="gap: 0.25rem"
                  v-if="value?.length"
                >
                  <v-chip
                    v-for="(chip, idx) in value"
                    :key="`${idx}${chip.id}`"
                    small
                  >
                    {{ chip.name }}
                  </v-chip>
                </div>
              </template>
              <template #[`item.seo_knowledge`]="{ item }">
                {{ item.seo_knowledge_name }}
              </template>
              <template #[`item.niche_knowledge`]="{ item }">
                {{ item.niche_knowledge_name }}
              </template>
              <template #[`item.text_quality`]="{ item }">
                {{ item.text_quality_name }}
              </template>
              <template #[`item.languages`]="{ value }">
                <div
                  class="d-flex flex-wrap justify-center py-1"
                  style="gap: 0.25rem"
                  v-if="value?.length"
                >
                  <v-chip
                    v-for="(chip, idx) in value"
                    :key="`${idx}${chip.id}`"
                    small
                  >
                    {{ chip.language }}
                  </v-chip>
                </div>
              </template>
              <template #[`item.created_at`]="{ value }">
                {{ value ? $moment(value).format("ll") : "" }}
              </template>
              <template #[`item.updated_at`]="{ value }">
                {{ value ? $moment(value).format("ll") : "" }}
              </template>
              <template #[`item.nda`]="{ value }">
                <span :class="value ? 'success--text' : 'error--text'">
                  {{ value ? "Yes" : "No" }}
                </span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <create-freelancer-dialog
      v-model="isCreateNewFreelancerDialogVisible"
      :form="createNewFreelancerForm"
      @update:form="createNewFreelancerForm = $event"
      @success="handleSuccessCreated"
    />
    <edit-freelancer-dialog
      v-model="isEditNewFreelancerDialogVisible"
      :form="editFreelancerForm"
      @update:form="editFreelancerForm = $event"
      @success="handleSuccessEdited"
    />
    <v-dialog
      v-model="aamIsAwaiting['delete']"
      width="320"
      content-class="my-shadow--e2"
    >
      <v-card flat outlined rounded="lg">
        <v-card-title class="text-body-2"> Delete freelancer </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          Are you sure you want to delete "{{ aamActiveSessionData?.name }}"
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            large
            class="px-6 text-normal"
            @click="aamResolveAwaiting('reject', 'delete')"
          >
            No, leave
          </v-btn>
          <v-btn
            color="error"
            large
            class="px-6 text-normal"
            @click="aamResolveAwaiting('resolve', 'delete')"
          >
            Yes, delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "../UI/PageHeader.vue";
import { FREELANCER_DATA_TABLE_HEADERS } from "./defaults";
import CreateFreelancerDialog from "./components/CreateFreelancerDialog.vue";
import uploadFiltersDataMixin from "../../mixins/UploadFiltersDataMixin";
import { ContentFreelancerApiService } from "./services/ContentFreelancerApiService";
import EditFreelancerDialog from "./components/EditFreelancerDialog.vue";
import awaitResolveMixin from "../../mixins/utils/AwaitResolveMixin";
import EasyRangeInput from "../UI/EasyRangeInput.vue";
import SmartDateFilter from "../UI/SmartDateFilter.vue";
import FiltersHandling from "../../mixins/components/FiltersHandling";
import apiErrorHandlingMixin from "../../mixins/ApiErrorHandlingMixin";

export default {
  components: {
    SmartDateFilter,
    EasyRangeInput,
    EditFreelancerDialog,
    CreateFreelancerDialog,
    PageHeader,
  },

  mixins: [
    uploadFiltersDataMixin,
    awaitResolveMixin,
    FiltersHandling,
    apiErrorHandlingMixin,
  ],

  data: () => ({
    filters: {
      languages: [],
      payment_methods: [],
      statuses: [],
      niches: [],
      rate: [null, null],
      optimisation_rate: [null, null],
      urgency_rate: [null, null],
      seo_knowledge: [],
      niche_knowledge: [],
      text_quality: [],
      date_added: [],
    },
    search: null,
    isCreateNewFreelancerDialogVisible: false,
    loadings: {
      table: false,
      edit: null,
      delete: null,
    },
    isEditNewFreelancerDialogVisible: false,
    createNewFreelancerForm: {},
    editFreelancerForm: {},
    tableData: {
      headers: FREELANCER_DATA_TABLE_HEADERS,
      items: [],
    },
    filtersData: {
      languages: [],
      freelancer_statuses: [],
      freelancer_base_selects: [],
      freelancer_text_qualities: [],
      payment_methods: [],
      niches: [],
    },
  }),

  async mounted() {
    await this.parseQuery("filters");
    await this.initializeFiltersData();
    this.initializeTableData();
  },

  methods: {
    async handleDeleteFreelancer(tableRow) {
      try {
        await this.aamWait("delete", { payload: tableRow });

        this.loadings.delete = tableRow.id;

        const { data } = await ContentFreelancerApiService().deleteFreelancer(
          tableRow.id
        );

        if (data.success) {
          this.tableData.items = this.tableData.items.filter(
            (item) => item.id !== tableRow.id
          );
        }
      } catch (e) {
        console.error("Error while delete freelancer.", e);
        this.handleApiError(e);
      } finally {
        this.loadings.delete = null;
      }
    },
    handleSuccessEdited() {
      this.initializeTableData();
    },
    handleSuccessCreated(newFreelancerData) {
      this.tableData.items.push(newFreelancerData);
    },
    async handleEditFreelancer(tableRow) {
      try {
        this.loadings.edit = tableRow.id;

        const { data } =
          await ContentFreelancerApiService().getFreelancerDetails(tableRow.id);

        if (data) {
          this.editFreelancerForm = this.freelancerFormAdapter(data);
          this.isEditNewFreelancerDialogVisible = true;
        }
      } catch (e) {
        console.error("Error while loading freelanced details.", e);
      } finally {
        this.loadings.edit = null;
      }
    },
    freelancerFormAdapter(formData) {
      // Проблема: с бека приходят элементы массива в виде объектов, а инпуты требуют только айдишники.

      return {
        ...formData,
        niches: formData?.niches?.map((item) => item.id),
        languages: formData?.languages?.map((item) => item.id),
        payment_methods: formData?.payment_methods?.map((item) => item.id),
      };
    },
    getFixedPayload() {
      const result = { ...this.filters };

      Object.entries(result).forEach(([key]) => {
        if (
          Array.isArray(result[key]) &&
          (result[key]?.length === 0 || result[key].every((e) => e === null))
        ) {
          result[key] = undefined;
        }
      });
      return result;
    },
    async initializeTableData() {
      try {
        const fixedPayload = this.getFixedPayload();

        this.loadings.table = true;
        const { data } = await ContentFreelancerApiService().getFreelancers(
          fixedPayload
        );

        this.tableData.items = data.data;
      } catch (e) {
        console.error("Error while loading freelancers list.", e);
      } finally {
        this.loadings.table = false;
      }
    },
    async initializeFiltersData() {
      await this.uploadFiltersData([
        "languages",
        "freelancer_statuses",
        "freelancer_base_selects",
        "freelancer_text_qualities",
        "payment_methods",
        "niches",
      ]);

      this.$store.commit("bof/setFiltersData", this.filtersData);
    },
  },
};
</script>
