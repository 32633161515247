<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          :title="pageConfigs.title"
          :breadcrumbs="pageConfigs.breadcrumbs"
        />
      </v-col>
      <v-col cols="12" class="pb-0">
        <v-text-field
          v-model="search"
          hide-details
          dense
          append-icon="mdi-magnify"
          outlined
          placeholder="Type to search"
        />
      </v-col>
      <v-col cols="12">
        <!-- Data table -->
        <span v-if="pending">
          <v-skeleton-loader type="table" class="border rounded-lg" />
        </span>
        <v-data-table
          v-else
          :search="search"
          :items="seoStrategyData"
          :headers="headers"
          show-select
          class="rounded-lg border"
          dense
          :items-per-page="50"
          :footer-props="{ itemsPerPageOptions: [50, 150, 300, -1] }"
        >
          <template #[`header.data-table-select`] />
          <template #[`item.data-table-select`]="{ item }">
            <div v-if="item" class="d-flex align-center">
              <v-checkbox
                v-model="item.checkbox"
                hide-details
                dense
                class="ma-0"
                :true-value="1"
                :false-value="0"
                :disabled="item?.pending"
                @change="update(item)"
              />
              <v-btn small icon @click="openEditDialog(item)">
                <v-progress-circular
                  v-if="item?.pending"
                  indeterminate
                  size="12"
                  width="1"
                />
                <v-icon v-else size="small">mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="isEditDialogVisible"
      content-class="my-shadow--e2"
      width="600"
    >
      <v-card class="elevation-0 rounded-lg border">
        <v-card-title class="text-body-2">
          Edit
          <v-spacer />
          <v-icon @click="isEditDialogVisible = false" small>mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-5">
          <v-row v-if="editForm">
            <v-col cols="12">
              <v-text-field
                v-model="editForm.link"
                hide-details
                label="Link"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-btn
                @click="applyEdits"
                block
                color="primary"
                class="elevation-0 text-normal"
                height="52"
                :loading="editPending"
                >Apply changes</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { seoStrategyApiService } from "./services/seoStrategyApiService";
import {
  SEO_STRATEGY_DATA_TABLE_HEADERS,
  SEO_STRATEGY_PAGE_CONFIG,
} from "./defaults";
import PageHeader from "../../components/UI/PageHeader.vue";
import { deepClone } from "../../utils/functions";

export default {
  components: { PageHeader },
  data: () => ({
    search: null,
    seoStrategyData: [],
    headers: SEO_STRATEGY_DATA_TABLE_HEADERS,
    pending: false,
    pageConfigs: SEO_STRATEGY_PAGE_CONFIG,
    isEditDialogVisible: false,
    editForm: null,
    editPending: false,
  }),

  mounted() {
    this.initializeSeoStrategyData();
  },

  methods: {
    async applyEdits() {
      this.editPending = true;

      const { data } = await this.update(this.editForm);

      if (!data.success) {
        return this.$message.notification({
          title: "Not saved.",
          text: "Changes have not been saved, try again later.",
          type: "error",
        });
      }

      this.rewriteEditedTableItem(this.editForm);

      await this.$nextTick();

      this.isEditDialogVisible = false;
      this.editForm = null;

      this.editPending = false;
    },
    rewriteEditedTableItem(newData) {
      const idx = this.seoStrategyData.findIndex(
        (item) => item.id === newData.id
      );

      this.$set(this.seoStrategyData, idx, newData);
    },
    async openEditDialog(tableItem) {
      this.editForm = deepClone(tableItem);

      await this.$nextTick();

      this.isEditDialogVisible = true;
    },
    async update(tableItem) {
      if (!tableItem) throw new Error("Cant update without data.");

      try {
        this.$set(tableItem, "pending", true);

        return await seoStrategyApiService().putStrategy(tableItem);
      } catch (e) {
        console.error("Error while updating status.", e);
      } finally {
        this.$set(tableItem, "pending", false);
      }
    },
    async initializeSeoStrategyData() {
      try {
        this.pending = true;

        const { data } = await seoStrategyApiService().getStrategy();

        this.seoStrategyData = data?.items || [];
      } catch (e) {
        console.error("Error while initializing seo strategy data.", e);
      } finally {
        this.pending = false;
      }
    },
  },
};
</script>
