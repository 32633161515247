export const FREELANCER_DATA_TABLE_ITEMS = [
  {
    id: 2,
    name: "Bob",
    contacts: "asd",
    language_id: 1,
    status: 0,
    price_per_100_words: "asd",
    experience: "asd",
    thematics: "asd",
    specialization: "asd",
    portfolio: null,
    avg_complete_time: "asd",
    seo_knowledge: "asd",
    niche_knowledge: "asd",
    texts_quality: null,
    avg_edits_time: null,
    payments: "asd",
    min_order: "asd",
    price_text_optimization: null,
    price_urgency: null,
    punctual: "asd",
    communication: "asd",
    summary_recommendation: null,
    comments: null,
    nda: null,
    experience_competitors: null,
    created_at: "2025-01-20T13:02:21.000000Z",
    updated_at: "2025-01-20T13:02:21.000000Z",
  },
];

export const FREELANCER_DATA_TABLE_HEADERS = [
  {
    text: "",
    value: "_actions",
    sortable: false,
  },
  {
    text: "Name / Nickname",
    value: "name",
  },
  {
    text: "Contact",
    value: "contacts",
  },
  {
    text: "Language",
    value: "languages",
    align: "center",
    width: "280px",
    sortable: false,
  },
  {
    text: "Niche",
    value: "niches",
    width: "280px",
    align: "center",
    sortable: false,
  },
  {
    text: "Status",
    value: "status_name",
    align: "center",
  },
  {
    text: "Portfolio",
    value: "portfolio",
  },
  {
    text: "TTW",
    title: "Time to write",
    value: "time_to_write",
    width: "85px",
    align: "center",
  },
  {
    text: "SEO knowledge",
    value: "seo_knowledge",
    align: "center",
  },
  {
    text: "Niche knowledge",
    value: "niche_knowledge",
    align: "center",
  },
  {
    text: "Text Quality",
    value: "text_quality_name",
    align: "center",
  },
  {
    text: "TTE",
    title: "Time to edit",
    value: "time_to_edit",
    width: "85px",
    align: "center",
  },
  {
    text: "Payment Type",
    value: "payment_methods",
    sortable: false,
  },
  {
    text: "Rate",
    value: "rate",
    title: "Per 100 words",
    width: "85px",
    align: "center",
  },
  {
    text: "Optimization Rate",
    value: "optimisation_rate",
    title: "Per 100 words",
    width: "85px",
    align: "center",
  },
  {
    text: "Urgency Rate",
    value: "urgency_rate",
    title: "Per 100 words",
    width: "100px",
    align: "center",
  },
  {
    text: "NDA",
    value: "nda",
    align: "center",
  },
  {
    text: "Comment",
    value: "comment",
    width: "320px",
    align: "center",
  },
  {
    text: "Created",
    value: "created_at",
    width: "160px",
    align: "center",
  },
  {
    text: "Updated",
    value: "updated_at",
    width: "160px",
    align: "center",
  },
];
