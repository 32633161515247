<template>
  <v-container fluid class="site-worksheets-by-element">
    <v-row dense>
      <v-col cols="12">
        <h2>Site Worksheet by elements</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.element"
          :items="filtersData.element"
          :loading="loadings.filters"
          dense
          outlined
          hide-details
          clearable
          multiple
          label="Element"
          placeholder="Select"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <smart-autocomplete
          :model-value="filters.element_status"
          @update:modelValue="filters.element_status = $event"
          :items="filtersData.element_status"
          :loading="loadings.filters"
          label="Element status"
          placeholder="Select"
          :disabled="filters.el_status_preset !== null"
        />
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.el_status_preset"
          :items="[
            {
              text: 'Empty status',
              value: 'empty_status',
            },
            {
              text: 'Not in work',
              value: 'not_in_work',
            },
          ]"
          :loading="loadings.filters"
          dense
          clearable
          outlined
          hide-details
          label="Element status presets"
          @change="handleChangeElementStatusPreset"
          placeholder="Select"
        />
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.el_status_resp"
          :items="filtersData.el_status_resp"
          :loading="loadings.filters"
          dense
          clearable
          outlined
          hide-details
          multiple
          label="Element status responsible"
          placeholder="Select"
        />
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.executive"
          :items="filtersData.executive"
          :loading="loadings.filters"
          dense
          outlined
          clearable
          multiple
          hide-details
          label="Element executive"
          placeholder="Select"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.seo"
          :items="filtersData.seo"
          :loading="loadings.filters"
          dense
          outlined
          clearable
          hide-details
          multiple
          label="Chief seo"
          placeholder="Select"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.project"
          :items="filtersData.project"
          :loading="loadings.filters"
          dense
          clearable
          outlined
          hide-details
          multiple
          label="Project"
          placeholder="Select"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.sub_project"
          :items="filtersData.subprojects"
          label="Sub Projects"
          :loading="loadings.filters"
          multiple
          hide-details
          clearable
          outlined
          dense
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.page"
          :items="[
            {
              text: 'Only Top 5',
              value: 'only_top_5',
            },
            {
              text: 'With Clicks',
              value: 'with_clicks',
            },
            {
              text: 'Only Top 5 or With Clicks',
              value: 'top_5_or_clicks',
            },
            {
              text: 'All',
              value: 'all',
            },
          ]"
          hide-details
          clearable
          dense
          label="Only top 5"
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-text-field
          v-model="filters.url"
          label="URL"
          hide-details
          dense
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.team"
          :items="filtersData.team"
          :loading="loadings.filters"
          dense
          clearable
          outlined
          hide-details
          multiple
          label="Team"
          placeholder="Type to search"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.niche"
          :items="filtersData.niche"
          :loading="loadings.filters"
          dense
          clearable
          outlined
          hide-details
          multiple
          label="Niche"
          placeholder="Type to search"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.type"
          :items="['current', 'new']"
          :loading="loadings.filters"
          dense
          clearable
          outlined
          hide-details
          label="Content type"
          placeholder="Type to search"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <smart-date-filter
          :model-value="filters.deadline"
          @update:modelValue="filters.deadline = $event"
          label="Element deadline"
        />
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.project_status"
          :items="filtersData.project_status"
          :loading="loadings.filters"
          dense
          clearable
          outlined
          hide-details
          multiple
          label="Project status"
          placeholder="Type to search"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <smart-date-filter
          :model-value="filters.el_status_dl"
          @update:modelValue="filters.el_status_dl = $event"
          label="Element status deadline"
        />
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.page_type"
          :items="filtersData.page_type"
          :loading="loadings.filters"
          dense
          outlined
          hide-details
          clearable
          multiple
          label="Page type"
          placeholder="Type to search"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <easy-range-input
          :model-value="filters.need_top"
          @update:modelValue="filters.need_top = $event"
          label="Need top"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.project_categories"
          :items="filtersData.project_categories"
          hide-details
          multiple
          dense
          outlined
          label="Project categories"
          placeholder="Type to search"
        />
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-checkbox
          v-model="filters.is_dont_view_done"
          :true-value="1"
          :false-value="0"
          hide-details
          dense
          class="ma-1"
          label="Don't view done"
        />
      </v-col>
      <v-col cols="4" md="4" lg="2" xl="fill"></v-col>
      <v-col cols="4" md="4" lg="2" xl="fill"></v-col>
      <v-col cols="4" md="4" lg="2" xl="fill"></v-col>
      <v-col cols="4" md="4" lg="2" xl="fill"></v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4" md="4" lg="2" xl="fill"></v-col>
      <v-col cols="4" md="4" lg="2" xl="fill"></v-col>
      <v-col cols="4" md="4" lg="2" xl="fill"></v-col>
      <v-col cols="4" md="4" lg="2" xl="fill"></v-col>
      <v-col cols="4" md="4" lg="2" xl="fill"></v-col>
      <v-col cols="4" md="4" lg="2" xl="fill">
        <v-btn
          block
          @click="fetchData"
          :loading="loadings.table"
          color="primary"
          class="text-body-2"
          height="40"
          >Get Data
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense class="mt-8">
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2 d-flex" style="gap: 0.25rem">
            <v-menu content-class="my-shadow--e3" offset-y>
              <template #activator="{ on }">
                <v-btn height="40" class="text-body-2" v-on="on">
                  <v-icon small left>mdi-chevron-down</v-icon>
                  Actions
                  <my-badge
                    v-if="getAmountOfSeletedTableItems"
                    class="success white--text ml-1"
                  >
                    {{ getAmountOfSeletedTableItems }}
                  </my-badge>
                  <v-icon small right>mdi-pen</v-icon>
                </v-btn>
              </template>
              <v-card class="styled-card--default pa-0">
                <v-list dense>
                  <v-menu
                    right
                    offset-x
                    open-on-hover
                    content-class="my-shadow--e3"
                  >
                    <template #activator="{ on }">
                      <v-list-item v-on="on">
                        <v-list-item-icon class="mr-4">
                          <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          Bind to executive
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                    <v-card class="styled-card--default pa-0">
                      <v-list dense>
                        <v-list-item
                          @click="handleOpenBindToExecutiveDialog(TYPES.PAGE)"
                        >
                          Page
                        </v-list-item>
                        <v-list-item
                          @click="
                            handleOpenBindToExecutiveDialog(TYPES.ELEMENT)
                          "
                        >
                          Element
                        </v-list-item>
                        <v-list-item
                          @click="
                            handleOpenBindToExecutiveDialogByCurrentStatus
                          "
                        >
                          <v-list-item-content>
                            <div>Current status</div>
                            <div>
                              <small
                                v-if="
                                  getSelectedTableItemsWithTableResponsibleIdAmount
                                "
                                class="warning--text"
                              >
                                Only
                                {{
                                  getSelectedTableItemsWithTableResponsibleIdAmount
                                }}
                                items are suitable for request
                              </small>
                              <small v-else class="error--text">
                                None of the selected items match the request
                              </small>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>

                  <v-menu
                    right
                    offset-x
                    open-on-hover
                    content-class="my-shadow--e3"
                  >
                    <template #activator="{ on }">
                      <v-list-item v-on="on">
                        <v-list-item-icon class="mr-4">
                          <v-icon>mdi-list-status</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          Change status</v-list-item-content
                        >
                        <v-list-item-action>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                    <v-card class="styled-card--default pa-0">
                      <v-list dense>
                        <v-list-item
                          @click="handleOpenChangeStatusDialog(TYPES.PAGE)"
                        >
                          Page
                        </v-list-item>
                        <v-list-item
                          @click="handleOpenChangeStatusDialog(TYPES.ELEMENT)"
                        >
                          Element
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-list>
              </v-card>
            </v-menu>
            <v-text-field
              v-model="search"
              label="Type to search"
              append-icon="mdi-magnify"
              hide-details
              clearable
              outlined
              dense
              style="max-width: 420px"
            />
            <v-spacer />
            <v-menu bottom offset-y content-class="my-shadow--e2">
              <template #activator="{ on }">
                <v-btn v-on="on" class="text-body-2">
                  Sorting presets <v-icon small right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="styled-card--default pa-0">
                <v-list dense>
                  <v-list-item @click="setTableSort(4)"
                    >By Need Top (default)</v-list-item
                  >
                  <v-list-item @click="setTableSort(0)"
                    >Page deadline</v-list-item
                  >
                  <v-list-item @click="setTableSort(1)"
                    >CL & Imp - priority</v-list-item
                  >
                  <v-list-item @click="setTableSort(2)"
                    >Element status deadline</v-list-item
                  >
                  <v-list-item @click="setTableSort(3)"
                    >Priority URL</v-list-item
                  >
                </v-list>
              </v-card>
            </v-menu>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <template v-if="loadings.table">
              <v-skeleton-loader type="table" />
            </template>
            <template v-else>
              <v-data-table
                v-model="selectedTableItems"
                :search="search"
                :headers="headers"
                show-select
                item-key="_id"
                :items="tableData"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                multi-sort
                class="site-worksheets-by-element__table relative-td"
                :footer-props="{ itemsPerPageOptions: [50, 150, 300, -1] }"
                :custom-sort="_$smartCustomTableSort"
              >
                <template #[`item.el_status_resp`]="{ value, item }" ]>
                  <user-preview
                    :name="value"
                    :email="item?.email"
                    hide-initials
                  />
                </template>
                <template #[`item.us`]="{ value, item }" ]>
                  <user-preview
                    :name="value"
                    :email="item?.email"
                    hide-initials
                  />
                </template>
                <template #[`item.dl`]="{ value }">
                  <v-chip
                    v-if="value"
                    :color="getDeadlineColor(value)"
                    :title="value"
                  >
                    {{ value ? $moment(value).format("MMM DD") : "" }}
                  </v-chip>
                </template>
                <template #[`item.last_change_status`]="{ value, item }">
                  <v-chip
                    v-if="value"
                    :color="getLastChangeStatusColor(value, item)"
                    :title="value"
                  >
                    {{ value ? $moment(value).format("MMM DD") : "" }}
                    <v-tooltip
                      bottom
                      open-delay="100"
                      content-class="pa-0 rounded-lg"
                    >
                      <template #activator="{ on }">
                        <v-icon v-on="on" small right>
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <v-sheet rounded="lg" outlined elevation="0" class="pa-4">
                        <div>
                          Diff in hours –
                          <b>{{ item._last_change_status?.diff_in_hours }}</b>
                        </div>
                        <div>
                          Critical time –
                          <b>{{ item._last_change_status?.critical_time }}</b>
                        </div>
                      </v-sheet>
                    </v-tooltip>
                  </v-chip>
                </template>
                <template #[`item.el_status_dl`]="{ value }">
                  <v-chip
                    v-if="value"
                    :color="getElementDeadlineColor(value)"
                    :title="value"
                  >
                    {{ value ? $moment(value).format("MMM DD") : "" }}
                  </v-chip>
                </template>
                <template #[`item.el_dl`]="{ value }">
                  <v-chip
                    v-if="value"
                    :color="getElementDeadlineColor(value)"
                    :title="value"
                  >
                    {{ value ? $moment(value).format("MMM DD") : "" }}
                  </v-chip>
                </template>
                <template #[`item.u`]="{ value, item }">
                  <div
                    class="mark mark--error"
                    style="width: 100%"
                    v-if="isOutdated(item)"
                  />
                  <div class="d-flex flex-column">
                    <div class="grey--text">{{ parseUrl(value)?.host }}</div>
                    <a
                      class="primary--text"
                      :href="value"
                      target="_blank"
                      style="min-width: 320px"
                    >
                      {{ parseUrl(value)?.pathname }}
                    </a>
                  </div>
                </template>
                <template #[`item.el_dl`]="{ value }">
                  <v-chip
                    v-if="value"
                    :color="getDeadlineColor(value)"
                    :title="value"
                  >
                    {{ value ? $moment(value).format("MMM DD") : "" }}
                  </v-chip>
                </template>
                <template #[`item.nt`]="{ value }">
                  <div :class="{ 'colored-td': true, yellow: value > 100 }">
                    {{ value }}
                  </div>
                </template>
                <template #[`item.el`]="{ item, value }">
                  <template v-if="item.el === CONTENT_FULL_PROCESS">
                    {{ value }}
                  </template>
                  <v-btn
                    v-else
                    color="transparent"
                    class="text-normal"
                    @click="handleOpenEditElementDialog(item.id, item)"
                    :loading="loadings.details === item.id"
                    :disabled="
                      loadings.elementData !== null &&
                      loadings.elementData !== item.id
                    "
                  >
                    {{ item.el }}
                    <template
                      v-if="
                        loadings.elementData !== null &&
                        loadings.elementData === item.id
                      "
                    >
                      <v-progress-circular
                        indeterminate
                        size="14"
                        width="2"
                        class="ml-2"
                      />
                    </template>
                    <v-icon v-else small right>mdi-pen</v-icon>
                  </v-btn>
                </template>
                <template #[`item.current_status`]="{ item, value }">
                  <v-btn
                    v-if="item.el === CONTENT_FULL_PROCESS"
                    color="transparent"
                    class="text-normal"
                    @click="handleOpenEditElementDialog(item.id, item)"
                    :loading="loadings.details === item.id"
                    :disabled="
                      loadings.elementData !== null &&
                      loadings.elementData !== item.id
                    "
                  >
                    {{ value }}
                    <template
                      v-if="
                        loadings.elementData !== null &&
                        loadings.elementData === item.id
                      "
                    >
                      <v-progress-circular
                        indeterminate
                        size="14"
                        width="2"
                        class="ml-2"
                      />
                    </template>
                    <v-icon v-else small right>mdi-pen</v-icon>
                  </v-btn>
                  <template v-else>
                    {{ value }}
                  </template>
                </template>
                <template #[`item.element_status`]="{ item }">
                  <v-autocomplete
                    v-model="item.status_id"
                    :items="filtersData.element_status"
                    hide-details
                    dense
                    filled
                    style="max-width: 160px"
                    @change="handleChangeElementStatus(item, $event)"
                  ></v-autocomplete>
                </template>
              </v-data-table>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <edit-element-dialog
      :model-value="editElementDialogModel"
      @update:modelValue="editElementDialogModel = $event"
      :element-data="elementData"
      :statuses="filtersData.element_status"
      :filtersData="filtersData"
    />
    <bind-to-executive-dialog
      :model-value="bindToExecutiveDialogModel"
      @update:modelValue="bindToExecutiveDialogModel = $event"
      :executive-items="filtersData.executive"
      @changeExecutive="
        updateUrl(
          selectedTableItems.map((item) => getDataByMode(item, mode)),
          $event,
          'executive_id',
          () => {
            bindToExecutiveDialogModel = false;
          }
        )
      "
    />
    <bind-to-executive-by-current-status
      :model-value="bindToExecutiveByCurrentStatusDialogModel"
      @update:modelValue="bindToExecutiveByCurrentStatusDialogModel = $event"
      :executive-items="filtersData.executive"
      @changeExecutive="
        updateUrlCurrentStatusModifier(
          selectedTableItems.map((item) =>
            getDataForBindToExecutiveForCurrentStatus(item)
          ),
          $event,
          () => {
            bindToExecutiveByCurrentStatusDialogModel = false;
          }
        )
      "
    />
    <change-status-dialog
      :model-value="changeStatusDialogModel"
      @update:modelValue="changeStatusDialogModel = $event"
      :status-items="filtersData.element_status"
      @changeStatus="
        updateUrl(
          selectedTableItems.map((item) => getDataByMode(item, mode)),
          $event,
          'status',
          () => {
            changeStatusDialogModel = false;
          }
        )
      "
    />
    <element-control-dialog
      v-model="isElementControlDialogVisible"
      :data="worksheetDetails"
      :loading="loadings.elementData !== null"
      @statusSuccessfullyUpdated="processContentFullProcess"
    />
  </v-container>
</template>

<script>
import Location from "../../../mixins/Location";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import EasyRangeInput from "@/components/UI/EasyRangeInput.vue";
import TableSorting from "@/mixins/TableSorting";
import EditElementDialog from "./EditElementDialog.vue";
import BindToExecutiveDialog from "@/components/SiteWorkplace/SiteWorksheet/BindToExecutiveDialog.vue";
import ChangeStatusDialog from "@/components/SiteWorkplace/SiteWorksheet/ChangeStatusDialog.vue";
import MyBadge from "@/components/UI/MyBadge.vue";
import SmartAutocomplete from "@/components/UI/SmartAutocomplete.vue";
import service from "@/plugins/service";
import Notification from "@/mixins/Notification";
import { deepClone } from "@/utils/functions";
import BindToExecutiveByCurrentStatus from "@/components/SiteWorkplace/SiteWorksheet/BindToExecutiveByCurrentStatus.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";
import ElementControlDialog from "./components/ElementControlDialog.vue";
import { SiteWorkSheetApiService } from "./services/SiteWorkSheetApiService";
import { CONTENT_FIELD_TYPES, EVENTS } from "./defaults";
import UserPreview from "../../UI/UserPreview.vue";
import eventPipe from "../../../events/eventPipe";

export default {
  components: {
    UserPreview,
    ElementControlDialog,
    BindToExecutiveByCurrentStatus,
    SmartAutocomplete,
    MyBadge,
    ChangeStatusDialog,
    BindToExecutiveDialog,
    EditElementDialog,
    EasyRangeInput,
    SmartDateFilter,
  },
  mixins: [
    FiltersHandling,
    Location,
    TableSorting,
    Notification,
    UploadFiltersDataMixin,
  ],
  computed: {
    getSelectedTableItemsWithTableResponsibleIdAmount() {
      return this.selectedTableItems?.filter(
        (item) => item.table_responsible_id
      ).length;
    },
    getAmountOfSeletedTableItems() {
      if (!this.selectedTableItems) return null;
      return this.selectedTableItems.length;
    },
  },
  data: () => ({
    TYPES: {
      PAGE: "page",
      ELEMENT: "element",
    },
    isElementControlDialogVisible: false,
    worksheetDetails: null,
    elementData: {},
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Site workplace",
        disabled: true,
      },
      {
        text: "Site Worksheet by elements",
        disabled: true,
      },
    ],
    filters: {
      is_dont_view_done: 1,
      url: null,
      element: [],
      element_status: [],
      el_status_preset: null,
      executive: [],
      seo: [],
      project: [],
      deadline: [null, null],
      team: [],
      niche: [],
      project_status: [1],
      project_categories: [],
      el_status_resp: [],
      sub_project: [],
      el_status_dl: [null, null],
      page_type: [],
      page: null,
      type: null,
      need_top: [null, null],
    },
    sortBy: ["pr_p", "nt"],
    sortDesc: [false, false],
    editElementDialogModel: false,
    bindToExecutiveDialogModel: false,
    changeStatusDialogModel: false,
    bindToExecutiveByCurrentStatusDialogModel: false,
    mode: null,
    headers: [],
    tableData: [],
    CONTENT_FULL_PROCESS: "Content: Full Process",
    filtersData: {
      subprojects: [],
      project_categories: [],
      element: [],
      executive: [],
      seo: [],
      project: [],
      element_status: [],
      team: [],
      niche: [],
      sub_project: [],
      type: null,
      project_status: [],
      page_type: [],
    },
    selectedTableItems: [],
    search: null,
    lastUsedContentFullProcessData: null,
    loadings: {
      details: false,
      filters: false,
      elementData: null,
      table: false,
    },
  }),
  async mounted() {
    await this.parseQuery("filters");

    this.initDefaultFilters();

    this.uploadFiltersData(["project_categories", "subprojects", "projects"], {
      projects: this.filters.project,
    });
    this.fetchFilters();

    if (Object.keys(this.$route.query).length) this.fetchData();

    eventPipe.$on(EVENTS.UPDATE_CFP_MODAL, () =>
      this.processContentFullProcess(this.lastUsedContentFullProcessData)
    );

    this.$watch("filters.project", () => {
      this.filters.sub_project = [];

      this.uploadFiltersData(["subprojects"], {
        projects: this.filters.project,
      });
    });
  },
  methods: {
    isOutdated(tableItem) {
      try {
        const { diff_in_hours, critical_time } = tableItem._last_change_status;

        return critical_time > 0 && diff_in_hours >= critical_time;
      } catch {
        return false;
      }
    },
    parseUrl(url) {
      try {
        return new URL(url);
      } catch {
        return {};
      }
    },
    setTableSort(sortId) {
      const sort = {
        0: {
          sortBy: ["dl", "cl", "imp", "u", "el_status_dl"],
          sortDesc: [false, true, true, false, false],
        },
        1: {
          sortBy: ["cl", "imp", "el_status_dl"],
          sortDesc: [true, true, false],
        },
        2: {
          sortBy: ["el_status_dl", "cl", "imp"],
          sortDesc: [false, true, true],
        },
        3: {
          sortBy: ["p_p", "el_status_dl"],
          sortDesc: [false, false],
        },
        4: {
          sortBy: ["pr_p", "nt"],
          sortDesc: [false, false],
        },
      };

      this.sortBy = sort[sortId].sortBy;
      this.sortDesc = sort[sortId].sortDesc;
    },
    initDefaultFilters() {
      const defaultFilters = {
        niche: [2],
        page: "top_5_or_clicks",
      };

      Object.entries(defaultFilters).forEach(([key, value]) => {
        if (!this.$route.query[key]) {
          this.filters[key] = value;
        }
      });
    },
    getDataForBindToExecutiveForCurrentStatus(data) {
      return {
        id: data.id,
        table_responsible_id: data.table_responsible_id,
        status_id: data.status_id,
      };
    },
    getDataByMode(data, mode) {
      if (mode === this.TYPES.PAGE) {
        return data.url_control_id;
      }
      if (mode === this.TYPES.ELEMENT) {
        return data.id;
      }
      return null;
    },
    async updateUrl(statuses, statusId, type, callback) {
      try {
        const url = "/dashboards/content/site-worksheet/update";

        const payload = {
          ids: statuses,
          value: statusId,
          type,
          mode: this.mode,
        };

        const response = await service.post(url, payload);

        if (response && response.data && response.data.status) {
          this.mSuccess({
            title: "Done",
            text: "Action successfully completed.",
          });
          callback();
        }
      } catch (e) {
        console.error("Error while changing url status.");
      }
    },
    async updateUrlCurrentStatusModifier(data, statusId, callback) {
      try {
        const url = "/dashboards/content/site-worksheet/update-current-status";

        const payload = {
          data,
          value: statusId,
        };

        const response = await service.post(url, payload);

        if (response && response.data && response.data.status) {
          this.mSuccess({
            title: "Done",
            text: "Action successfully completed.",
          });
          callback();
        }
      } catch (e) {
        console.error("Error while changing url status.");
      }
    },
    handleChangeElementStatus(urlItem, statusId) {
      this.updateUrl([urlItem.id], statusId, "status");
    },
    handleChangeElementStatusPreset() {
      this.filters.element_status = [];
    },
    handleOpenChangeStatusDialog(type) {
      this.changeStatusDialogModel = true;
      this.mode = type;
    },
    handleOpenBindToExecutiveDialog(type) {
      this.bindToExecutiveDialogModel = true;
      this.mode = type;
    },
    handleOpenBindToExecutiveDialogByCurrentStatus() {
      this.bindToExecutiveByCurrentStatusDialogModel = true;
    },
    initializeDefaultFieldValue(field, ctx) {
      try {
        if (field.type === CONTENT_FIELD_TYPES.PRICE) {
          const defaultValue = ctx.fields?.[field.id]?.value?.value || null;
          const defaultCurrency =
            ctx.fields?.[field.id]?.value?.currency || null;

          if (!("value" in field)) this.$set(field, "value", defaultValue);
          if (!("currency" in field))
            this.$set(field, "currency", defaultCurrency);
          return null;
        }

        const defaultValue = ctx.fields?.[field.id]?.value || null;
        if (!("value" in field)) this.$set(field, "value", defaultValue);
      } catch (e) {
        console.error("Error while initializing default value.", e);
        console.error("Field: ", field);
        console.error("Context: ", ctx);
      }
    },
    workSheetDetailsAdapter(data) {
      try {
        const fixed = { ...data };

        fixed.content_fields = fixed.content_fields?.map((field) => {
          this.initializeDefaultFieldValue(field, data);
          return field;
        });

        return fixed;
      } catch (e) {
        console.error("Error in workSheetDetailsAdapter", e);
        return {};
      }
    },
    async processContentFullProcess(tableRow) {
      if (!tableRow) throw new Error("tableRow are not exist.");

      try {
        this.loadings.elementData = tableRow.id;

        this.lastUsedContentFullProcessData = tableRow;

        const { data } =
          await SiteWorkSheetApiService().getWorksheetDetailsById(
            tableRow.url_id
          );

        this.worksheetDetails = {
          ...this.workSheetDetailsAdapter(data),
          _ctx: tableRow,
        };

        this.isElementControlDialogVisible = true;
      } catch (e) {
        console.error("Error while loading details.", e);
      } finally {
        this.loadings.elementData = null;
      }
    },
    async handleOpenEditElementDialog(urlId, tableRow) {
      if (tableRow.el === this.CONTENT_FULL_PROCESS)
        return this.processContentFullProcess(tableRow);

      this.elementData = {
        ...(await this.fetchElementData(urlId)),
        url_id: urlId,
      };
      this.editElementDialogModel = true;
    },
    async fetchElementData(urlId) {
      try {
        const url = "/dashboards/content/site-worksheet/" + urlId;

        this.loadings.elementData = urlId;

        const response = await service.post(url);

        if (response) {
          return response.data;
        }
      } catch (e) {
        console.error("Error while loading element data.", e);
      } finally {
        this.loadings.elementData = null;
      }
    },
    getDeadlineColor(date) {
      const d = this.$moment(date);
      const today = this.$moment();
      const threeDaysPlus = this.$moment().add(3, "days");

      if (d.isSameOrAfter(threeDaysPlus, "day")) return "transparent";

      if (d.isBefore(today, "day")) return "error";

      return "transparent";
    },
    getLastChangeStatusColor(value, data) {
      if (data.deadline) return "error";

      return "primary";
    },
    getElementDeadlineColor(date) {
      const d = this.$moment(date);
      const today = this.$moment();

      if (d.isBefore(today, "day")) return "error";

      if (!d.isSame(today, "day")) return "info";

      return "transparent";
    },
    resetTableData() {
      this.selectedTableItems = [];
    },
    async fetchData() {
      try {
        if (this.filters.el_status_preset !== null) {
          this.filters.element_status = [];
        }

        const payload = {
          filter: this.filters,
        };

        this.loadings.table = true;

        this.resetTableData();

        const resp = await this.$store.dispatch(
          "site-worksheet/fetchData",
          payload
        );

        if (resp) {
          this.headers = this.processTableHeaders(resp.headers);
          this.tableData = this.processTableData(resp.items);
        }
      } catch (e) {
        console.error("Error while fetching data.", e);
      } finally {
        this.loadings.table = false;
      }
    },
    fixTableDataItems(items) {
      return items.map((item) => {
        item._json = JSON.stringify(item);

        return item;
      });
    },
    fixTableHeadersItems(items) {
      const settings = {
        current_status: {
          align: "center",
        },
        status: {
          align: "center",
        },
        el_status_dl: {
          align: "center",
        },
        pt: {
          align: "center",
        },
        p_p: {
          align: "center",
        },
        pr_p: {
          align: "center",
        },
        cl: {
          align: "center",
        },
        imp: {
          align: "center",
        },
        nt: {
          align: "center",
        },
        el_dl: {
          align: "center",
        },
        el_resp: {
          align: "center",
        },
        dl: {
          align: "center",
        },
        us: {
          align: "center",
        },
      };

      return items.map((item) => {
        if (settings[item.value]) {
          item = {
            ...item,
            ...settings[item.value],
          };
        }

        return item;
      });
    },
    processTableData(tableItems) {
      let tableItemsTmpr = deepClone(tableItems) || [];

      tableItemsTmpr = this.fixTableDataItems(tableItemsTmpr);

      return tableItemsTmpr;
    },
    processTableHeaders(tableItems) {
      let tableHeadersTmpr = deepClone(tableItems) || [];

      tableHeadersTmpr = this.fixTableHeadersItems(tableHeadersTmpr);

      return tableHeadersTmpr;
    },
    async fetchFilters() {
      try {
        const filters = [
          "element",
          "executive",
          "seo",
          "project",
          "element_status",
          "team",
          "niche",
          "sub_project",
          "project_status",
          "page_type",
          "el_status_resp",
        ];
        const payload = {
          type: "/dashboards/content/site-worksheet",
          take: filters,
          filter: {
            projectID: this.$store.state.project.active_project,
          },
        };
        this.loadings.filters = true;

        const resp = await this.$store.dispatch("global/getFilters", payload);
        if (resp) {
          this.filtersData = { ...this.filtersData, ...resp };
        }
      } catch (e) {
        console.error("Error while fetching filters.", e);
      } finally {
        this.loadings.filters = false;
      }
    },
  },
};
</script>

<style lang="scss">
.site-worksheets-by-element {
  &__table {
    table {
      tr {
        td,
        th {
          &:nth-child(2),
          &:nth-child(4),
          &:nth-child(6),
          &:nth-child(9),
          &:nth-child(10),
          &:nth-child(13),
          &:nth-child(15) {
            border-right: 1px solid rgba(0, 0, 0, 0.12);
          }
        }
      }
    }
  }
}
</style>
