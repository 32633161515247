<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <page-header title="Domain subfolder" />
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title style="gap: 0.5rem">
            <v-text-field
              v-model="search"
              hide-details
              dense
              outlined
              placeholder="Type to search"
              append-icon="mdi-magnify"
            />
            <v-btn
              height="40"
              color="primary"
              class="text-normal"
              @click="isAddDomainDialogVisible = true"
            >
              Add domains <v-icon small right>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0">
            <domain-subfolders-table
              :items="items"
              :headers="headers"
              :loading="pending"
              @edit="handleEdit"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <add-domain-dialog
      v-model="isAddDomainDialogVisible"
      :filtersData="filtersData"
      @success="initializeTableData"
    />
    <edit-domain-dialog
      v-model="isEditDomainDialogVisible"
      @success="initializeTableData"
      :filters-data="filtersData"
      :data="editDomainData"
    />
  </v-container>
</template>

<script>
import { TABLE_HEADERS } from "./defaults";
import PageHeader from "../../../../components/UI/PageHeader.vue";
import DomainSubfoldersTable from "./components/DomainSubfoldersTable.vue";
import { DomainSubfolderApiService } from "../../services/DomainSubfolderApiService";
import AddDomainDialog from "./components/AddDomainDialog.vue";
import UploadFiltersDataMixin from "../../../../mixins/UploadFiltersDataMixin";
import EditDomainDialog from "./components/EditDomainDialog.vue";

export default {
  components: {
    EditDomainDialog,
    AddDomainDialog,
    DomainSubfoldersTable,
    PageHeader,
  },

  mixins: [UploadFiltersDataMixin],

  data: () => ({
    headers: TABLE_HEADERS,
    search: null,
    items: [],
    isAddDomainDialogVisible: false,
    isEditDomainDialogVisible: false,
    pending: false,
    editDomainData: {
      pattern: null,
      name: null,
      country_id: null,
      language_id: null,
      priority: 0,
      is_active: false,
      id: null,
    },
  }),

  mounted() {
    this.initializeTableData();
    this.uploadFiltersData(["languages", "countries"]);
  },

  methods: {
    handleEdit(tableItem) {
      this.editDomainData = { ...tableItem };
      this.isEditDomainDialogVisible = true;
    },
    tableItemMapper(rawItem) {
      return {
        ...rawItem,
        domain_name: rawItem?.domain?.domain || "",
        language_name: rawItem?.language?.language || "",
        country_name: rawItem?.country?.gsc || "",
      };
    },
    async initializeTableData() {
      try {
        this.pending = true;

        const { data } = await DomainSubfolderApiService().getAll();

        this.items = data.map(this.tableItemMapper);

        console.log(this.item);
      } catch (e) {
        console.error("Error while initializing table data.", e);
      } finally {
        this.pending = false;
      }
    },
  },
};
</script>
