<template>
  <div class="px-4 pb-2 pt-1">
    <template v-if="field.type === TYPES.EMAIL">
      <div>
        <small>
          {{ field.name }}
        </small>
        <div>
          <template v-if="getDetailsAboutFieldById(field.id)?.value">
            <b style="font-size: 0.875rem">{{
              getDetailsAboutFieldById(field.id)?.value
            }}</b>
          </template>
          <template v-else>
            <small style="opacity: 0.5"> No value... </small>
          </template>
        </div>
      </div>
    </template>

    <template v-else-if="field.type === TYPES.PRICE">
      <div>
        <small>
          {{ field.name }}
        </small>
        <div>
          <template v-if="getDetailsAboutFieldById(field.id)?.value">
            <b style="font-size: 0.875rem"
              >{{ getDetailsAboutFieldById(field.id)?.value?.value
              }}{{ getDetailsAboutFieldById(field.id)?.additional?.symbol }}</b
            >
          </template>
          <template v-else>
            <small style="opacity: 0.5"> No value... </small>
          </template>
        </div>
      </div>
    </template>

    <template v-else-if="field.type === TYPES.URL">
      <div>
        <small>
          {{ field.name }}
        </small>
        <div>
          <template v-if="getDetailsAboutFieldById(field.id)?.value">
            <v-chip
              label
              color="transparent"
              :href="getDetailsAboutFieldById(field.id)?.value"
              target="_blank"
            >
              Visit <v-icon small right>mdi-open-in-new</v-icon>
            </v-chip>
          </template>
          <template v-else>
            <small style="opacity: 0.5"> No value... </small>
          </template>
        </div>
      </div>
    </template>

    <template v-else-if="field.type === TYPES.LANGUAGE">
      <div>
        <small>
          {{ field.name }}
        </small>
        <div>
          <template
            v-if="getDetailsAboutFieldById(field.id)?.additional?.language"
          >
            <b style="font-size: 0.875rem">{{
              getDetailsAboutFieldById(field.id)?.additional?.language
            }}</b>
          </template>
          <template v-else>
            <small style="opacity: 0.5"> No value... </small>
          </template>
        </div>
      </div>
    </template>

    <template v-else-if="field.type === TYPES.USERS">
      <div>
        <small>
          {{ field.name }}
        </small>
        <v-row dense>
          <v-col
            v-if="getDetailsAboutFieldById(field.id)?.additional?.photo"
            cols="auto"
          >
            <v-avatar size="38">
              <v-img
                :src="getDetailsAboutFieldById(field.id)?.additional?.photo"
              />
            </v-avatar>
          </v-col>
          <v-col cols="fill" class="d-flex flex-column">
            <template
              v-if="getDetailsAboutFieldById(field.id)?.additional?.name"
            >
              <div
                class="text-body-2"
                style="
                  max-width: 200px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                :title="getDetailsAboutFieldById(field.id)?.additional?.email"
              >
                <b>{{
                  getDetailsAboutFieldById(field.id)?.additional?.email
                }}</b>
              </div>
              <div class="text-body-2">
                {{ getDetailsAboutFieldById(field.id)?.additional?.name }}
              </div>
            </template>

            <template v-else>
              <small style="opacity: 0.5"> No value... </small>
            </template>
          </v-col>
        </v-row>
      </div>
    </template>

    <template v-else-if="field.type === TYPES.FREELANCER">
      <div>
        <small>
          {{ field.name }}
        </small>
        <div>
          <template v-if="getDetailsAboutFieldById(field.id)?.additional?.name">
            <div
              class="text-body-2 d-flex justify-space-between"
              style="font-size: 0.875rem"
            >
              <b>{{ getDetailsAboutFieldById(field.id)?.additional?.name }}</b>
              <v-dialog content-class="my-shadow--e2">
                <template #activator="{ on }">
                  <div
                    v-on="on"
                    class="text-normal px-2"
                    style="cursor: pointer"
                  >
                    Details
                    <v-icon small>mdi-information-outline</v-icon>
                  </div>
                </template>
                <v-card flat outlined rounded="lg">
                  <v-card-title class="text-body-2">
                    Freelancer details
                  </v-card-title>
                  <v-divider />
                  <v-card-text class="px-0 pt-5">
                    <v-data-table
                      :items="[getDetailsAboutFieldById(field.id)?.additional]"
                      :headers="FREELANCER_DATA_TABLE_HEADERS"
                    />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </template>

          <template v-else>
            <small style="opacity: 0.5"> No value... </small>
          </template>
        </div>
      </div>
    </template>

    <template v-else>
      <div>
        <small>
          {{ field.name }}
        </small>
        <div>
          <template v-if="getDetailsAboutFieldById(field.id)?.value">
            <b style="font-size: 0.875rem">{{
              getDetailsAboutFieldById(field.id)?.value
            }}</b>
          </template>
          <template v-else>
            <small style="opacity: 0.5"> No value... </small>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { FREELANCER_DATA_TABLE_HEADERS } from "../../../BaseOfFreelance/defaults";
import { CONTENT_FIELD_TYPES } from "../defaults";

export default {
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    details: {
      type: Object,
      default: () => ({}),
    },
    value: {},
  },

  inject: ["filtersData", "filtersDataPendingKeys"],

  data: () => ({
    FREELANCER_DATA_TABLE_HEADERS,
    TYPES: CONTENT_FIELD_TYPES,
  }),

  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    getDetailsAboutFieldById(fieldId) {
      return this.details?.[fieldId];
    },
  },
};
</script>
