export const PAGES_INFO_EMPTY_FILTERS = {
  problems: [],
  error_type: [],
  priority: [null, null],
  need_top: [null, null],
  page_type: [],
  code: [],
  ni: [],
  relinking: null,
  problem_id: null,
  content_length: [null, null],
  title_length: [null, null],
  description_length: [null, null],
  first_seen: [],
  links: [null, null],
  impression: [null, null],
  depth: [null, null],
  anchors: [null, null],
  is_ignored: 0,
  hide_ignore_pages: 1,
  hide_know_problems: 1,
  hide_whole_directories: 1,
  without_keys: 0,
  non_ascii_url: null,
  non_ascii_html: null,
  non_ascii_text: null,
  sitemap: null,
  ltt_links_n: [null, null],
  sl_links_n: [null, null],
};

export const CONTENT_CHECKER_TABLE_HEADERS = [
  {
    text: "URL",
    value: "url",
  },
  {
    text: "NT",
    value: "need_top",
    align: "center",
  },
  {
    text: "Uniq Anchors",
    align: "center",
    value: "uniq_anchors",
  },
  {
    text: "D",
    value: "depth",
    align: "center",
  },
  {
    text: "Uniq Link",
    value: "uniq_link",
    align: "center",
  },
  {
    text: "L",
    value: "links",
    align: "center",
  },
  {
    text: "URL L",
    value: "url_length",
    align: "center",
  },
  {
    text: "Pr",
    value: "priority",
    align: "center",
  },
  {
    text: "Cl",
    value: "clicks",
    align: "center",
  },
  {
    text: "Imp",
    value: "impressions",
    align: "center",
  },
  {
    text: "KC Keys",
    value: "kc_keys",
    align: "center",
  },
  {
    text: "Page Type",
    value: "page_type",
    align: "center",
  },
  {
    text: "Code",
    value: "code",
    align: "center",
  },
  {
    text: "Is Indexed",
    value: "google_index",
    align: "center",
  },
  {
    text: "NI",
    value: "ni",
    align: "center",
  },
  {
    text: "Anchors",
    value: "anchors",
    align: "center",
  },
  {
    text: "Title Length",
    value: "title_length",
    align: "center",
  },
  {
    text: "Desc length",
    value: "desc_length",
    align: "center",
  },
  {
    text: "is Ignored",
    value: "is_ignored",
    align: "center",
  },
  {
    text: "Crawled At",
    value: "crawled_at",
    align: "center",
  },
  { text: "First seen", value: "first_seen", align: "center" },
  { text: "!ASCII h", value: "ascii_in_html", align: "center" },
  { text: "!ASCII t", value: "ascii_in_text", align: "center" },
  { text: "!ASCII u", value: "ascii_in_url", align: "center" },
  { text: "LTT, n", value: "ltt_domains", align: "center" },
  { text: "SL, n", value: "sl_domains", align: "center" },
  { text: "SM", value: "in_sm", align: "center" },
  { text: "SW Date", value: "sw_date", align: "center" },
  { text: "SW Status", value: "sw_status", align: "center" },
];
