<template>
  <v-container fluid>
    <v-row v-if="isset_active_project">
      <v-col cols="12">
        <page-header
          title="LTT Pro: Report"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'LTT Pro: Report', disabled: true },
          ]"
        />
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-select
              v-model="filter.status_id"
              :items="report_status"
              item-text="name"
              item-value="id"
              label="Status"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filter.projects_id"
              :items="projects"
              item-text="name"
              item-value="id"
              label="Project"
              outlined
              hide-details="auto"
              dense
              multiple
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filter.reporter"
              :items="users"
              item-value="id"
              item-text="email"
              label="Reporter"
              dense
              outlined
              clearable
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filter.linkbuilder"
              :items="linkbuildersItems"
              item-value="id"
              item-text="name"
              dense
              label="Linkbuilder"
              outlined
              :disabled="lockLinkbuilder"
              :clearable="!lockLinkbuilder"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <smart-date-filter
              :model-value="filter.date_range"
              @update:modelValue="filter.date_range = $event"
              :custom-presets="datePresets"
              label="Date range"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-btn
              color="primary"
              class="text-normal"
              :loading="loadings.data"
              @click="fetch_data"
              height="40"
              block
              >Get data</v-btn
            >
          </v-col>
          <v-col
            v-if="
              filter.status_id === 1 ||
              filter.status_id === 3 ||
              filter.status_id === 4
            "
            cols="6"
            md="4"
            lg="2"
            xl="fill"
          >
            <v-btn class="text-normal" height="40" block @click="send_data">
              Send email
              <v-icon right small>mdi-email-send</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              placeholder="Type to search"
              outlined
              clearable
              dense
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0">
            <v-data-table
              :search="search"
              :headers="table_headers"
              :items="items"
              show-select
              :footer-props="{
                'items-per-page-options': [10, 15, 50, -1],
              }"
              :items-per-page="50"
              class="elevation-1"
              dense
              @change="handleChange"
            >
              <template #header.data-table-select>
                <div @click="handleChange">
                  <v-checkbox
                    v-model="tableSelect"
                    :indeterminate="isInterminate"
                    :value="isChecked"
                  ></v-checkbox>
                </div>
              </template>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td>
                      <v-checkbox v-model="item.checked"></v-checkbox>
                    </td>
                    <td :style="get_width_action(item)">
                      <div
                        v-if="item.status_id === 1 || item.status_id === 3"
                        class="d-flex my-1 align-center"
                        style="gap: 0.5rem"
                      >
                        <v-card flat outlined rounded="lg" class="pa-3">
                          <v-switch
                            v-if="item.email === null"
                            disabled
                            inset
                            hide-details
                            label="lb"
                            dense
                            class="ma-1"
                            v-model="item.lb_send"
                          ></v-switch>
                          <v-switch
                            v-else
                            hide-details
                            inset
                            label="lb"
                            dense
                            class="ma-1"
                            v-model="item.lb_send"
                          ></v-switch>
                        </v-card>
                        <v-card flat outlined rounded="lg" class="pa-3">
                          <v-switch
                            hide-details
                            inset
                            dense
                            class="ma-1"
                            label="seo"
                            v-model="item.seo_send"
                          ></v-switch>
                        </v-card>
                        <v-tooltip bottom open-delay="300">
                          <template #activator="{ on }">
                            <v-btn
                              v-on="on"
                              class="px-2 text-normal"
                              style="min-width: 38px"
                              color="error"
                              :loading="loadings.remove === item.id"
                              :disabled="deletedProblems.includes(item.id)"
                              @click="handleRemove(item)"
                            >
                              <v-icon small>mdi-trash-can</v-icon>
                            </v-btn>
                          </template>
                          Remove problem
                        </v-tooltip>
                      </div>
                      <span v-else-if="item.status_id === 4">
                        <v-btn-toggle v-model="item.done" borderless>
                          <v-btn value="0">
                            <span class="hidden-sm-and-down">Reopen</span>
                            <v-icon right> mdi-backup-restore </v-icon>
                          </v-btn>

                          <v-btn value="1">
                            <span class="hidden-sm-and-down">Fixed</span>
                            <v-icon right> mdi-playlist-check </v-icon>
                          </v-btn>
                        </v-btn-toggle>
                      </span>
                      <span v-else>
                        {{ item.id }}
                      </span>
                      <v-btn
                        v-if="
                          item.status_id === 2 ||
                          item.status_id === 5 ||
                          item.status_id === 6
                        "
                        icon
                        @click="seeDetails(item)"
                      >
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </td>
                    <td v-if="item.status_id === 4">
                      {{ item.status }}
                    </td>
                    <td>
                      {{ item.problem_type }}
                    </td>
                    <td>
                      {{ item.problem_priority }}
                    </td>
                    <td>
                      {{ item.url_donor }}
                    </td>
                    <td>
                      {{ item.url_acceptor }}
                    </td>
                    <td>
                      {{ item.type
                      }}<span title="level">({{ item.level }})</span>
                    </td>
                    <td>
                      {{ item.islink }}
                    </td>
                    <td>
                      <span v-if="item.email === null">
                        the link has no author
                      </span>
                      <span v-else>
                        {{ item.user }}
                      </span>
                    </td>
                    <td>
                      {{ item.comment }}
                    </td>
                    <td>
                      {{ item.comment_lb }}
                    </td>
                    <td>
                      {{ item.updated_at }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else dense>
      <v-col cols="12"
        ><v-alert
          icon="mdi-shield-lock-outline"
          prominent
          text
          type="info"
          dark
        >
          <div class="fz-14">
            <b>Selected project is required</b>
          </div>
          <div class="mb-4">To view the page, you must select a project.</div>
          <v-btn
            color="primary"
            large
            class="text-normal px-6"
            @click="$store.dispatch('toggle_select_project_menu')"
          >
            Select project
          </v-btn>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import service from "../../../plugins/service";
import PageHeader from "../../UI/PageHeader.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import Service from "../../../plugins/service";
import Notification from "../../../mixins/Notification";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
import { DEFAULT_MOMENT_DATE_FORMAT } from "../../../utils/defaultData";
import { uid } from "../../../utils/functions";

export default {
  name: "Index",
  components: { SmartDateFilter, PageHeader },
  mixins: [FiltersHandling, Notification],
  async mounted() {
    await this.parseQuery("filter");

    this.fetchLinkbuilders();

    this.fetch_report_status();
    this.fetchUsers();
  },
  data() {
    return {
      datePresets: [
        {
          id: uid(),
          name: "Today",
          range: [
            this.$moment().startOf("day").format(DEFAULT_MOMENT_DATE_FORMAT),
            this.$moment().endOf("day").format(DEFAULT_MOMENT_DATE_FORMAT),
          ],
        },
        {
          id: uid(),
          name: "Week",
          range: [
            this.$moment().startOf("week").format(DEFAULT_MOMENT_DATE_FORMAT),
            this.$moment().endOf("week").format(DEFAULT_MOMENT_DATE_FORMAT),
          ],
        },
      ],
      selectedTableItems: [],
      search: "",
      loadings: {
        remove: null,
        data: false,
      },
      deletedProblems: [],
      headers: [
        { text: "action", title: "action", value: "id", class: "text-left" },
        {
          text: "status",
          title: "status",
          value: "status",
          class: "text-left",
          align: "d-none",
        },
        {
          text: "problem_type",
          title: "problem_type",
          value: "problem_type",
          class: "text-left",
        },
        {
          text: "problem_priority",
          title: "problem_priority",
          value: "problem_priority",
          class: "text-left",
        },
        {
          text: "url_donor",
          title: "url_donor",
          value: "url_donor",
          class: "text-left",
        },
        {
          text: "url_acceptor",
          title: "url_acceptor",
          value: "url_acceptor",
          class: "text-center",
        },
        { text: "type", title: "type", value: "type", class: "text-center" },
        {
          text: "islink",
          title: "islink",
          value: "islink",
          class: "text-center",
        },
        { text: "user", title: "user", value: "user", class: "text-center" },
        {
          text: "comment",
          title: "comment",
          value: "comment",
          class: "text-center",
        },
        {
          text: "comment_lb",
          title: "comment_lb",
          value: "comment_lb",
          class: "text-center",
        },
        {
          text: "updated_at",
          title: "updated_at",
          value: "updated_at",
          class: "text-center",
        },
      ],
      linkbuildersItems: [],
      headers_active: {
        status: false,
      },
      items: [],
      users: [],
      filter: {
        date_range: [null, null],
        status_id: null,
        projects_id: [],
        linkbuilder: null,
        reporter: null,
      },
      report_status: [],
      tableSelect: null,
      lockLinkbuilder: false,
    };
  },
  methods: {
    async handleRemove(item) {
      try {
        this.loadings.remove = item.id;
        const url = `/ltt/reports/${item.id}`;

        await Service.delete(url);

        this.mSuccess({
          title: "Deleted",
          text: "Problem successfully deleted.",
        });
        this.deletedProblems.push(item.id);
      } catch (e) {
        console.error("Error while removing.", e);
      } finally {
        this.loadings.remove = null;
      }
    },
    async fetchLinkbuilders() {
      const url = "/ltt/reports/get/linkbuilder";

      const resp = await service.get(url);

      if (resp) {
        this.linkbuildersItems = resp.data.data;

        if (this.linkbuildersItems.length === 1) {
          this.lockLinkbuilder = true;
          this.filter.linkbuilder = this.linkbuildersItems[0].id;
        }
      }
    },
    seeDetails(item) {
      const url = `https://api.serpinsider.com/tools/ltt/lb/response/${item.links_id}?level=${item.level}`;
      window.open(url, "_blank");
    },
    handleChange() {
      const checkedLength = this.items.filter((v) => v.checked);

      if (this.items.length === checkedLength.length) {
        this.tableSelect = false;
        return this.items.forEach((v) => (v.checked = false));
      }

      this.tableSelect = true;
      this.items.forEach((v) => (v.checked = true));
    },
    async fetchUsers() {
      const projectId = this.$store.state.project.active_project;
      const resp = await this.$store.dispatch("global/getUsers", { projectId });

      if (resp) {
        this.users = resp;
      }
    },
    fetch_report_status() {
      let url = `${this.$store.state.server_url}/ltt/reports/get/data-status`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {};

      axios
        .post(url, data, config)
        .then((response) => {
          this.report_status = response.data;
          this.filter.status_id = response.data[0].id;
        })
        .catch(function (error) {
          alert(error);
        });
    },
    fetch_data() {
      this.loadings.data = true;

      let url = `${this.$store.state.server_url}/ltt/reports/get/data`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      if (this.filter.status_id === 4) {
        this.headers_active["status"] = true;
      } else {
        this.headers_active["status"] = false;
      }

      axios
        .post(url, this.filter, config)
        .then((response) => {
          this.items = response.data;
          this.$forceUpdate();
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(() => {
          this.loadings.data = false;
        });
    },
    send_data() {
      let self = this;
      self.update_loading("send_data", true);

      let url = `${self.$store.state.server_url}/ltt/reports/set/data`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {
        data: this.items,
      };

      self.items = [];

      axios
        .post(url, data, config)
        .then(function () {
          self.update_loading("send_data", false);
          self.fetch_data();
        })
        .catch(function (error) {
          alert(error);
        });
    },
    get_width_action(item) {
      let style = "";
      if (item.status_id === 1 || item.status_id === 3) {
        style = "min-width: 200px";
      } else if (item.status_id === 4) {
        style = "min-width: 210px";
      }
      return style;
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    isInterminate() {
      const checkedLength = this.items.filter((v) => v.checked);

      return (
        checkedLength.length > 0 && this.items.length !== checkedLength.length
      );
    },
    isChecked() {
      const checkedLength = this.items.filter((v) => v.checked);

      return (
        this.items.length > 0 && this.items.length === checkedLength.length
      );
    },
    table_headers() {
      let headers = [];

      for (let i in this.headers) {
        if (
          this.headers_active[this.headers[i].value] === undefined ||
          this.headers_active[this.headers[i].value]
        ) {
          headers.push(this.headers[i]);
        }
      }
      return headers;
    },
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
    projects() {
      return this.$store.state.project.allowed_projects;
    },
  },
};
</script>

<style scoped></style>
