<template>
  <div>
    <template v-if="loading">
      <v-row>
        <v-col cols="12">
          <div class="skeleton" style="height: 120px; width: 100%" />
        </v-col>
      </v-row>
    </template>
    <template v-else-if="formValue?.info_fields?.length">
      <v-row dense class="d-flex flex-column">
        <template v-for="(field, idx) in computedVisibleInfoFields">
          <v-col v-if="field" cols="12" :key="`field-${idx}`" class="relative">
            <v-hover v-slot="{ hover }" :disabled="field?.loadings?.saving">
              <div>
                <element-control-info-field-factory
                  v-show="!field.isEditing"
                  :field="field"
                  :details="formValue?.fields"
                  v-model="field.value"
                />
                <div v-show="field.isEditing" class="px-4 py-1">
                  <element-control-field-factory
                    :field="field"
                    v-model="field.value"
                    :details="formValue?.fields"
                  />
                </div>
                <v-hover v-slot="{ hover: hover2 }">
                  <v-btn
                    small
                    @click="toggleEditing(field)"
                    v-show="hover"
                    :loading="field?.loadings?.saving"
                    class="px-2 text-normal flat-chip"
                    :class="{
                      'flat-chip--success': field.isEditing,
                    }"
                    style="min-width: 32px; top: 0.25rem; right: 0.5rem"
                    right
                    top
                    absolute
                  >
                    <span v-if="hover2">
                      {{ field.isEditing ? "Save" : "Edit" }}
                    </span>
                    <v-icon size="12" :right="hover2">{{
                      field.isEditing ? "mdi-content-save" : "mdi-pencil"
                    }}</v-icon>
                  </v-btn>
                </v-hover>
              </div>
            </v-hover>
          </v-col>
          <v-divider
            v-if="idx !== computedVisibleInfoFields?.length - 1"
            :key="`field-divider-${idx}`"
            :details="formValue?.fields"
            v-model="field.value"
          />
        </template>
      </v-row>
    </template>
    <template v-else
      ><div
        class="text-center d-flex flex-column align-center pa-4"
        style="height: 100%; opacity: 0.5"
      >
        <v-icon>mdi-border-none-variant</v-icon>
        <small> There are no info fields... </small>
      </div>
    </template>
    <div
      v-if="computedContainSomeNotRequiredItems"
      class="px-2 pb-2 text-center"
    >
      <v-btn
        class="text-normal"
        :color="isShowMoreVisible ? '' : 'transparent'"
        @click="isShowMoreVisible = !isShowMoreVisible"
      >
        {{ isShowMoreVisible ? "Show less" : "Show more" }}
        <v-icon small right>
          {{ isShowMoreVisible ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import ElementControlInfoFieldFactory from "./ElementControlInfoFieldFactory.vue";
import ElementControlFieldFactory from "./ElementControlFieldFactory.vue";
import { CONTENT_FIELD_TYPES, EVENTS } from "../defaults";
import { SiteWorkSheetApiService } from "../services/SiteWorkSheetApiService";
import { deepClone } from "../../../../utils/functions";
import eventPipe from "../../../../events/eventPipe";

export default {
  props: {
    formValue: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ElementControlFieldFactory,
    ElementControlInfoFieldFactory,
  },

  data: () => ({
    isShowMoreVisible: false,
    isEditing: false,
  }),

  methods: {
    async toggleEditing(field) {
      // Адаптируем field под интерфейс контент-филда
      if (!("value" in field)) await this.initializeFieldDefaultValue(field);

      // If items is undefined AND items exists in details
      if (
        !("items" in field) &&
        this.getFieldValueFromDetails(field)?.items?.length > 0
      )
        await this.initializeFieldItems(field);

      // Визуальный тогл
      if (field.isEditing === undefined || !field.isEditing) {
        return this.$set(field, "isEditing", true);
      }

      try {
        // Если код дошел сюда - сохранить изменения
        await this.saveChanges(field);
        // Если не было ошибок - обновить данные модалки
        eventPipe.$emit(EVENTS.UPDATE_CFP_MODAL);

        this.$set(field, "isEditing", false);
      } catch (e) {
        console.error("Cant set new field value as default.", e);
      }

      console.log(deepClone(this.formValue));
    },
    setNewFieldsValuesAsDefault(newData) {
      try {
        const clone = deepClone(this.formValue);

        clone.fields = { ...clone.fields, ...newData.fields };
      } catch (e) {
        console.error("Cant set new data as default.", e);
      }
    },
    setFieldLoadings(field, key, payload) {
      if (!field.loadings) this.$set(field, "loadings", {});

      this.$set(field.loadings, key, payload);
    },
    async saveChanges(field) {
      try {
        if (!this.formValue.url_info.url_id)
          return console.error("Cant save field changes without ulr_id.");

        this.setFieldLoadings(field, "saving", true);

        const response = await SiteWorkSheetApiService().updateField(
          this.formValue.url_info.url_id,
          {
            fields: {
              [field.id]: this.getFieldValue(field),
            },
          }
        );

        return response?.data || {};
      } catch (e) {
        console.error("Error while saving field changes.", e);
        throw e;
      } finally {
        this.setFieldLoadings(field, "saving", false);
      }
    },
    async initializeFieldDefaultValue(field) {
      try {
        return new Promise((resolve) => {
          const details = this.getFieldValueFromDetails(field);

          this.setDefaultValueToField(field, details);

          resolve();
        });
      } catch {
        console.error("Error while initializing fieldValue.", field);
      }
    },
    getFieldValue(field) {
      if (field.type === CONTENT_FIELD_TYPES.PRICE) {
        return {
          currency: field.currency,
          value: parseInt(field.value),
        };
      }

      return field.value;
    },
    setDefaultValueToField(field, details) {
      if (field.type === CONTENT_FIELD_TYPES.PRICE) {
        this.$set(field, "value", details?.value?.value);
        this.$set(field, "currency", details?.value?.currency);
        return;
      }

      this.$set(field, "value", details?.value || null);
    },
    async initializeFieldItems(field) {
      try {
        return new Promise((resolve) => {
          const details = this.getFieldValueFromDetails(field);

          if (details?.items) this.$set(field, "items", details.items);

          resolve();
        });
      } catch {
        console.error("Error while initializing fieldItems.", field);
      }
    },
    getFieldValueFromDetails(field) {
      return this.formValue?.fields?.[field.id];
    },
  },

  computed: {
    computedContainSomeNotRequiredItems() {
      return this.formValue.info_fields.some(
        (item) => !item?.pivot?.is_required
      );
    },
    computedInfoFields() {
      return this.formValue.info_fields?.toSorted(
        (a, b) => a?.pivot?.order - b?.pivot?.order
      );
    },
    computedVisibleInfoFields() {
      if (this.isShowMoreVisible) {
        return this.computedInfoFields;
      }

      return this.computedInfoFields.filter((item) => item?.pivot?.is_required);
    },
  },
};
</script>
