<template>
  <v-card flat outlined rounded="lg">
    <v-card-title class="text-body-2 py-2 pr-2 d-flex">
      <v-icon small left>mdi-file-document-multiple-outline</v-icon
      ><b>Content Process</b>
      <v-spacer />

      <v-text-field
        v-model="search"
        hide-details
        dense
        outlined
        placeholder="Type to search"
        append-icon="mdi-magnify"
      />
    </v-card-title>
    <v-divider />
    <v-card-text class="px-0 pt-0">
      <v-data-table
        :search="search"
        multi-sort
        :items="items"
        :sort-by="['priority', 'need_top']"
        :sort-desc="[false, false]"
        :headers="computedHeaders"
        class="v-data-table--flex-headers"
      >
        <template v-for="h in headers" #[`item.${h.value}`]="{ item, value }">
          <template v-if="avatar.includes(h.value)">
            <user-preview
              :name="value"
              :key="h.value"
              hide-initials
              class="justify-center"
            />
          </template>
          <template v-else-if="status.includes(h.value)">
            <v-chip
              v-if="value"
              :color="getLastChangeStatusColor(value, item)"
              :title="value"
              :key="h.value"
            >
              {{ value ? $moment(value).format("MMM DD") : "" }}
              <v-tooltip
                bottom
                open-delay="100"
                content-class="pa-0 rounded-lg"
              >
                <template #activator="{ on }">
                  <v-icon v-on="on" small right>
                    mdi-information-outline
                  </v-icon>
                </template>
                <v-sheet rounded="lg" outlined elevation="0" class="pa-4">
                  <div>
                    Diff in hours –
                    <b>{{ item._last_change_status?.diff_in_hours }}</b>
                  </div>
                  <div>
                    Critical time –
                    <b>{{ item._last_change_status?.critical_time }}</b>
                  </div>
                </v-sheet>
              </v-tooltip>
            </v-chip>
          </template>
          <template v-else-if="links.includes(h.value)">
            <v-chip
              v-if="value"
              :href="value"
              :key="h.value"
              label
              color="transparent"
              class="px-2"
              style="margin-left: -0.875rem"
              target="_blank"
            >
              <v-icon small>mdi-open-in-new</v-icon>
            </v-chip>
          </template>
          <template v-else-if="url.includes(h.value)">
            <a :href="value" target="_blank" :key="h.value">
              {{ formatUrl(value).pathname }}
            </a>
          </template>
          <template v-else>
            {{ value }}
          </template>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import UserPreview from "../../../UI/UserPreview.vue";

export default {
  components: { UserPreview },
  props: ["items", "headers"],

  data: () => ({
    search: null,
    status: ["last_change_status"],
    avatar: ["publishing_executive", "writer", "brief_creator"],
    url: ["url"],
    links: [
      "brief_template",
      "content_brief",
      "cb_editor_live_task",
      "text_doc",
    ],
  }),

  computed: {
    computedHeaders() {
      if (!this.headers?.length) return [];

      return this.headers.map((header) => {
        if (header.value === "url") {
          //
        } else {
          header.align = "center";
        }

        return header;
      });
    },
  },

  methods: {
    getLastChangeStatusColor(value, data) {
      if (data.deadline) return "error";

      return "primary";
    },
    formatUrl(url) {
      return new URL(url);
    },
  },
};
</script>
