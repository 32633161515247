<template>
  <div class="links-monitor">
    <v-row v-if="loadings.table && firstFetch">
      <v-col cols="12">
        <div class="links-monitor__table">
          <v-skeleton-loader type="button" class="top" />
          <v-skeleton-loader type="table-row-divider@10"></v-skeleton-loader>
          <v-skeleton-loader type="button" class="bottom" />
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title style="gap: 0.5rem">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              placeholder="Type to search"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-btn
              :loading="loadings.saving"
              :class="`${savingStatus === 1 ? 'done' : ''}${
                savingStatus === 2 ? 'not-done' : ''
              }`"
              @click="handleSaveTable"
              :color="savingStatus === 1 ? 'success' : ''"
              class="text-normal"
            >
              <v-icon left v-if="savingStatus === 0">mdi-content-save</v-icon>
              <v-icon left v-else-if="savingStatus === 1"
                >mdi-content-save-check</v-icon
              >
              <v-icon left v-else-if="savingStatus === 2"
                >mdi-content-save-off</v-icon
              >
              <template v-if="savingStatus === 0"> Save </template>
              <template v-if="savingStatus === 1"> Saved 👍 </template>
              <template v-if="savingStatus === 2"> Error </template>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0">
            <v-data-table
              :search="search"
              v-model="selected"
              :headers="tableData.headers"
              :items="tableData.items"
              class="links-monitor__table"
              item-key="url"
              show-select
            >
              <template v-slot:item.dateadd="{ item: { dateadd } }">
                {{ $moment(dateadd).format("MM/DD/YYYY") }}
              </template>
              <template v-slot:item.donor="{ item: { donor } }">
                <div class="table__link" @click="goTo(donor)">
                  {{ donor }}
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { DEFAULT_LINKS_MONITOR_HEADERS } from "@/utils/defaultData";

export default {
  name: "Index",
  async created() {
    await this.fetchTableData();
    this.firstFetch = false;
  },
  data: () => ({
    selected: [],
    search: "",
    loadings: {
      table: false,
      saving: false,
    },
    tableData: {
      headers: DEFAULT_LINKS_MONITOR_HEADERS,
      items: [],
    },
    firstFetch: true,
    savingStatus: 0,
  }),
  watch: {
    selected(items = []) {
      const tableItems = this.tableData.items;
      items.forEach((item) => {
        const id = item.url_id;
        const idx = tableItems.findIndex((item) => item.url_id === id);
        if (idx !== -1) {
          tableItems[idx].is_good = 1;
        }
      });
      this.tableData.items = tableItems;
    },
  },
  methods: {
    goTo(url) {
      window.open(url, "_blank");
    },
    handleSaveTable() {
      this.saveSelectedItems();
    },
    async fetchTableData() {
      this.loadings.table = true;

      const resp = await this.$store.dispatch(
        "links-monitor/fetchTableData",
        {}
      );
      if (resp) {
        this.tableData.items = resp;
      }

      this.loadings.table = false;
    },
    async saveSelectedItems() {
      this.loadings.saving = true;

      const payload = { data: this.tableData.items };

      const resp = await this.$store.dispatch("links-monitor/save", payload);
      setTimeout(() => {
        if (resp) {
          this.savingStatus = 1;
          this.fetchTableData();
        } else {
          this.savingStatus = 2;
        }
        this.loadings.saving = false;
      }, 1000);
      setTimeout(() => (this.savingStatus = 0), 5000);
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
    getProjects() {
      return this.$store.state.project.allowed_projects;
    },
  },
};
</script>

<style scoped></style>
