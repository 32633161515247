<template>
  <v-form ref="formRef">
    <v-row>
      <!-- START Основная информаци -->

      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2"> Main information </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Name / Nickname
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.name"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['name']"
                  @blur="validation['name'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Contact
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.contacts"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['contacts']"
                  @blur="validation['contacts'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Language
                </element-control-content-field-label>
                <v-autocomplete
                  v-model="form.languages"
                  :items="filtersData?.languages"
                  hide-details="auto"
                  dense
                  multiple
                  outlined
                  :error-messages="validation['languages']"
                  @blur="validation['languages'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Niche
                </element-control-content-field-label>
                <v-autocomplete
                  v-model="form.niches"
                  :items="filtersData?.niches"
                  hide-details="auto"
                  dense
                  multiple
                  outlined
                  :error-messages="validation['niches']"
                  @blur="validation['niches'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Status
                </element-control-content-field-label>
                <v-autocomplete
                  v-model="form.status"
                  hide-details="auto"
                  :items="filtersData['freelancer_statuses']"
                  dense
                  outlined
                  :error-messages="validation['status']"
                  @blur="validation['status'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="notRequired">
                  Portfolio
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.portfolio"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['portfolio']"
                  @blur="validation['portfolio'] = []"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- END Основная информаци -->
      <!-- START Качество и выполнение работы -->

      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2"> Main information </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Time to write
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.time_to_write"
                  hide-details="auto"
                  dense
                  outlined
                  type="number"
                  :error-messages="validation['time_to_write']"
                  @blur="validation['time_to_write'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  SEO Knowledge
                </element-control-content-field-label>
                <v-autocomplete
                  v-model="form.seo_knowledge"
                  hide-details="auto"
                  :items="filtersData?.freelancer_base_selects"
                  dense
                  outlined
                  :error-messages="validation['seo_knowledge']"
                  @blur="validation['seo_knowledge'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Niche knowledge
                </element-control-content-field-label>
                <v-autocomplete
                  v-model="form.niche_knowledge"
                  hide-details="auto"
                  :items="filtersData?.freelancer_base_selects"
                  dense
                  outlined
                  :error-messages="validation['niche_knowledge']"
                  @blur="validation['niche_knowledge'] = []"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2">
            Quality and performance of work
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <element-control-content-field-label :field="notRequired">
                  Text quality
                </element-control-content-field-label>
                <v-autocomplete
                  v-model="form.text_quality"
                  hide-details="auto"
                  dense
                  outlined
                  :items="filtersData?.freelancer_text_qualities"
                  :error-messages="validation['text_quality']"
                  @blur="validation['text_quality'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="notRequired">
                  Time to edit
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.time_to_edit"
                  hide-details="auto"
                  type="number"
                  dense
                  outlined
                  :error-messages="validation['text_quality']"
                  @blur="validation['text_quality'] = []"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- END Качество и выполнение работы -->
      <!-- START Финансовые условия -->

      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2">
            Financial conditions
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Payment type
                </element-control-content-field-label>
                <v-autocomplete
                  v-model="form.payment_methods"
                  hide-details="auto"
                  :items="filtersData?.payment_methods"
                  multiple
                  dense
                  outlined
                  :error-messages="validation['payment_methods']"
                  @blur="validation['payment_methods'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Rate (p 100 w)
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.rate"
                  hide-details="auto"
                  dense
                  type="number"
                  outlined
                  :error-messages="validation['rate']"
                  @blur="validation['rate'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="notRequired">
                  Optimization Rate (p 100 w)
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.optimisation_rate"
                  hide-details="auto"
                  dense
                  type="number"
                  min="0"
                  max="255"
                  outlined
                  :rules="[(v) => parseInt(v) <= 255 || 'Max values is 255']"
                  :error-messages="validation['optimization_rate']"
                  @blur="validation['optimization_rate'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="notRequired">
                  Urgency Rate (p 100 w)
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.urgency_rate"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['urgency_rate']"
                  @blur="validation['urgency_rate'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="notRequired">
                  NDA availability
                </element-control-content-field-label>
                <v-select
                  v-model="form.nda"
                  hide-details="auto"
                  :items="[
                    {
                      text: 'Yes',
                      value: 1,
                    },
                    {
                      text: 'No',
                      value: 0,
                    },
                  ]"
                  dense
                  outlined
                  :error-messages="validation['nda']"
                  @blur="validation['nda'] = []"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2">
            Evaluation of communication
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Comments
                </element-control-content-field-label>
                <v-textarea
                  v-model="form.comment"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['comment']"
                  @blur="validation['comment'] = []"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ElementControlContentFieldLabel from "../../SiteWorkplace/SiteWorksheet/components/ElementControlContentFieldLabel.vue";

export default {
  components: { ElementControlContentFieldLabel },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    validationKey: {
      type: String,
      default: "",
    },
    filtersData: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    notRequired: { pivot: { is_required: false } },
    required: { pivot: { is_required: true } },
  }),

  computed: {
    validation() {
      return this.$store.getters[this.validationKey];
    },
    form: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
