<template>
  <div class="d-flex align-center" :title="getTitle">
    <v-avatar
      :style="{
        background: getUserColorsData.bg,
      }"
      size="36"
    >
      <span
        :style="{
          color: getUserColorsData.text,
          'font-size': '12px',
        }"
      >
        {{ getInitials }}
      </span>
    </v-avatar>
    <div v-if="!hideInitials" class="text-body-2 d-flex flex-column ml-2">
      <div v-if="email">
        <small>{{ email }}</small>
      </div>
      <div v-if="name">
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script>
import { stringToAvatarData } from "@/functions";

export default {
  props: {
    email: String,
    name: String,
    hideInitials: Boolean,
    opacity: Number,
  },

  methods: {
    getNameInitials(name) {
      try {
        if (!name || typeof name !== "string") return "";

        if (name.includes("@boosta.co")) {
          // example: firstName.lastName@boosta.co
          const [first, last] = name.split("@")[0].split(".");

          return (first[0] + last[0]).toUpperCase();
        }

        const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

        let initials = [...name.matchAll(rgx)] || [];

        initials = (
          (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
        ).toUpperCase();

        return initials;
      } catch (e) {
        console.error("Error while parsing name to initials.", e);
      }
    },
  },

  computed: {
    getInitials() {
      return this.getNameInitials(this.name || this.email);
    },
    getTitle() {
      let result = "";

      if (this.name) result += this.name;

      if (this.email) result += ` | ${this.email}`;

      return result;
    },
    getUserColorsData() {
      return stringToAvatarData(
        this.name || this.email,
        this.opacity || 0.2,
        this.$vuetify.theme.dark
      );
    },
  },
};
</script>
