<template>
  <div class="urls-overview">
    <v-row dense>
      <v-col cols="12">
        <page-header title="4DX Urls Overview" :breadcrumbs="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-form ref="formRef">
          <v-row dense>
            <v-col>
              <v-autocomplete
                v-model="filters.project"
                :items="filtersData.projects"
                label="Projects"
                placeholder="Type to search"
                dense
                hide-details="auto"
                outlined
                clearable
                append-icon="mdi-profile"
                :loading="filtersDataPendingKeys.projects"
              />
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="filters.subproject"
                :items="filtersData.subprojects"
                multiple
                clearable
                label="Subproject"
                placeholder="Type to search"
                dense
                :loading="filtersDataPendingKeys.subprojects"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="filters.status_code"
                :items="filtersData.status_codes"
                hide-details
                multiple
                dense
                outlined
                clearable
                label="Status code"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <easy-range-input
                label="Need Top"
                :model-value="filters.need_top"
                :presets="needTopPresets"
                @update:modelValue="filters.need_top = $event"
              />
            </v-col>
            <v-col>
              <div class="fix-button-height">
                <smart-date-filter
                  :model-value="filters.date"
                  :custom-presets="customDatePresets"
                  @update:modelValue="filters.date = $event"
                />
              </div>
            </v-col>
            <v-col>
              <v-btn
                block
                height="40"
                color="primary"
                class="text-normal"
                @click="handleGetData"
                :loading="loadings.table"
              >
                Get Data
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <custom-overiview-table
          :headers="tableData.headers"
          :items="tableData.items"
          :loading="loadings.table"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Project from "../../../mixins/Project";
import EasyRangeInput from "@/components/UI/EasyRangeInput.vue";
import { uid } from "@/utils/functions";
import PageHeader from "@/components/UI/PageHeader.vue";
import CustomOveriviewTable from "../../UI/CustomOveriviewTable.vue";
import {
  URLS_OVERVIEW_4DX_TABLE_HEADERS,
  DEFAULT_MOMENT_DATE_FORMAT,
} from "../../../utils/defaultData";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import Location from "../../../mixins/Location";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";

export default {
  components: {
    PageHeader,
    SmartDateFilter,
    EasyRangeInput,
    CustomOveriviewTable,
  },
  mixins: [Location, FiltersHandling, Project, UploadFiltersDataMixin],
  data: function () {
    return {
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Dashboards",
          disabled: true,
        },
        {
          text: "4DX Urls Overview",
          disabled: true,
        },
      ],
      filters: {
        niche: [],
        status_code: [200],
        executive: [],
        project: null,
        subproject: [],
        date: [],
        need_top: [0, 0],
      },
      filtersData: {
        status_codes: [],
        executive: [],
        subproject: [],
      },
      firstEnter: true,
      loadings: {
        table: false,
      },
      tableData: {
        headers: URLS_OVERVIEW_4DX_TABLE_HEADERS,
        items: [],
      },
      FILTER_TYPES: {
        niche: "niche",
        date: "date",
        executive: "executive",
        project: "project",
        need_top: "need_top",
      },
      customDatePresets: [
        {
          id: uid(),
          name: "Last 200 days",
          range: [
            this.$moment()
              .subtract(200, "day")
              .startOf("day")
              .format(DEFAULT_MOMENT_DATE_FORMAT),
            this.$moment().format(DEFAULT_MOMENT_DATE_FORMAT),
          ],
        },
      ],
      needTopPresets: [
        ["1-3", [1, 3]],
        ["1-5", [1, 5]],
        ["4-10", [4, 10]],
        ["4-20", [4, 20]],
        ["1-50", [1, 50]],
        ["11-50", [11, 50]],
      ],
    };
  },

  async mounted() {
    await this.parseQuery("filters");

    this.uploadFiltersData(["status_codes", "projects", "subprojects"], {
      project: this.filters.project,
    });
    this.initializeDefaultDateFilterValue();

    const haveDate = this.$route.query.date;
    const haveParams = !!Object.keys(this.$route.query).length;

    if (!haveDate) {
      this.date = this.getDefaultDate;
    }
    if (haveParams) {
      if (this.$route.query.project) {
        this.handleProjectChange(this.filters.project);
      }

      this.fetchData();
    }

    this.$watch("filters.project", () => {
      this.filters.subproject = [];

      this.uploadFiltersData(["subprojects"], {
        project: this.filters.project,
      });
    });
  },
  computed: {
    getDefaultDate() {
      const date = new Date();
      const start = this.$moment(date)
        .subtract(3, "months")
        .startOf("day")
        .format("YYYY-MM-DD");
      const end = this.$moment(date).format("YYYY-MM-DD");
      return [start, end];
    },
  },
  methods: {
    initializeDefaultDateFilterValue() {
      if (this.$route.query.date) return;

      const end = this.$moment().format(DEFAULT_MOMENT_DATE_FORMAT);
      const start = this.$moment()
        .subtract(2, "weeks")
        .format(DEFAULT_MOMENT_DATE_FORMAT);

      return (this.filters.date = [start, end]);
    },
    handleProjectChange(event) {
      this._$setActiveProject(event, false);
    },
    handleDateUpdate(event, type) {
      this[type] = event;
      this._$handleFilterChange(event, type);
    },
    handleFilterChange(newValue, type) {
      this._$setQueryParams(type, newValue);
    },
    handleGetData() {
      if (!this.$refs.formRef?.validate()) return;

      this.fetchData();
    },
    async fetchFilters() {
      try {
        const filters = ["subproject"];

        const payload = {
          type: `/dashboards/summary/urls-overview`,
          take: filters,
        };
        this.loadings.filters = true;
        const resp = await this.$store.dispatch("global/getFilters", payload);

        if (resp) {
          this.filtersData = resp;
        }
      } catch (e) {
        console.error("Error while loading filters.", e);
      } finally {
        this.loadings.filters = false;
      }
    },
    async fetchData() {
      try {
        this.loadings.table = true;
        this.firstEnter = false;

        const payload = {
          filter: {
            date_from: this.filters.date[0],
            date_to: this.filters.date[1],
            ...this.filters,
          },
        };

        const resp = await this.$store.dispatch(
          "url-overview/fetchData",
          payload
        );
        if (resp) {
          if (resp.items) this.tableData.items = resp.items;
          if (resp.headers) this.tableData.headers = resp.headers;
        }
      } catch (e) {
        console.error("Error while loading table data.", e);
      } finally {
        this.loadings.table = false;
      }
    },
  },
};
</script>
