<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <page-header
          :title="pageConfigs.title"
          :breadcrumbs="pageConfigs.breadcrumbs"
        />
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title style="gap: 0.25rem">
            <v-text-field
              v-model="searchQuery"
              hide-details
              dense
              outlined
              placeholder="Type to search"
              clearable
              append-icon="mdi-magnify"
            />
            <div class="d-flex" style="gap: 0.25rem">
              <v-btn
                height="40"
                class="text-normal"
                @click="fetchRoles"
                :loading="loadings.roles"
              >
                Get data
                <v-icon small right>mdi-refresh</v-icon>
              </v-btn>
              <v-btn
                height="40"
                color="primary"
                class="text-normal"
                @click="isCreateRoleDialogVisible = true"
              >
                Create role <v-icon right small>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0">
            <v-data-table
              dense
              :search="searchQuery"
              :items="dataTable.items"
              :headers="dataTable.headers"
              :loading="loadings.roles"
              :footer-props="{ itemsPerPageOptions: [50, 150, 300, -1] }"
            >
              <template #[`item._actions`]="{ item }">
                <v-menu
                  bottom
                  offset-y
                  :close-on-content-click="false"
                  content-class="my-shadow--e3"
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      :loading="loadings.edit === item.id"
                      :disabled="!!loadings.edit"
                    >
                      <v-icon small>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="styled-card--default pa-0" width="120">
                    <v-list dense>
                      <v-list-item @click="handleEditRole(item)">
                        <v-icon small left>mdi-pencil</v-icon> Edit
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <!--3. Edit role-->
      </v-col>
    </v-row>
    <create-role-dialog
      v-model="isCreateRoleDialogVisible"
      :after-create-callback="() => fetchRoles()"
    />
    <edit-role-dialog
      v-model="isEditRoleDialogVisible"
      :data="editData"
      @afterEdit="handleAfterEdit"
      @dialogClosed="handleAfterEdit"
    />
  </v-container>
</template>

<script>
import PageHeader from "../UI/PageHeader.vue";
import { PAGE_CONFIG, ROLES_DATA_TABLE_HEADERS } from "./defaults";
import { RolesApiService } from "./services/RolesApiService";
import CreateRoleDialog from "./components/CreateRoleDialog.vue";
import EditRoleDialog from "./components/EditRoleDialog.vue";
import EasyDataTableSearchMixin from "../../mixins/utils/EasyDataTableSearchMixin";

export default {
  components: { EditRoleDialog, CreateRoleDialog, PageHeader },

  mixins: [EasyDataTableSearchMixin],

  data: () => ({
    pageConfigs: PAGE_CONFIG,
    searchQuery: "",
    isCreateRoleDialogVisible: false,
    isEditRoleDialogVisible: false,
    editData: null,
    dataTable: {
      items: [],
      headers: ROLES_DATA_TABLE_HEADERS,
    },
    loadings: {
      roles: false,
      edit: null,
    },
    pagination: {
      current: 1,
      per_page: 50,
      total: 0,
    },
  }),

  mounted() {
    this.fetchRoles();
  },

  methods: {
    handleAfterEdit() {
      this.editData = null;
    },
    async handleEditRole(role) {
      this.editData = await this.fetchRole(role);
      this.isEditRoleDialogVisible = true;
    },
    async fetchRole(role) {
      try {
        this.loadings.edit = role.id;

        const { data } = await RolesApiService().getRole(role.id);

        return data;
      } catch (e) {
        console.error("Error while fetching roles.", e);
      } finally {
        this.loadings.edit = null;
      }
    },
    async fetchRoles() {
      try {
        this.loadings.roles = true;

        const { data } = await RolesApiService().getRoles({
          page: this.pagination.current,
          rowsPerPage: this.pagination.per_page,
          search: this.searchQuery,
        });

        this.dataTable.items = data.data;
      } catch (e) {
        console.error("Error while fetching roles.", e);
      } finally {
        this.loadings.roles = false;
      }
    },
    handleSearchChanged() {
      this.fetchRoles();
    },
    onPageChange(newPage) {
      this.pagination.current = newPage;
      this.fetchRoles();
    },
    onRowsPerPageChange(newRowsPerPage) {
      this.pagination.per_page = newRowsPerPage;
      this.fetchRoles();
    },
  },
};
</script>
