<template>
  <v-dialog
    v-model="dialog"
    content-class="my-shadow--e2"
    width="680"
    scrollable
    persistent
  >
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2"> Edit freelancer </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <freelancer-form
          v-model="formValue"
          :validation="validationResult"
          validation-key="bof/getEditValidationResult"
          :filters-data="filtersData"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn @click="dialog = false" large class="text-normal px-6">
          Close
        </v-btn>
        <v-spacer />
        <v-btn
          large
          color="success"
          class="text-normal px-6"
          @click="handleSaveChanges"
          :loading="loadings.save"
        >
          Save changes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../mixins/Dialog";
import FreelancerForm from "./FreelancerForm.vue";
import { ContentFreelancerApiService } from "../services/ContentFreelancerApiService";
import apiErrorHandlingMixin from "../../../mixins/ApiErrorHandlingMixin";

export default {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  components: { FreelancerForm },

  mixins: [Dialog, apiErrorHandlingMixin],

  data: () => ({
    loadings: {
      save: false,
    },
  }),

  computed: {
    filtersData() {
      return this.$store.getters["bof/getFiltersData"];
    },
    formValue: {
      get() {
        return this.form;
      },
      set(newValue) {
        this.$emit("update:form", newValue);
      },
    },
  },

  watch: {
    dialog(visible) {
      if (!visible) this.validationResult = {};
    },
  },

  methods: {
    clearForm() {
      this.formValue = {};
    },
    async handleSaveChanges() {
      try {
        this.loadings.save = true;

        const fixedPayload = {
          ...this.form,
        };

        const { data } = await ContentFreelancerApiService().editFreelancer(
          fixedPayload
        );

        if (data) {
          this.$emit("success", data);
          this.dialog = false;
          this.clearForm();
        }
      } catch (e) {
        console.error("Error while creating new freelancer.", e);
        this.handleApiError(e);
        this.$store.commit(
          "bof/setEditFreelancerValidationErrors",
          this.validationResult
        );
      } finally {
        this.loadings.save = false;
      }
    },
  },
};
</script>
