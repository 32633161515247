<template>
  <v-dialog
    :retain-focus="false"
    v-model="inputVal"
    max-width="1100px"
    content-class="remove-dialog-shadow"
  >
    <v-card class="styled-card shadow-e1-bordered" rounded="lg">
      <v-card-title v-if="alert.shown">
        <template>
          <v-alert
            v-for="message in alert.messages"
            :key="message"
            text
            :type="alert.type"
            style="width: 100%"
            class="mb-0"
            border="left"
            dismissible
            dense
          >
            {{ message }}
          </v-alert>
        </template>
      </v-card-title>
      <v-card-title> Price Donors </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-card rounded="lg" class="styled-card--default">
              <v-card-title> Import from text </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    1. Put the columns to import in the order in which you make
                    the data
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="text.headers"
                      filled
                      label="Headers"
                      hide-details
                      dense
                      :placeholder="'domain;price'"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">2. Insert data (without headers)</v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="text.rows"
                      filled
                      hide-details
                      label="Rows"
                      :placeholder="'domain;price\n'"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  class="flat-chip flat-chip--primary"
                  width="160"
                  :loading="loadings.upload"
                  @click="upload_data(1)"
                  >Upload
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card class="styled-card--default" rounded="lg">
              <v-card-title class="d-flex">
                Import from file
                <v-spacer />
                <a href="/file/AnchorsPlanExample.csv" target="_blank">
                  <v-chip @click="() => {}" label small>
                    Download Example
                    <v-icon small right>mdi-download</v-icon></v-chip
                  >
                </a>
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      v-model="file.names"
                      filled
                      counter
                      dense
                      hide-details
                      label="File input"
                      multiple
                      placeholder="Select your files"
                      prepend-icon="mdi-paperclip"
                      accept=".csv"
                      :show-size="1000"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="overline grey--text text--darken-3 mx-2"
                        >
                          +{{ file.names.length - 2 }} File(s)
                        </span>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="d-flex">
                <v-spacer />
                <v-btn
                  class="flat-chip flat-chip--primary"
                  width="160"
                  :loading="loadings.upload"
                  @click="upload_data(2)"
                  >Upload
                </v-btn>
              </v-card-actions>
            </v-card>

            <v-row>
              <v-col cols="12">
                <v-card elevation="0">
                  <v-card-title> Allowed columns </v-card-title>
                  <v-card-text class="pa-0">
                    <v-simple-table dense>
                      <tbody>
                        <tr>
                          <td>domain</td>
                          <td>example.com</td>
                        </tr>
                        <tr>
                          <td>price</td>
                          <td>200</td>
                        </tr>
                        <tr>
                          <td>currency</td>
                          <td>EUR,GBP,USD,BGN,CZK,DKK,JPY,PLN,UAH</td>
                        </tr>
                        <tr>
                          <td>email_wm</td>
                          <td>lolita.brown0407@gmail.com</td>
                        </tr>
                        <tr>
                          <td>email_gp</td>
                          <td>vladyslav.talasimov@boosta.co</td>
                        </tr>
                        <tr>
                          <td>period</td>
                          <td>permanent, per year, per month</td>
                        </tr>
                        <tr>
                          <td>source</td>
                          <td>
                            gpm, research hub, manual, kosmolot, valgro,
                            prposting, bazoom,whitehatlinks, kazboozt,
                            collaborator
                          </td>
                        </tr>
                        <tr>
                          <td>price_type</td>
                          <td>
                            default, essay, gambling, article link, content
                          </td>
                        </tr>
                        <tr>
                          <td>max_links</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>comment</td>
                          <td>"20% знижки, якщо більше 2 статтей"</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadDisavowDonors",
  props: ["value"],
  data() {
    return {
      loadings: {
        upload: false,
      },
      alert: {
        data: [],
        shown: false,
        type: "",
      },
      file: { names: [] },
      text: {
        headers:
          "domain;price;currency;email_wm;email_gp;period;source;price_type\n",
        row: "",
      },
      level: 0,
      domains: [],
    };
  },
  created() {},
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    userId() {
      return this.$store.state.auth.user.id;
    },
  },
  methods: {
    upload_data(type) {
      let self = this;

      self.alert.messages = [];
      self.alert.type = "";
      self.alert.shown = false;

      let url = `${self.$store.state.server_url}/donor-base/clean/${
        self.id_project || 0
      }/upload-price/${self.level}`;
      let data = {};
      if (type === 1) {
        data = {
          type: type,
          headers: self.text.headers,
          data: self.text.rows,
          level: self.level,
          project: self.project,
        };
      } else {
        data = new FormData();
        data.append("file", self.file.names[0]);
        data.append("type", type);
        data.append("level", self.level);
        data.append("project", self.project);
      }

      let config = {
        headers: {
          "Content-Type":
            type === 1 ? "application/json" : "multipart/form-data",
          Authorization: this.$store.state.auth.token,
        },
      };

      this.loadings.upload = true;

      axios
        .post(url, data, config)
        .then(function (response) {
          self.alert.messages = response.data.messages;
          self.alert.type = response.data.type;
          self.alert.shown = true;
          self.file.names = [];
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            title: "Something gone wrong",
            type: "error",
            text: "Try again.",
          });
        })
        .finally(() => {
          this.loadings.upload = false;
        });
    },
  },
};
</script>

<style scoped></style>
