<template>
  <v-container fluid style="padding-bottom: 200px">
    <v-row>
      <v-col cols="12">
        <page-header
          title="Link Dynamics Report"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'Link Dynamics Report', disabled: true },
          ]"
        />
      </v-col>
      <v-col cols="12">
        <div class="d-flex" style="gap: 8px">
          <download-csv
            v-if="export_data_status"
            :data="export_data.items"
            :name="export_data.dataFile"
            :labels="export_data.labels"
            :fields="export_data.fields"
            :delimiter="'\t'"
            v-on:export-finished="exported_csv"
          >
            <v-btn class="text-normal" @click="() => {}">
              <v-icon small left>mdi-cloud-download-outline</v-icon>
              Download CSV
            </v-btn>
          </download-csv>
          <v-btn
            v-else
            :disabled="table.items?.length === 0"
            class="text-normal"
            @click="export_csv"
          >
            <v-icon small left>mdi-cloud-download-outline</v-icon>
            Download CSV
          </v-btn>
          <v-menu
            transition="slide-y-transition"
            :close-on-content-click="false"
            nudge-bottom="40"
            v-model="modal.shown"
            :value="modal.shown"
            max-width="620"
            eager
            content-class="my-shadow--e2 rounded-lg"
          >
            <template v-slot:activator="{ on }">
              <v-btn class="text-normal" color="primary" v-on="on">
                <v-icon small left>mdi-filter</v-icon> Filters
                <v-icon small right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card flat rounded="lg" outlined>
              <v-card-title class="text-body-2"> Filters </v-card-title>
              <v-divider />
              <v-card-text>
                <v-form ref="formRef">
                  <v-row>
                    <v-col cols="6">
                      <smart-date-filter
                        :model-value="filter.date_range"
                        @update:modelValue="filter.date_range = $event"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        dense
                        hide-details
                        v-model="filter.interval"
                        :items="intervals"
                        outlined
                        item-value="id"
                        item-text="name"
                        label="Interval"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        dense
                        hide-details
                        v-model="filter.niche"
                        :items="niches"
                        item-value="id"
                        outlined
                        multiple
                        item-text="name"
                        label="Niche"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        dense
                        hide-details
                        v-model="filter.department"
                        :items="departments"
                        item-value="id"
                        multiple
                        outlined
                        item-text="name"
                        label="Department"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="filter.project"
                        :items="filtersData.projects"
                        outlined
                        label="Project"
                        placeholder="Select project"
                        hide-details="auto"
                        dense
                        clearable
                        multiple
                        :rules="computedProjectAndSubprojectRules"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="filter.subprojects"
                        :items="filtersData.subprojects"
                        outlined
                        label="Subproject"
                        placeholder="Select project"
                        hide-details="auto"
                        dense
                        multiple
                        clearable
                        :rules="computedProjectAndSubprojectRules"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="filter.seo"
                        :items="seoItems"
                        :loading="loadings.seo"
                        outlined
                        multiple
                        label="Responsible seo"
                        placeholder="Select user"
                        item-text="email"
                        item-value="id"
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="filter.link_type"
                        dense
                        outlined
                        multiple
                        hide-details
                        item-text="text"
                        item-value="value"
                        :items="linkTypeItems"
                        label="Link type"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="filter.team"
                        dense
                        outlined
                        multiple
                        hide-details
                        :items="filtersData.team"
                        :loading="loadings.filters"
                        label="Team"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="filter.project_categories"
                        :items="filtersData.project_categories"
                        :loading="filtersDataPendingKeys?.project_categories"
                        multiple
                        hide-details
                        dense
                        outlined
                        label="Project categories"
                        placeholder="Type to search"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="filter.last_month"
                        hide-details
                        dense
                        class="ma-0"
                        label="Last month"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="filter.target_urls"
                        hide-details
                        dense
                        class="ma-0"
                        label="Target URL's"
                      ></v-checkbox
                    ></v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="filter.action"
                        hide-details
                        dense
                        class="ma-0"
                        label="Action"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  @click="fetch_data"
                  large
                  color="primary"
                  class="px-6 text-normal"
                  :loading="loadings.fetch_data"
                >
                  Get data
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="12" md="12">
        <v-card
          flat
          outlined
          rounded="lg"
          v-if="loadings.fetch_data && firstEnter"
        >
          <v-skeleton-loader type="table" />
        </v-card>
        <v-card
          v-else
          flat
          outlined
          rounded="lg"
          :loading="loadings.fetch_data"
        >
          <v-card-title class="text-body-2 pa-3">
            <v-text-field
              v-model="search"
              hide-details
              dense
              outlined
              append-icon="mdi-magnify"
              style="max-width: 420px"
              placeholder="Type to search"
            />
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <v-data-table
              :search="search"
              :headers="table.headers"
              :items="table.items"
              :sort-by="['priority']"
              :sort-desc="[true]"
              :items-per-page="-1"
              dense
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr
                    style="white-space: nowrap"
                    :style="get_style_row(item.lag_on_page)"
                    v-for="(item, index) in items"
                    :key="`item-${index}`"
                  >
                    <td :style="get_style_project(item.project)">
                      {{ item.project }}
                    </td>
                    <td class="text-center">
                      {{ item.type }}
                    </td>
                    <td class="text-center">
                      {{ item.plan }}
                    </td>
                    <td class="text-center">
                      <div>
                        <span>
                          <a
                            :href="
                              get_link_to_ltt(
                                item.id,
                                item.subproject_id,
                                item.type_id
                              )
                            "
                            target="_blank"
                          >
                            {{ item.fact.all.all }}
                          </a>
                          <sup v-if="item.fact.all.nofollow > 0">
                            {{ item.fact.all.nofollow }}
                          </sup>
                        </span>
                        <span v-if="item.fact.on_page.all > 0">
                          ({{ item.fact.on_page.all }}
                          <sup v-if="item.fact.on_page.nofollow > 0"
                            >{{ item.fact.on_page.nofollow }}
                          </sup>
                          <span v-if="item.fact.gi.all > 0">
                            /{{ item.fact.gi.all }}
                            <sup v-if="item.fact.gi.nofollow > 0">
                              {{ item.fact.gi.nofollow }}
                            </sup>
                          </span>
                          )
                        </span>
                      </div>
                    </td>
                    <td class="text-center">
                      {{ item.done }}% ({{ item.done_on_page }}%)
                    </td>
                    <!--  <td class="text-center">
                      {{ item.lag }}% ({{ item.lag_on_page }}%)
                    </td>-->
                    <td class="text-center">
                      <span v-if="item.department.toLowerCase().includes('la')">
                        <span
                          v-if="
                            item.type_id !== 6 &&
                            item.type_id !== 13 &&
                            item.left.performer > 0
                          "
                        >
                          <span v-if="item.task_id > 0">
                            <a
                              target="_blank"
                              :href="
                                '/anchors-plan/placement-links/' + item.task_id
                              "
                              >{{ item.left.plan }}</a
                            >
                          </span>
                          <span v-else>
                            {{ item.left.plan }}
                          </span>
                          - {{ item.left.new_donors }}
                          <sup>
                            {{ item.left.all_donors }}
                          </sup>
                          <span v-if="item.left.plan > item.left.new_donors">
                            <v-icon style="color: #f10707" size="20">
                              mdi-alert-circle
                            </v-icon>
                          </span>
                        </span>
                      </span>
                      <span v-else>
                        <v-icon small color="red">mdi-crop</v-icon>
                      </span>
                    </td>
                    <td
                      v-for="date in item.dates"
                      :key="`date-${date.id}`"
                      class="text-center"
                    >
                      <div>
                        <span v-if="date.all.all > 0">
                          <a
                            :href="
                              get_link_to_ltt(
                                item.id,
                                item.subproject_id,
                                item.type_id,
                                date.date
                              )
                            "
                            target="_blank"
                          >
                            {{ date.all.all }}
                          </a>
                          <sup v-if="date.all.nofollow > 0"
                            >{{ date.all.nofollow }}
                          </sup>
                        </span>
                        <span v-if="date.on_page.all > 0">
                          ({{ date.on_page.all }}
                          <sup v-if="date.on_page.nofollow > 0">
                            {{ date.on_page.nofollow }}
                          </sup>
                          <span v-if="date.gi.all > 0">
                            /{{ date.gi.all }}
                            <sup v-if="date.gi.nofollow > 0">
                              {{ date.gi.nofollow }}
                            </sup></span
                          >)
                        </span>
                        <div
                          v-if="date.check > 0"
                          style="color: red; font-weight: 600"
                        >
                          {{ date.check }}
                        </div>
                        <span v-if="date.action > 0">
                          --> {{ date.action }}</span
                        >
                      </div>
                    </td>
                    <td class="text-center">
                      {{ item.niche }}
                    </td>
                    <td class="text-center">
                      {{ item.seo }}
                    </td>
                    <td class="text-center">
                      {{ item.priority }}
                    </td>
                    <td class="text-center">
                      <div v-for="(i, j) in item.created_at" :key="`date-${j}`">
                        <div :title="i.department">{{ i.date }}</div>
                      </div>
                    </td>
                    <td class="text-center">
                      {{ item.department }}
                    </td>
                    <td class="text-center">
                      <template v-if="item.link_task === 'none'"> </template>
                      <template
                        v-else-if="item.link_task.split(',').length > 1"
                      >
                        <a
                          v-for="(link, index) in item.link_task.split(',')"
                          :key="`link-${index}`"
                          :href="link"
                          target="_blank"
                          style="margin-right: 10px"
                        >
                          LINK {{ index + 1 }}
                        </a>
                      </template>
                      <template v-else>
                        <a :href="item.link_task" target="_blank"> LINK </a>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import JsonCSV from "vue-json-csv";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import Project from "@/mixins/Project";
import service from "@/plugins/service";
import SmartDateFilter from "@/components/UI/SmartDateFilter";
import Location from "../../../mixins/Location";
import Service from "@/plugins/service";
import PageHeader from "@/components/UI/PageHeader.vue";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";

let dateFormat = require("dateformat");
let date = new Date();

export default {
  name: "LinkDynamicsReportIndex",
  metaInfo: {
    title: "Link Dynamics Report - LTT - SI",
  },
  components: { PageHeader, SmartDateFilter, "download-csv": JsonCSV },
  mixins: [FiltersHandling, Project, Location, UploadFiltersDataMixin],
  data() {
    return {
      firstEnter: true,
      linkTypeItems: [],
      seoItems: [],
      date_modal: false,
      search: "",
      modal: {
        shown: false,
      },
      filtersData: {
        team: [],
        project_categories: [],
      },
      loadings: {
        fetch_data: false,
        seo: false,
        filters: false,
      },
      filter: {
        project_categories: [],
        link_type: [],
        date_range: [
          dateFormat(
            new Date(date.getFullYear(), date.getMonth(), 1),
            "yyyy-mm-dd"
          ),
          dateFormat(
            new Date(date.getFullYear(), date.getMonth() + 1, 0),
            "yyyy-mm-dd"
          ),
        ],
        team: [],
        niche: [],
        last_month: false,
        department: [],
        action: false,
        target_urls: false,
        interval: "day",
        seo: [],
        project: [],
        subprojects: [],
      },
      departments: [
        { id: "la", name: "la" },
        { id: "ha", name: "ha" },
        { id: "fl", name: "fl" },
      ],
      intervals: [
        { id: "day", name: "day" },
        { id: "week", name: "week" },
      ],
      table: {
        headers: [],
        items: [],
      },
      export_data_status: false,
      export_data: {
        items: [],
        dataFile: "serp_export.csv",
        labels: {},
        fields: [],
      },
    };
  },
  async mounted() {
    await this.parseQuery("filter");

    this.fetch_data();
    this.fetchUsers();
    this.getItems();
    this.fetchFilters();

    this.uploadFiltersData(["project_categories", "projects", "subprojects"], {
      projects: this.filter.project,
    });

    this.$watch("filter.project", () => {
      this.filter.subprojects = [];

      this.uploadFiltersData(["subprojects"], {
        projects: this.filter.project,
      });
    });

    if (this.niches.length === 0) {
      this.$store.dispatch("fetch_niches_list");
    }
  },
  computed: {
    niches() {
      return this.$store.state.reuse.niches;
    },
    computedProjectAndSubprojectRules() {
      let rules = [];

      if (!this.filter.project?.length)
        rules.push("Select project or subproject.");
      if (!this.filter.subprojects?.length)
        rules.push("Select project or subproject.");

      if (!!this.filter.project?.length || !!this.filter.subprojects?.length)
        rules = [];

      return rules;
    },
  },
  methods: {
    async fetchFilters() {
      const url = "/static/get-link-types";

      const resp = await service.get(url, {});

      if (!resp) return;

      this.linkTypeItems = resp.data.items;
    },
    async getItems() {
      try {
        const payload = {
          type: "/ltt/link-dynamics-report",
          take: ["team"],
        };

        this.loadings.filters = true;

        const resp = await this.$store.dispatch("global/getFilters", payload);

        if (resp) this.filtersData = { ...this.filtersData, ...resp };
      } catch (e) {
        console.error(
          "Error while getting filter items for Link Dynamic Report.",
          e
        );
      } finally {
        this.loadings.filters = false;
      }
    },
    async fetchUsers() {
      this.loadings.seo = true;
      const projectId = this.$store.state.project.active_project;
      const resp = await this.$store.dispatch("global/getUsers", { projectId });

      if (resp) {
        this.seoItems = resp;
      }

      this.loadings.seo = false;
    },
    exported_csv() {
      this.export_data_status = false;
    },
    export_csv() {
      let self = this;

      self.export_data.items = self.table.items.map((v) => {
        let res = {
          name: v.project,
          type: v.type,
        };

        self.export_data.labels["name"] = "name";
        self.export_data.labels["type"] = "type";
        let dates = Object.keys(v.dates).reverse();
        for (let date in dates) {
          let newDate = new Date(dates[date]);
          let d = newDate.getDate() + "-" + (newDate.getMonth() + 1);
          //     console.log(d, dates[date], newDate.getMonth())
          self.export_data.labels[dates[date]] = d;
          res[dates[date]] = v.dates[dates[date]].all.all;
        }

        return res;
      });

      self.export_data.fields = Object.keys(self.export_data.items[0]);
      self.export_data_status = true;
    },
    async fetch_data() {
      if (!this.$refs.formRef.validate()) return (this.modal.shown = true);

      this.table.headers = [];
      this.table.items = [];
      this.modal.shown = false;

      try {
        this.loadings.fetch_data = true;

        const url = `/ltt/link-dynamics-report/0`;

        const payload = { filter: this.filter };

        const response = await Service.post(url, payload);

        this.firstEnter = false;

        this.table.items = response.data.data;

        this.table.headers = response.data.headers;
      } catch (e) {
        console.error("Error while loading table data.", e);
      } finally {
        this.loadings.fetch_data = false;
      }
    },
    hash_code(str) {
      let hash = 0,
        i,
        chr;
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    to_color(num) {
      num >>>= 0;
      var b = num & 0xff,
        g = (num & 0xff00) >>> 8,
        r = (num & 0xff0000) >>> 16,
        a = ((num & 0xff000000) >>> 24) / 10;
      return "rgba(" + [r, g, b, a].join(",") + ")";
    },
    get_style_project(name) {
      let val = this.hash_code(name);
      return (
        "border-left: 3px solid " + this.to_color(val) + "; padding: 7px; "
      );
    },
    get_style_row(val) {
      let color = "";
      if (val > 50) {
        color = "rgba(255,112,112,0.14)";
      } else if (val > 20) {
        color = "rgba(255,244,131,0.14)";
      }
      return "background-color:" + color;
    },
    get_link_to_ltt(project, subproject, type = false, date = false) {
      let url = "/ltt/index?project=" + project + "&subproject=" + subproject;

      if (date) {
        url += "&date_range=" + date + "," + date;
      } else {
        url += "&date_range=" + this.filter.date_range;
      }

      if (type) {
        url += "&type=" + type;
      }
      return url;
    },
  },
};
</script>

<style scoped></style>
