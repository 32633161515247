<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="12">
        <v-card flat outlined rounded="lg" :loading="loading.data">
          <v-card-title v-if="!loading.data">
            #{{ data.id_task }} / {{ data.phrase }}
          </v-card-title>
          <v-card-title class="pt-0">
            <v-text-field
              v-model="search"
              hide-details
              dense
              outlined
              placeholder="Type to search"
              append-icon="mdi-magnify"
            />
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0">
            <v-data-table
              :search="search"
              :headers="headers"
              :items="items"
              :items-per-page="15"
              :options.sync="options"
              :server-items-length="total_items"
            >
              <template v-slot:item.url="{ item }">
                {{ item.url }}<br />
                <span v-html="item.title"></span>
              </template>
              <template v-slot:item.sw_top_c="{ item }">
                <v-tooltip v-if="item.sw_top_c.iso !== undefined" bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on"
                      >{{ item.sw_top_c.iso }} /
                      {{ item.sw_top_c.traffic_share }}%</span
                    >
                  </template>
                  <span>{{ item.sw_top_c.updated_at.split("T")[0] }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.dr="{ item }">
                <v-tooltip v-if="item.params.ah_dr !== undefined" bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ item.params.ah_dr }}</span>
                  </template>
                  <span>{{ item.params.ah_updated_at }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.rd="{ item }">
                <v-tooltip
                  v-if="item.params.ah_refdomains !== undefined"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ item.params.ah_refdomains }}</span>
                  </template>
                  <span>{{ item.params.ah_updated_at }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.whois="{ item }">
                <v-tooltip
                  v-if="item.params.whois_creation !== undefined"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ item.params.whois_creation }}</span>
                  </template>
                  <span>{{ item.params.whois_updated_at }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.wa="{ item }">
                <v-tooltip v-if="item.params.first !== undefined" bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ item.params.first }}</span>
                  </template>
                  <span>{{ item.params.wa_updated_at }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.gi="{ item }">
                <v-tooltip v-if="item.params.google_index !== undefined" bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ item.params.google_index }}</span>
                  </template>
                  <span>{{ item.params.gi_updated_at }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.MP="{ item }">
                <v-btn
                  text
                  @click="viewLinks(item.id_url, 'MP')"
                  v-if="item.links.tMP !== undefined"
                  >{{ item.links.tMP }}</v-btn
                >
                <span v-else-if="item.has_in_sl">–</span>
              </template>
              <template v-slot:item.LHOME="{ item }">
                <v-btn
                  text
                  @click="viewLinks(item.id_url, 'LHOME')"
                  v-if="item.links.tLHOME !== undefined"
                  >{{ item.links.tLHOME }}</v-btn
                >
                <span v-else-if="item.has_in_sl">–</span>
              </template>
              <template v-slot:item.GP="{ item }">
                <v-btn
                  text
                  @click="viewLinks(item.id_url, 'GP')"
                  v-if="item.links.tGP !== undefined"
                  >{{ item.links.tGP }}</v-btn
                >
                <span v-else-if="item.has_in_sl">–</span>
              </template>
              <template v-slot:item.X="{ item }">
                <v-btn
                  text
                  @click="viewLinks(item.id_url, 'X')"
                  v-if="item.links.tX !== undefined"
                  >{{ item.links.tX }}</v-btn
                >
                <span v-else-if="item.has_in_sl">–</span>
              </template>
              <template v-slot:item.CRWD="{ item }">
                <v-btn
                  text
                  @click="viewLinks(item.id_url, 'CRWD')"
                  v-if="item.links.tCRWD !== undefined"
                  >{{ item.links.tCRWD }}</v-btn
                >
                <span v-else-if="item.has_in_sl">–</span>
              </template>
              <template v-slot:item.PRF="{ item }">
                <v-btn
                  text
                  @click="viewLinks(item.id_url, 'PRF')"
                  v-if="item.links.tPRF !== undefined"
                  >{{ item.links.tPRF }}</v-btn
                >
                <span v-else-if="item.has_in_sl">–</span>
              </template>
              <template v-slot:item.OTHR="{ item }">
                <v-btn
                  text
                  @click="viewLinks(item.id_url, 'OTHR')"
                  v-if="item.links.tOTHR !== undefined"
                  >{{ item.links.tOTHR }}</v-btn
                >
                <span v-else-if="item.has_in_sl">–</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-dialog v-model="links_dialog">
          <v-card>
            <v-card-text>
              <v-data-table
                :headers="links.headers"
                :items="links.items"
                :items-per-page="15"
                class="elevation-1"
              ></v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "SemanticToolKeywordsDetailed",
  data() {
    return {
      search: null,
      headers: [],
      items: [],
      loading: {
        data: false,
        links: false,
      },
      options: {},
      data: {
        phrase: "",
        id_task: "",
      },
      links: {
        headers: [],
        items: [],
      },
      links_dialog: false,
      total_items: 0,
    };
  },
  methods: {
    viewLinks(urlID, typeLink) {
      let self = this;

      self.loading.links = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
        params: {
          taskID: this.data.id_task,
          keywordID: this.$route.params.id,
          urlID: urlID,
          typeLink: typeLink,
        },
      };

      axios
        .get(
          `${self.$store.state.server_url}/semantic-tool/keyword-detailed/links`,
          config
        )
        .then((r) => {
          self.links = r.data.data;
          self.links_dialog = true;
        })
        .finally(() => (self.loading.links = false));
    },
    fetchData() {
      let self = this;

      self.loading.data = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
        params: {
          keywordID: this.$route.params.id,
          options: this.options,
          search: this.search,
        },
      };

      axios
        .get(
          `${self.$store.state.server_url}/semantic-tool/keyword-detailed/all`,
          config
        )
        .then((r) => {
          self.headers = r.data.data.headers;
          self.items = r.data.data.items;
          self.total_items = r.data.data.total_items;
          self.data = r.data.data.data;
        })
        .finally(() => (self.loading.data = false));
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped></style>
