export const TABLE_HEADERS = [
  {
    text: "id",
    value: "id",
  },
  {
    text: "domain",
    value: "domain_name",
  },
  {
    text: "pattern",
    value: "pattern",
  },
  {
    text: "language",
    value: "language_name",
  },
  {
    text: "country",
    value: "country_name",
  },
  {
    text: "created",
    value: "created_at",
  },
  {
    text: "is active",
    value: "is_active",
    width: "60px",
  },
  {
    text: "",
    value: "_actions",
  },
];
