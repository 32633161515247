<template>
  <v-tooltip
    bottom
    :content-class="`smart-date-tooltip ${
      value && Array.isArray(value) && value[0] && value[1] ? 'exist' : ''
    }`"
  >
    <template #activator="{ on }">
      <div v-on="on" style="width: 100%">
        <v-menu
          v-model="menu"
          bottom
          content-class="my-shadow--e2 rounded-lg smart-date-tooltip__menu"
          transition="slide-y-transition"
          offset-y
          max-width="580"
          min-width="580"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <div v-on="on">
              <v-text-field
                :value="value?.every((v) => !v) ? '' : value"
                :label="label"
                placeholder="Select a date"
                dense
                readonly
                hide-details="auto"
                :rules="rules"
                :filled="filled"
                :outlined="!filled"
                clearable
                append-icon="mdi-calendar"
                :disabled="disabled"
                style="width: 100%"
                :loading="loading"
                @blur="$emit('blur')"
                @click:clear="value = [null, null]"
              />
            </div>
          </template>
          <v-card flat outlined rounded="lg">
            <v-card-text class="pb-1 pt-0 pl-0 pr-0">
              <v-row dense>
                <v-col cols="4">
                  <v-list
                    nav
                    dense
                    class="dense-scrollbar"
                    style="max-height: 448px; overflow-y: auto; height: 100%"
                  >
                    <v-list-item
                      v-for="(date, i) in [
                        ...(customPresets || []),
                        ...presets,
                      ]"
                      :key="date.id || i"
                      @click="setDate(date)"
                      :class="{
                        'v-list-item--active':
                          JSON.stringify(date.range) === JSON.stringify(value),
                        'primary--text':
                          JSON.stringify(date.range) ===
                            JSON.stringify(value) && !$vuetify.theme.dark,
                      }"
                    >
                      <span v-html="date.name"></span>
                      <v-spacer />
                      <v-icon
                        v-if="
                          JSON.stringify(date.range) === JSON.stringify(value)
                        "
                        small
                        >mdi-check</v-icon
                      >
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-divider vertical />
                <v-col cols="8">
                  <v-row dense class="pa-2">
                    <v-col cols="12">
                      <v-card class="styled-card--light pa-0">
                        <v-date-picker
                          full-width
                          no-title
                          reactive
                          scrollable
                          :first-day-of-week="1"
                          v-model="value"
                          range
                        >
                        </v-date-picker>
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <v-row dense class="d-flex">
                        <v-col>
                          <template
                            v-if="
                              value &&
                              Array.isArray(value) &&
                              value[0] !== undefined
                            "
                          >
                            <v-text-field
                              v-model="value[0]"
                              hide-details
                              clearable
                              dense
                              outlined
                              class="input-rounded-1"
                              placeholder="YYYY-MM-DD"
                              @change="handleUpdateValue"
                            >
                              <template #prepend-inner>
                                <v-icon style="margin-top: 4px" small
                                  >mdi-calendar-start-outline</v-icon
                                >
                              </template>
                            </v-text-field>
                            <div v-if="value[0]">
                              {{ $moment(value[0]).format("MMMM Do, YYYY") }}
                            </div>
                          </template>
                          <template v-else>
                            <div
                              class="skeleton skeleton--placeholder"
                              style="width: 163px; height: 40px"
                            />
                            <div
                              class="skeleton skeleton--placeholder"
                              style="
                                width: 163px;
                                height: 14px;
                                margin-top: 2px;
                              "
                            />
                          </template>
                        </v-col>
                        <v-col
                          class="flex-shrink-1 d-flex justify-center align-start"
                          style="max-width: 32px"
                        >
                          <v-icon
                            v-if="
                              value &&
                              Array.isArray(value) &&
                              value[1] !== undefined
                            "
                            style="margin-top: 8px"
                            >mdi-arrow-right</v-icon
                          >
                          <template v-else>
                            <div
                              class="skeleton skeleton--placeholder"
                              style="width: 24px; height: 24px; margin-top: 8px"
                            ></div>
                          </template>
                        </v-col>
                        <v-col>
                          <template
                            v-if="
                              value &&
                              Array.isArray(value) &&
                              value[1] !== undefined
                            "
                          >
                            <v-text-field
                              v-model="value[1]"
                              hide-details
                              dense
                              outlined
                              class="input-rounded-1"
                              clearable
                              placeholder="YYYY-MM-DD"
                              @change="handleUpdateValue"
                            >
                              <template #prepend-inner>
                                <v-icon style="margin-top: 4px" small
                                  >mdi-calendar-end-outline</v-icon
                                >
                              </template>
                            </v-text-field>
                            <div v-if="value[1]">
                              {{ $moment(value[1]).format("MMMM Do, YYYY") }}
                            </div>
                          </template>
                          <template v-else>
                            <div
                              class="skeleton skeleton--placeholder"
                              style="width: 163px; height: 40px"
                            />
                            <div
                              class="skeleton skeleton--placeholder"
                              style="
                                width: 163px;
                                height: 14px;
                                margin-top: 2px;
                              "
                            />
                          </template>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions v-if="false" class="d-flex justify-end">
              <v-chip label outlined class="mr-2" @click="value = [null, null]">
                Clear selected
                <v-icon small right>mdi-trash-can-outline</v-icon>
              </v-chip>
              <v-chip
                style="width: 100px"
                class="d-flex justify-center"
                label
                color="success"
                @click="menu = false"
              >
                Apply
              </v-chip>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </template>
    <div class="smart-date-tooltip__content">
      {{
        Array.isArray(value) && value[0]
          ? $moment(value[0]).calendar(null, calendarFormat)
          : "Select from"
      }}
      →
      {{
        Array.isArray(value) && value[1]
          ? $moment(value[1]).calendar(null, calendarFormat)
          : "Select to"
      }}
    </div>
  </v-tooltip>
</template>

<script>
import { DEFAULT_MOMENT_DATE_FORMAT } from "@/utils/defaultData";
import { deepClone, uid } from "@/utils/functions";

export default {
  props: {
    customPresets: {
      type: Array,
      default() {
        return [];
      },
    },
    errorMessages: Array,
    disabled: Boolean,
    loading: Boolean,
    disableDefaultPresets: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array,
      default() {
        return [null, null];
      },
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default: "Date",
    },
    filled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menu: false,
    calendarMenuModel: false,
    presets: [],
    calendarFormat: {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      lastDay: "[Yesterday]",
      lastWeek: "[Last] dddd",
      sameElse: "YYYY MMM DD",
    },
  }),
  mounted() {
    if (!this.disableDefaultPresets) {
      this.presets = [
        {
          name: "Month",
          id: uid(),
          range: [
            this.$moment().startOf("month").format(DEFAULT_MOMENT_DATE_FORMAT),
            this.$moment().endOf("month").format(DEFAULT_MOMENT_DATE_FORMAT),
          ],
        },
        {
          name: "Quarter",
          id: uid(),
          range: [
            this.$moment().startOf("Q").format(DEFAULT_MOMENT_DATE_FORMAT),
            this.$moment().endOf("Q").format(DEFAULT_MOMENT_DATE_FORMAT),
          ],
        },
        {
          name: "Year",
          id: uid(),
          range: [
            this.$moment().startOf("year").format(DEFAULT_MOMENT_DATE_FORMAT),
            this.$moment().endOf("year").format(DEFAULT_MOMENT_DATE_FORMAT),
          ],
        },
        {
          name: "Prev. month",
          id: uid(),
          range: [
            this.$moment()
              .subtract(1, "months")
              .startOf("month")
              .format(DEFAULT_MOMENT_DATE_FORMAT),
            this.$moment()
              .subtract(1, "months")
              .endOf("month")
              .format(DEFAULT_MOMENT_DATE_FORMAT),
          ],
        },
        {
          name: "Prev. quarter",
          id: uid(),
          range: [
            this.$moment()
              .subtract(1, "Q")
              .startOf("Q")
              .format(DEFAULT_MOMENT_DATE_FORMAT),
            this.$moment()
              .subtract(1, "Q")
              .endOf("Q")
              .format(DEFAULT_MOMENT_DATE_FORMAT),
          ],
        },
        {
          name: "Prev. year",
          id: uid(),
          range: [
            this.$moment()
              .subtract(1, "years")
              .startOf("year")
              .format(DEFAULT_MOMENT_DATE_FORMAT),
            this.$moment()
              .subtract(1, "years")
              .endOf("year")
              .format(DEFAULT_MOMENT_DATE_FORMAT),
          ],
        },
      ];
    }

    if (!this.value || this.value.length === 0) this.value = [null, null];
  },
  watch: {
    // fromDateModel(string) {
    //   if (!this.value) this.value = [];
    //
    //   this.value = [string, this.value[1] ? this.value[1] : ""];
    // },
    // toDateModel(string) {
    //   if (!this.value) this.value = [];
    //
    //   this.value = [this.value[0] ? this.value[0] : "", string];
    // },
  },
  computed: {
    getFormattedValue() {
      const val = this.value;

      if (!val) return "";

      if (Array.isArray(val) && val.length === 2 && !val[0] && !val[1])
        return "";

      const from = val[0] ? val[0] : "Select from";
      const to = val[1] ? val[1] : "Select to";

      return from + " → " + to;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    handleUpdateValue() {
      this.$emit("update:modelValue", this.value);
    },
    setCustomDate(options) {
      const { action, from, type, amount } = options;

      let date;
      if (from === "today") {
        date = new Date();
      } else {
        date = new Date(from);
      }

      const mDate = this.$moment(date);
      const start = mDate[action](amount, type)
        .startOf("day")
        .format("YYYY-MM-DD");
      const end = this.$moment(date).format("YYYY-MM-DD");

      return (this.value = [start, end]);
    },
    setDate(date) {
      return (this.value = deepClone(date.range));
    },
  },
};
</script>

<style lang="scss">
.theme--dark {
  .smart-date-tooltip {
    &__menu {
      .v-btn.v-date-picker-table__current {
        color: currentColor !important;
      }
    }
  }
}
.smart-date-tooltip {
  display: none;
  border-radius: 8px !important;
  &.exist {
    display: block;
  }
  &__content {
    font-size: 12px !important;
  }
}
.smart-date-preset.preset-item {
  small {
    opacity: 0.4 !important;
    font-size: 16px !important;
  }
}
</style>
