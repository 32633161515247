<template>
  <div>
    <div class="mb-4">
      <v-text-field
        v-model="search"
        hide-details
        dense
        outlined
        placeholder="Type to search"
        append-icon="mdi-magnify"
      />
    </div>
    <v-card flat outlined rounded="lg" :loading="loading">
      <v-data-table
        :search="search"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
      >
        <template v-slot:item.id="{ item }">
          <v-btn @click="openDialogSettings(item.id)" icon>
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.tags="{ item }">
          <v-chip
            class="ma-2"
            :color="getTagColor(i)"
            small
            v-for="i in item.tags"
            :key="i"
          >
            {{ i }}
          </v-chip>
        </template>
        <template v-slot:item.c_projects="{ item }">
          {{ item.c_projects }} / {{ allowedProject.length }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.c_projects === 0"
            dark
            color="red darken-1"
            depressed
            :loading="item.loading"
            class="text-normal"
            @click="subscribe(item.id)"
          >
            Subscribe
          </v-btn>
          <v-btn
            v-else
            depressed
            :loading="item.loading"
            class="text-normal"
            @click="unsubscribe(item.id)"
          >
            Subscribed
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog_settings" persistent scrollable max-width="600px">
      <v-card flat outlined rounded="lg">
        <v-card-title class="text-body-2"> Report settings </v-card-title>
        <v-divider />
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="12">
                <smart-autocomplete
                  :model-value="report.projects"
                  :items="allowedProject"
                  label="Select projects"
                  item-text="name"
                  item-value="id"
                  :handling="false"
                  @update:modelValue="report.projects = $event"
                />
                <!--                  <SelectMultiple-->
                <!--                  :model="reportProjects"-->
                <!--                  :items="allowedProject"-->
                <!--                  itemText="name"-->
                <!--                  itemValue="id"-->
                <!--                  label="Selected projects"-->
                <!--                  @selectChange="-->
                <!--                    (data) => {-->
                <!--                      report.projects = data;-->
                <!--                    }-->
                <!--                  "-->
                <!--                />-->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <smart-autocomplete
                  :model-value="report.days"
                  :items="days_items"
                  label="Days"
                  :handling="false"
                  @update:modelValue="report.days = $event"
                />
                <!--                <SelectMultiple-->
                <!--                  :model="reportDays"-->
                <!--                  :items="days_items"-->
                <!--                  itemText="text"-->
                <!--                  itemValue="value"-->
                <!--                  label="Days"-->
                <!--                  @selectChange="-->
                <!--                    (data) => {-->
                <!--                      report.days = data;-->
                <!--                    }-->
                <!--                  "-->
                <!--                />-->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="px-6 text-normal"
            large
            text
            @click="dialog_settings = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="px-6 text-normal"
            large
            @click="updateData"
            :loading="dialog_settings_save"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import SmartAutocomplete from "../UI/SmartAutocomplete.vue";

export default {
  name: "Index",
  components: { SmartAutocomplete },
  data() {
    return {
      search: null,
      dialog_settings_save: false,
      dialog_settings: false,
      loading: false,
      headers: [
        { text: "", align: "start", sortable: false, value: "id", width: 30 },
        { text: "Report Name", align: "start", sortable: false, value: "name" },
        { text: "Tags", align: "center", sortable: false, value: "tags" },
        {
          text: "Projects",
          align: "center",
          sortable: false,
          value: "c_projects",
        },
        { text: "Actions", align: "center", value: "actions" },
      ],
      items: [],
      allowed_project: [],
      days_items: [
        { text: "mon", value: "mon" },
        { text: "tue", value: "tue" },
        { text: "wed", value: "wed" },
        { text: "thu", value: "thu" },
        { text: "fri", value: "fri" },
        { text: "sat", value: "sat" },
        { text: "sun", value: "sun" },
      ],
      report: {
        loading: false,
        projects: [],
        days: [],
      },
      active_type: null,
    };
  },
  computed: {
    reportProjects() {
      return this.report.projects;
    },
    reportDays() {
      return this.report.days;
    },
    allowedProject() {
      return this.$store.state.project.allowed_projects;
    },
  },
  methods: {
    getTagColor(tag) {
      let colors = {
        ltt: "info",
      };

      return colors[tag] === undefined ? "secondary" : colors[tag];
    },
    fetchData() {
      let self = this;

      self.loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .get(`${self.$store.state.server_url}/mailer/reports`, config)
        .then((r) => {
          self.items = r.data.items;
        })
        .finally(() => (self.loading = false));
    },
    openDialogSettings(id) {
      let self = this;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      self.dialog_settings = true;
      self.active_type = id;

      axios
        .get(`${self.$store.state.server_url}/mailer/reports/${id}`, config)
        .then((r) => {
          self.report = r.data;
        });
    },
    updateData() {
      let self = this;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      self.dialog_settings_save = true;

      let data = self.report;

      axios
        .put(
          `${self.$store.state.server_url}/mailer/reports/${this.active_type}`,
          data,
          config
        )
        .finally(() => {
          self.dialog_settings_save = false;
          self.fetchData();
        });
    },
    subscribe(id) {
      let self = this;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = self.report;

      axios
        .post(
          `${self.$store.state.server_url}/mailer/reports/${id}/subscribe`,
          data,
          config
        )
        .finally(() => self.fetchData());
    },
    unsubscribe(id) {
      let self = this;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = self.report;

      axios
        .post(
          `${self.$store.state.server_url}/mailer/reports/${id}/unsubscribe`,
          data,
          config
        )
        .finally(() => self.fetchData());
    },
  },
  created() {
    let self = this;

    self.fetchData();
  },
};
</script>

<style scoped></style>
