import service from "../../../plugins/service";

export const Tribe4DxApiService = () => {
  const ENDPOINTS = {
    GET_TRIBE_CONTENT: "/fdx/tribe/index",
    GET_TRIBE_GROUPS: "/fdx/tribe/groups",
  };

  const TRIBE_TYPES = {
    CONVERSION: "conversion-table",
    LINKS: "links-table",
    CONTENT: "content-table",
    POSITIONS: "positions-table",
    NEED_TOP: "need-top-table",
    PAGES_INFO: "pages-info-table",
    SW: "sw",
    P200: "p200",
  };

  const getTribeContent = (params) => {
    return service.get(
      ENDPOINTS.GET_TRIBE_CONTENT,
      true,
      { params },
      { disableError: true }
    );
  };

  const getTribeGroups = (params) => {
    return service.get(
      ENDPOINTS.GET_TRIBE_GROUPS,
      true,
      {
        params,
      },
      { disableError: true }
    );
  };

  return { getTribeContent, getTribeGroups, TRIBE_TYPES };
};
