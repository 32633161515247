<template>
  <v-data-table
    :items="items || []"
    :headers="headers || []"
    :loading="loading"
    multi-sort
    dense
    :items-per-page="50"
    :footer-props="{ itemsPerPageOptions: [50, 150, 300, -1] }"
  >
    <template #[`item._actions`]="{ item }">
      <v-btn
        text
        @click="$emit('edit', item)"
        class="px-2"
        style="min-width: 36px"
      >
        <v-icon small>mdi-pen</v-icon>
      </v-btn>
    </template>
    <template #[`item.created_at`]="{ value }">
      {{ value ? $moment(value).format("ll") : "" }}
    </template>
    <template #[`item.is_active`]="{ item }">
      <v-switch
        v-model="item.is_active"
        inset
        dense
        hide-details
        class="ma-1"
        @change="($event) => handleChangeActive($event, item)"
        :loading="item._pending"
      />
    </template>
  </v-data-table>
</template>

<script>
import { DomainSubfolderApiService } from "../../../services/DomainSubfolderApiService";

export default {
  props: ["items", "headers", "loading"],

  methods: {
    async handleChangeActive(isActive, tableItem) {
      try {
        this.$set(tableItem, "_pending", true);
        await DomainSubfolderApiService().update({
          is_active: isActive,
          id: tableItem.id,
        });
      } catch (e) {
        console.error("Error while changing activity state.", e);
      } finally {
        this.$set(tableItem, "_pending", false);
      }
    },
  },
};
</script>
