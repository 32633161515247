<template>
  <div>
    <v-text-field
      :rules="rules"
      :value="localValue"
      @input="
        handleChange(
          () => {
            errors[field.id] = [];
          },
          () => handleChangeTime($event)
        )
      "
      clearable
      hide-details="auto"
      dense
      outlined
      placeholder="hh:mm"
      @change="$emit('change')"
      :error-messages="errors[field.id]"
    />
    <div v-if="localValue">
      <small> {{ computedHumanTime }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value", "rules", "errors", "field"],

  data: () => ({
    localValue: null,
    minutesAmount: null,
  }),

  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    computedBackendValidTime() {
      const totalMinutes = Number(this.minutesAmount);

      // Проверка на валидное число
      if (isNaN(totalMinutes) || totalMinutes < 0) {
        return "Invalid time";
      }

      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      // Форматирование с ведущими нулями
      const pad = (num) => String(num).padStart(2, "0");

      return `${pad(hours)}:${pad(minutes)}`;
    },
    computedHumanTime() {
      const totalMinutes = Number(this.minutesAmount);

      // Проверка на валидное число
      if (isNaN(totalMinutes) || totalMinutes < 0) {
        return "Invalid time";
      }

      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      // Форматирование с ведущими нулями
      const pad = (num) => String(num).padStart(2, "0");

      return `${pad(hours)} h. ${pad(minutes)} min.`;
    },
  },

  methods: {
    handleChange(cb, changeHandler) {
      cb();
      changeHandler();
    },
    handleChangeTime(event) {
      this.localValue = event;

      // Разделяем на часы и минуты (все после первого двоеточия - минуты)
      const parts = event.split(":");
      const hoursPart = parts[0]?.replace(/\D/g, "") || "0"; // Удаляем нецифровые символы
      const minutesPart = parts.slice(1).join("").replace(/\D/g, "") || "0"; // Объединяем все после первого ":"

      // Парсим числа (ведущие нули игнорируются)
      const parsedHours = parseInt(hoursPart, 10) || 0;
      const parsedMinutes = parseInt(minutesPart, 10) || 0;

      // Сохраняем результат в минутах
      this.minutesAmount = parsedHours * 60 + parsedMinutes;
      this.modelValue = this.computedBackendValidTime;
    },
  },
};
</script>
