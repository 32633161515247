<template>
  <div style="position: relative">
    <div
      v-if="newDesign && _$getActiveProject && _$isActiveProjectAllowed"
      class="project-edit__bg-gradient"
      :style="`background: radial-gradient(50% 50% at 50% 50%, ${
        _$projectGetAvatarColors(
          _$getActiveProject.domain,
          $vuetify.theme.dark ? 0.2 : 0.09
        ).rgba
      } 0%, rgba(217, 217, 217, 0) 100%)`"
    ></div>
    <v-row align="center">
      <v-col class="grow">
        <div class="d-inline-block px-2">
          <v-menu
            v-if="newDesign"
            bottom
            offset-y
            :close-on-content-click="false"
            content-class="my-shadow--e3"
            transition="slide-y-transition"
          >
            <template #activator="{ on }">
              <div v-on="on">
                <template v-if="_$getActiveProject && _$isActiveProjectAllowed">
                  <v-chip
                    label
                    style="width: auto; height: auto"
                    color="transparent"
                    class="d-flex justify-center align-center pa-2"
                    @click="() => {}"
                  >
                    <v-list-item-icon
                      class="mr-2 my-0"
                      style="height: auto !important"
                    >
                      <v-avatar
                        rounded
                        :style="{
                          background: _$projectGetAvatarColors(
                            _$getActiveProject.domain
                          ).bg,
                        }"
                        size="24"
                      >
                        <span
                          :style="{
                            color: _$projectGetAvatarColors(
                              _$getActiveProject.domain
                            ).text,
                            'font-size': '12px',
                          }"
                        >
                          {{ getDomainInitials(_$getActiveProject.domain) }}
                        </span>
                      </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content class="py-0">
                      <div class="d-flex align-center">
                        <div>
                          <div>
                            {{ _$getActiveProject.domain }}
                          </div>
                        </div>
                        <div class="ml-2">
                          <v-icon small>mdi-chevron-down</v-icon>
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-chip>
                </template>
                <template
                  v-else-if="
                    selected_project === null || selected_project === undefined
                  "
                >
                  <v-btn class="text-normal" @click="() => {}">
                    <v-icon small left>mdi-file-document-outline</v-icon>

                    <span style="opacity: 0.5">Select project</span>

                    <v-icon small right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <template v-else-if="!_$isActiveProjectAllowed">
                  <v-chip
                    label
                    style="width: auto; height: auto"
                    color="transparent"
                    class="d-flex justify-center align-center pa-2"
                    @click="() => {}"
                  >
                    <v-list-item-icon
                      class="mr-2 my-0"
                      style="height: auto !important"
                    >
                      <v-icon color="error">mdi-alert-box</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="py-0">
                      <div class="d-flex align-center">
                        <div>No access to selected project</div>
                        <div class="ml-2">
                          <v-icon small>mdi-chevron-down</v-icon>
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-chip>
                </template>
              </div>
            </template>
            <v-card flat outlined rounded="lg">
              <v-card-title
                class="text-body-2"
                style="padding: 8px 8px 8px 16px !important"
              >
                <span>Projects</span>
                <v-spacer />
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-chip
                      v-on="on"
                      label
                      outlined
                      class="error--text px-2"
                      @click="selected_project = null"
                    >
                      <v-icon small>mdi-file-document-remove-outline</v-icon>
                    </v-chip>
                  </template>
                  <div>Deselect project</div>
                </v-tooltip>
              </v-card-title>
              <v-divider />
              <v-list dense nav class="pa-1">
                <v-list-item
                  :disabled="loadings.projects || !selected_project"
                  @click="open_dialog"
                >
                  <v-list-item-icon class="mr-2">
                    <v-icon small>mdi-cog-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content> Project settings </v-list-item-content>
                </v-list-item>
                <v-list-item @click="isAddProjectDialogVisible = true">
                  <v-list-item-icon class="mr-2">
                    <v-icon small>mdi-plus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content> Add new project </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider />
              <v-text-field
                v-model="searchModel"
                dense
                :disabled="loadings.projects"
                hide-details
                filled
                prepend-inner-icon="mdi-magnify"
                clearable
                clear-icon="mdi-close-circle"
                placeholder="Search by domain / id / name"
              >
              </v-text-field>
              <v-divider />
              <v-list
                dense
                nav
                class="pa-1"
                style="max-height: 400px; overflow-y: scroll"
              >
                <template v-if="loadings.projects">
                  <div
                    v-for="i in [0, 1, 2, 3, 4]"
                    :key="i"
                    class="skeleton mb-1"
                    style="height: 48px"
                  />
                </template>
                <template v-else>
                  <v-list-item
                    v-for="item in getFilteredProjects"
                    :key="item.id"
                    @click="selected_project = item.id"
                    :class="{
                      'v-list-item--active':
                        selected_project === item.id && $vuetify.theme.dark,
                      'v-list-item--active primary--text':
                        selected_project === item.id && !$vuetify.theme.dark,
                    }"
                  >
                    <v-list-item-icon
                      class="mr-2"
                      style="height: auto !important"
                    >
                      <v-avatar
                        rounded
                        :style="{
                          background: _$projectGetAvatarColors(item.domain).bg,
                        }"
                        size="24"
                      >
                        <span
                          :style="{
                            color: _$projectGetAvatarColors(item.domain).text,
                            'font-size': '12px',
                          }"
                        >
                          {{ getDomainInitials(item.domain, item) }}
                        </span>
                      </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <div>
                        {{ item && item.domain }}
                      </div>
                      <div style="font-size: 12px; opacity: 0.75">
                        id: {{ item && item.id }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </v-menu>

          <div v-else class="d-flex" style="gap: 0.25rem">
            <v-autocomplete
              dense
              :items="computedAllowedProjects"
              cache-items
              v-model="selected_project"
              hide-no-data
              hide-details
              :style="{ width: _$isActiveProjectAllowed ? '260px' : '320px' }"
              :label="getProjectLabel"
              solo
              :class="`
                ${is_dark_mode ? 'dark-mode' : ''}
                reset-box-shadow
                project-select
                rounded-lg
              `"
              :menu-props="{
                rounded: 'lg',
                transition: 'slide-x-transition',
                contentClass: 'shadow-e2-bordered',
              }"
            >
              <template #append>
                <template v-if="!_$isActiveProjectAllowed">
                  <v-icon color="error">mdi-alert</v-icon>
                </template>
              </template>
              <template #item="{ item }">
                <div
                  class="text-body-2 d-flex align-center py-2"
                  style="width: 100%"
                >
                  <div>
                    {{ item._ctx.domain }}
                  </div>
                  <v-spacer />
                  <div class="d-flex" style="gap: 0.25rem">
                    <v-tooltip bottom open-delay="300">
                      <template #activator="{ on }">
                        <div v-on="on" class="d-flex align-center">
                          <v-badge
                            :color="
                              { 0: 'error', 1: 'success', 2: 'yellow' }[
                                item._ctx.status
                              ]
                            "
                            dot
                            inline
                          />
                        </div>
                      </template>
                      <div>
                        {{
                          {
                            0: "Project is disabled",
                            1: "Project is active",
                            2: "Project is paused",
                          }[item._ctx.status]
                        }}
                      </div>
                    </v-tooltip>
                  </div>
                </div>
              </template>
            </v-autocomplete>
            <v-btn
              v-if="_$isActiveProjectAllowed"
              small
              height="38"
              class="text-normal text-body-2 px-0"
              style="min-width: 38px"
              @click="open_dialog"
            >
              <v-icon small>mdi-cog</v-icon>
            </v-btn>
            <v-btn
              small
              height="38"
              text
              class="text-normal text-body-2 px-2"
              @click="handleAddProject"
            >
              Add <v-icon small class="ml-1">mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="840px"
      content-class="my-shadow--e4 pa-2"
    >
      <v-card
        rounded="lg"
        class="shadow-e2-bordered project-settings relative"
        style="overflow: hidden"
      >
        <Loader v-if="loadings.projectData" />
        <v-card-title class="pa-0">
          <v-tabs v-model="tab" background-color="primary" hide-slider dark>
            <v-tab :key="1"> Main</v-tab>
            <v-tab :key="2"> Access</v-tab>
            <v-tab :key="3"> Analytics</v-tab>
            <v-tab :key="4"> Brand Analysis</v-tab>
            <v-tab :key="5"> Serp</v-tab>
            <v-tab :key="6"> Content Checker</v-tab>
            <v-tab :key="7"> Ltt</v-tab>
            <v-tab :key="8"> SpiderLink</v-tab>
            <v-tab :key="9"> Comments</v-tab>
            <v-tab :key="10"> Unique Check</v-tab>
            <v-tab :key="11"> Links Planning</v-tab>
          </v-tabs>
        </v-card-title>
        <v-card-text>
          <v-tabs-items v-model="tab">
            <v-tab-item :key="1">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" v-if="project_data.redirect_to">
                    <v-card
                      flat
                      class="flat-chip flat-chip--primary"
                      rounded="lg"
                    >
                      <v-card-title class="text-body-2 pb-1">
                        <v-icon small left>mdi-information-outline</v-icon>
                        <b>Project has redirect</b>
                      </v-card-title>
                      <v-card-text>
                        Project redirected to
                        <a
                          :href="project_data.redirect_to"
                          target="_blank"
                          style="text-decoration: underline"
                          >{{ project_data.redirect_to }}</a
                        >
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px">
                        Main settings
                        <v-spacer />
                        <v-icon>mdi-cog-outline</v-icon>
                      </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                              :loading="loading.main_data"
                              label="Name*"
                              filled
                              v-model="project_data.name"
                              required
                              hide-details
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              :loading="loading.main_data"
                              label="Url*"
                              hide-details
                              filled
                              dense
                              v-model="project_data.url"
                              hint="https://www.example.com"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              v-model="project_data.country"
                              :items="countries"
                              item-value="id"
                              item-text="name"
                              label="Country*"
                              hide-details
                              dense
                              :menu-props="menuProps"
                              required
                              filled
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              v-model="project_data.language"
                              :items="languages"
                              item-value="id"
                              item-text="name"
                              label="Language*"
                              hide-details
                              dense
                              :menu-props="menuProps"
                              required
                              filled
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              :loading="filtersDataPendingKeys?.niches"
                              v-model="project_data.niche"
                              :items="filtersData?.niches"
                              label="Niche*"
                              hide-details
                              :menu-props="menuProps"
                              dense
                              required
                              filled
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              v-model="project_data.project_category_id"
                              :items="filtersData.project_categories"
                              :loading="
                                filtersDataPendingKeys?.project_categories
                              "
                              hide-details
                              dense
                              filled
                              label="Project categories"
                              placeholder="Type to search"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              v-model="project_data.status"
                              :items="filtersData.project_status"
                              :loading="loading.main_data"
                              hide-details
                              dense
                              :menu-props="menuProps"
                              filled
                              label="Project Status"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              :loading="loading.main_data"
                              auto-grow
                              clearable
                              name="input-7-1"
                              label="Regional feature*"
                              hide-details
                              dense
                              v-model="project_data.regional_feature"
                              hint="/en"
                              filled
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12">
                            <v-row dense>
                              <v-col class="d-flex justify-space-between">
                                Max links in index at 24 hours
                                <v-text-field
                                  v-model="project_data.max_links_24_hours"
                                  :loading="loading.main_data"
                                  type="number"
                                  hide-details
                                  dense
                                  min="0"
                                  max="20"
                                  label="Amount"
                                  style="max-width: 60px"
                                />
                              </v-col>
                              <v-col>
                                <v-slider
                                  :loading="loading.main_data"
                                  v-model="project_data.max_links_24_hours"
                                  hint="Add to url"
                                  hide-details
                                  dense
                                  min="0"
                                  max="20"
                                  thumb-label="always"
                                ></v-slider>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px">
                        Responsibility
                        <v-spacer />
                        <v-icon>mdi-account-outline</v-icon>
                      </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-autocomplete
                              label="Junior SEO"
                              v-model="responsibility['junior seo']"
                              :items="roles.items"
                              item-text="email"
                              item-value="id"
                              required
                              hide-details
                              dense
                              filled
                              clearable
                              :menu-props="menuProps"
                              chips
                              small-chips
                              deletable-chips
                              multiple
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              label="Chief SEO"
                              v-model="responsibility['chief seo']"
                              :items="roles.items"
                              item-text="email"
                              item-value="id"
                              required
                              :menu-props="menuProps"
                              filled
                              hide-details
                              dense
                              clearable
                              chips
                              small-chips
                              deletable-chips
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              label="SEO Manager"
                              v-model="responsibility['seo manager']"
                              :items="roles.items"
                              item-text="email"
                              item-value="id"
                              required
                              filled
                              hide-details
                              :menu-props="menuProps"
                              dense
                              clearable
                              chips
                              small-chips
                              deletable-chips
                              multiple
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px">
                        Subprojects
                        <v-spacer />
                        <v-chip
                          label
                          class="flat-chip flat-chip--primary"
                          @click="subprojects.push(subprojectTemplate)"
                        >
                          Add subproject <v-icon right small>mdi-plus</v-icon>
                        </v-chip>
                      </v-card-title>
                      <v-divider />
                      <v-card-text
                        class="pa-4"
                        style="background: var(--card-darken-body-color)"
                      >
                        <template v-if="subprojects && subprojects.length > 0">
                          <div class="d-flex flex-column" style="gap: 0.5rem">
                            <v-card
                              v-for="(item, i) in subprojects"
                              flat
                              outlined
                              rounded="lg"
                              :key="i"
                            >
                              <v-card-title class="text-body-2">
                                {{ item.name }}
                                <v-spacer />
                                <div class="d-flex" style="gap: 0.25rem">
                                  <v-hover v-slot="{ hover }">
                                    <v-btn
                                      class="text-normal"
                                      :color="hover ? null : 'transparent'"
                                      :class="{
                                        'flat-chip flat-chip--error': hover,
                                        'px-2': !hover,
                                      }"
                                      :style="{
                                        'min-width': !hover ? '36px' : '',
                                      }"
                                      @click="delete_subproject(i)"
                                    >
                                      {{ hover ? "Delete" : "" }}
                                      <v-icon small :right="hover">
                                        mdi-trash-can-outline
                                      </v-icon>
                                    </v-btn>
                                  </v-hover>
                                </div>
                              </v-card-title>
                              <v-divider />
                              <v-card-text class="pt-5">
                                <v-row dense>
                                  <v-col cols="fill">
                                    <v-row dense>
                                      <v-col v-if="item.redirect_to" cols="12">
                                        <v-card
                                          flat
                                          class="flat-chip flat-chip--primary"
                                          rounded="lg"
                                        >
                                          <v-card-title
                                            class="text-body-2 pb-1"
                                          >
                                            <v-icon small left
                                              >mdi-information-outline</v-icon
                                            >
                                            <b>Subproject has redirect</b>
                                          </v-card-title>
                                          <v-card-text>
                                            Subproject redirected to
                                            <a
                                              :href="item.redirect_to"
                                              target="_blank"
                                              style="text-decoration: underline"
                                              >{{ item.redirect_to }}</a
                                            >
                                          </v-card-text>
                                        </v-card>
                                      </v-col>
                                      <v-col cols="6">
                                        <v-text-field
                                          label="Name"
                                          v-model="item.name"
                                          required
                                          filled
                                          clearable
                                          hide-details
                                          dense
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="3">
                                        <v-text-field
                                          label="Pattern"
                                          v-model="item.url_pattern"
                                          required
                                          filled
                                          clearable
                                          hide-details
                                          dense
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="3">
                                        <v-text-field
                                          label="Priority"
                                          v-model="item.priority"
                                          required
                                          filled
                                          clearable
                                          hide-details
                                          dense
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="3">
                                        <v-autocomplete
                                          label="Country"
                                          v-model="item.country_id"
                                          :items="countries"
                                          item-value="id"
                                          item-text="name"
                                          :menu-props="menuProps"
                                          required
                                          clearable
                                          filled
                                          hide-details
                                          dense
                                        ></v-autocomplete>
                                      </v-col>
                                      <v-col cols="3">
                                        <v-autocomplete
                                          label="Language"
                                          v-model="item.language_id"
                                          :items="languages"
                                          item-value="id"
                                          item-text="name"
                                          :menu-props="menuProps"
                                          required
                                          clearable
                                          filled
                                          hide-details
                                          dense
                                        ></v-autocomplete>
                                      </v-col>
                                      <v-col cols="3">
                                        <v-text-field
                                          label="Worksection"
                                          v-model="item.worksection_id"
                                          required
                                          clearable
                                          filled
                                          hide-details
                                          dense
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="3">
                                        <v-autocomplete
                                          v-model="item.status"
                                          :items="filtersData.project_status"
                                          :loading="loading.main_data"
                                          hide-details
                                          dense
                                          :menu-props="menuProps"
                                          filled
                                          label="Status"
                                        ></v-autocomplete>
                                      </v-col>
                                      <v-col cols="6">
                                        <v-autocomplete
                                          dense
                                          label="Responsible"
                                          v-model="item.responsible_user_id"
                                          :items="roles.items"
                                          item-text="email"
                                          item-value="id"
                                          required
                                          :menu-props="menuProps"
                                          filled
                                          clearable
                                          hide-details
                                          class="reset-vuetify-input"
                                        ></v-autocomplete>
                                      </v-col>
                                      <v-col cols="6">
                                        <v-autocomplete
                                          dense
                                          label="Redirect from subproject"
                                          v-model="item.redirect_subproject_id"
                                          :items="filtersData.subprojects"
                                          required
                                          clearable
                                          :menu-props="menuProps"
                                          filled
                                          hide-details
                                          class="reset-vuetify-input"
                                        ></v-autocomplete>
                                      </v-col>
                                      <v-col cols="6">
                                        <v-autocomplete
                                          dense
                                          label="Publishing responsible"
                                          v-model="
                                            item.publishing_responsible_user_id
                                          "
                                          :items="roles.items"
                                          item-text="email"
                                          item-value="id"
                                          required
                                          clearable
                                          :menu-props="menuProps"
                                          filled
                                          hide-details
                                          class="reset-vuetify-input"
                                        ></v-autocomplete>
                                      </v-col>
                                      <v-col cols="6">
                                        <v-autocomplete
                                          dense
                                          label="Brier creator"
                                          v-model="item.brief_creator_user_id"
                                          :items="roles.items"
                                          item-text="email"
                                          item-value="id"
                                          required
                                          clearable
                                          :menu-props="menuProps"
                                          filled
                                          hide-details
                                        ></v-autocomplete
                                      ></v-col>
                                      <v-col cols="6">
                                        <v-autocomplete
                                          dense
                                          label="Content TL"
                                          v-model="item.content_tl_user_id"
                                          :items="roles.items"
                                          item-text="email"
                                          item-value="id"
                                          required
                                          clearable
                                          :menu-props="menuProps"
                                          filled
                                          hide-details
                                        ></v-autocomplete
                                      ></v-col>
                                      <v-col cols="6">
                                        <v-autocomplete
                                          dense
                                          label="Head of content"
                                          v-model="item.head_of_content_user_id"
                                          :items="roles.items"
                                          item-text="email"
                                          item-value="id"
                                          required
                                          clearable
                                          :menu-props="menuProps"
                                          filled
                                          hide-details
                                          class="reset-vuetify-input"
                                        ></v-autocomplete>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </div>
                        </template>
                        <template v-else>
                          <div class="text-center">
                            <span style="opacity: 0.5">
                              Add new subproject...
                            </span>
                          </div>
                        </template>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions
                        v-if="subprojects && subprojects.length > 1"
                      >
                        <v-spacer />
                        <v-chip
                          label
                          class="flat-chip flat-chip--primary"
                          @click="subprojects.push(subprojectTemplate)"
                        >
                          Add subproject
                          <v-icon right small>mdi-plus</v-icon>
                        </v-chip>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="2">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px">
                        Users with access
                      </v-card-title>
                      <v-card-subtitle>
                        Select users who will have access to the project
                      </v-card-subtitle>
                      <v-divider />
                      <v-card-text>
                        <v-autocomplete
                          :loading="loading.roles"
                          v-model="roles.value"
                          :items="roles.items"
                          item-text="name"
                          item-value="id"
                          chips
                          hide-details
                          :menu-props="{
                            maxHeight: '200',
                            rounded: 'lg',
                            transition: 'slide-y-transition',
                            contentClass: 'shadow-e2-bordered remove-scrollbar',
                          }"
                          clearable
                          deletable-chips
                          filled
                          dense
                          multiple
                          placeholder="Select"
                          label="Users"
                        >
                          <template #selection="{ item, index }">
                            <span v-if="index <= 3">
                              <v-chip small>
                                {{ item.name }}
                              </v-chip>
                            </span>
                            <span
                              v-if="index === 4"
                              class="grey--text text-caption"
                            >
                              (+{{ roles.value.length - 3 }} more)
                            </span>
                          </template>
                        </v-autocomplete>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="3">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px">
                        Google Analytics
                      </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                              label="Profile Id"
                              v-model="analytics.ga.id"
                              filled
                              hide-details
                              dense
                              clearable
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              label="Event category"
                              v-model="analytics.ga.event_category"
                              filled
                              hide-details
                              dense
                              clearable
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="4">
              <v-container fluid>
                <v-row dense>
                  <v-col cols="12">
                    <v-textarea
                      :loading="loading.main_data"
                      auto-grow
                      clearable
                      name="input-7-1"
                      label="Competitors"
                      v-model="brand_analysis.competitors"
                      hide-details
                      filled
                    ></v-textarea>
                    <v-alert text type="info">
                      <div style="font-size: 12px"><b>Example</b></div>
                      <div>
                        www.example.com, www.example.com, www.example.com
                      </div>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px">
                        Organic
                      </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-autocomplete
                              label="Language"
                              v-model="project_data.language"
                              :items="languages"
                              item-value="id"
                              item-text="name"
                              filled
                              dense
                              :menu-props="menuProps"
                              hide-details
                              clearable
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              label="Period parsing"
                              v-model="brand_analysis.organic.period"
                              :items="period_parsing"
                              item-value="text"
                              item-text="text"
                              filled
                              dense
                              :menu-props="menuProps"
                              hide-details
                              clearable
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              label="Parsing top"
                              v-model="brand_analysis.organic.top"
                              :items="top_parsing"
                              item-value="value"
                              item-text="text"
                              filled
                              dense
                              :menu-props="menuProps"
                              hide-details
                              clearable
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px"> Ad </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-autocomplete
                              label="Language"
                              v-model="project_data.language"
                              :items="languages"
                              item-value="id"
                              item-text="name"
                              filled
                              hide-details
                              :menu-props="menuProps"
                              dense
                              clearable
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              label="Period parsing"
                              v-model="brand_analysis.ad.period"
                              :items="period_parsing"
                              item-value="text"
                              item-text="text"
                              filled
                              :menu-props="menuProps"
                              hide-details
                              dense
                              clearable
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              label="Parsing top"
                              v-model="brand_analysis.ad.top"
                              :items="top_parsing"
                              item-value="value"
                              item-text="text"
                              filled
                              hide-details
                              :menu-props="menuProps"
                              dense
                              clearable
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              label="Location"
                              v-model="brand_analysis.ad.location"
                              filled
                              hide-details
                              :menu-props="menuProps"
                              dense
                              clearable
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="5">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      :loading="loading.main_data"
                      auto-grow
                      clearable
                      name="input-7-1"
                      label="Competitors"
                      v-model="serp.competitors"
                      hide-details
                      filled
                    ></v-textarea>
                    <v-alert text type="info">
                      <div style="font-size: 12px"><b>Example</b></div>
                      <div>
                        www.example.com, www.example.com, www.example.com
                      </div>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="6">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px">
                        Page Groups
                        <v-spacer />
                        <v-chip
                          label
                          class="flat-chip flat-chip--primary"
                          @click="
                            content_checker_categories.push({
                              name: 'Test',
                              contain: '/test/',
                            })
                          "
                        >
                          Add group <v-icon right small>mdi-plus</v-icon>
                        </v-chip>
                      </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <template
                          v-if="
                            content_checker_categories &&
                            content_checker_categories.length > 0
                          "
                        >
                          <v-row
                            dense
                            v-for="(item, i) in content_checker_categories"
                            :key="i"
                          >
                            <v-col cols="12" md="5">
                              <v-text-field
                                label="Name"
                                v-model="item.name"
                                required
                                filled
                                dense
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                label="Contain"
                                v-model="item.contain"
                                required
                                filled
                                dense
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="1" class="text-center">
                              <v-btn
                                icon
                                class="ma-3"
                                @click="delete_category_content_checker(i)"
                              >
                                <v-icon dark> mdi-close </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-else>
                          <div class="text-center">
                            <span style="opacity: 0.5"> Add new group... </span>
                          </div>
                        </template>
                        <template
                          v-if="
                            content_checker_categories &&
                            content_checker_categories.length > 6
                          "
                        >
                          <div class="d-flex justify-center">
                            <v-chip
                              label
                              class="flat-chip flat-chip--primary"
                              @click="
                                content_checker_categories.push({
                                  name: 'Test',
                                  contain: '/test/',
                                })
                              "
                            >
                              Add group <v-icon right small>mdi-plus</v-icon>
                            </v-chip>
                          </div>
                        </template>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px">
                        Ignored Pages
                        <v-spacer />
                        <v-chip
                          label
                          class="flat-chip flat-chip--primary"
                          @click="
                            content_checker_ignored.push({
                              contain: '/test/',
                            })
                          "
                        >
                          Add page <v-icon right small>mdi-plus</v-icon>
                        </v-chip>
                      </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <template
                          v-if="
                            content_checker_ignored &&
                            content_checker_ignored.length > 0
                          "
                        >
                          <v-row
                            dense
                            v-for="(item, i) in content_checker_ignored"
                            :key="i"
                          >
                            <v-col cols="12" md="11">
                              <v-text-field
                                label="Contain"
                                v-model="item.contain"
                                required
                                filled
                                dense
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="1" class="text-center">
                              <v-btn
                                icon
                                class="ma-3"
                                @click="delete_ignored_content_checker(i)"
                              >
                                <v-icon dark> mdi-close </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-else>
                          <div class="text-center">
                            <span style="opacity: 0.5"> Add new page... </span>
                          </div>
                        </template>
                        <template
                          v-if="
                            content_checker_ignored &&
                            content_checker_ignored.length > 6
                          "
                        >
                          <div class="d-flex justify-center">
                            <v-chip
                              label
                              class="flat-chip flat-chip--primary"
                              @click="
                                content_checker_ignored.push({
                                  contain: '/test/',
                                })
                              "
                            >
                              Add page <v-icon right small>mdi-plus</v-icon>
                            </v-chip>
                          </div>
                        </template>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px">
                        Crawling Options
                      </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-switch
                          v-model="content_checker.status"
                          inset
                          hide-details
                          class="mt-0"
                          :label="content_checker.status ? 'Enable' : 'Disable'"
                        ></v-switch>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="7">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px">
                        Automatically tick the checkbox for links that are
                        uploaded to ltt by the SEO
                      </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-autocomplete
                          v-model="ltt.user"
                          :items="ltt.users"
                          item-text="name"
                          item-value="id"
                          label="Email"
                          filled
                          hide-details
                          chips
                          :menu-props="menuProps"
                          multiple
                          deletable-chips
                          small-chips
                        ></v-autocomplete>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px">
                        Redirect to the project
                        <v-spacer />
                        <v-icon size="20" color="blue lighten-2"
                          >mdi-arrow-decision</v-icon
                        >
                      </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-autocomplete
                          v-model="ltt.redirect"
                          :items="allowed_projects"
                          item-text="name"
                          item-value="id"
                          label="Redirect"
                          filled
                          hide-details
                          dense
                          :menu-props="menuProps"
                          chips
                          deletable-chips
                          small-chips
                        ></v-autocomplete>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px">
                        Mailer
                        <v-spacer />
                        <v-icon size="20" color="blue lighten-2"
                          >mdi-email-multiple-outline</v-icon
                        >
                      </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="12"
                            v-for="(type, i) in mailer"
                            :key="i"
                          >
                            <v-combobox
                              v-model="ltt.send_days[type.id]"
                              :items="ltt.week_days"
                              :label="type.name"
                              filled
                              dense
                              clearable
                              hide-details
                              deletable-chips
                              multiple
                              chips
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  :key="JSON.stringify(data.item)"
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  :disabled="data.disabled"
                                  @click:close="
                                    data.parent.selectItem(data.item)
                                  "
                                >
                                  <v-avatar
                                    class="accent white--text"
                                    left
                                    v-text="data.item.slice(0, 1).toUpperCase()"
                                  ></v-avatar>
                                  {{ data.item }}
                                </v-chip>
                              </template>
                            </v-combobox>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px">
                        Mailer
                        <v-spacer />
                        <v-icon size="20" color="blue lighten-2"
                          >mdi-cog-transfer-outline</v-icon
                        >
                      </v-card-title>
                      <v-card-subtitle>
                        Send an email with a change in status to the worse: for
                        those links that have disappeared.
                      </v-card-subtitle>
                      <v-divider />
                      <v-card-text>
                        <v-switch
                          v-model="ltt.mail"
                          inset
                          label="Mail"
                          class="mt-0"
                          hide-details
                        ></v-switch>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px" class="d-flex">
                        Last update of anchor type
                        <v-spacer />
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-chip small label v-on="on">
                              {{ ltt.last_update_of_anchor_type }}
                            </v-chip>
                          </template>
                          <div>
                            {{
                              $moment(ltt.last_update_of_anchor_type).calendar()
                            }}
                          </div>
                        </v-tooltip>
                      </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-switch
                          v-model="ltt.update_of_anchor_type"
                          inset
                          hide-details
                          class="mt-0"
                          label="Forced upgrade"
                        ></v-switch>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px" class="d-flex">
                        Min links parameters
                        <v-chip outlined small class="ml-2">crowd</v-chip>
                      </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-row
                          v-for="(item, i) in ltt.min_parameters.crowd"
                          :key="i"
                          class="align-center"
                        >
                          <v-col cols="12" md="6">
                            {{ item.name }}
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-if="item.join.value"
                              label="Contain word"
                              v-model="item.join.value"
                              required
                              filled
                              dense
                              hide-details
                            ></v-text-field>
                            <v-text-field
                              v-else
                              label="Contain word"
                              v-model="item.join[0].value"
                              required
                              filled
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card rounded="lg" elevation="0" class="border">
                      <v-card-title style="font-size: 14px" class="d-flex">
                        Min links parameters
                        <v-chip outlined small class="ml-2">guest_post</v-chip>
                      </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-row
                          v-for="(item, i) in ltt.min_parameters.guest_post"
                          :key="i"
                          class="align-center"
                        >
                          <v-col cols="12" md="6">
                            {{ item.name }}
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-if="item.join.value"
                              label="Contain word"
                              v-model="item.join.value"
                              required
                              filled
                              dense
                              hide-details
                            ></v-text-field>
                            <v-text-field
                              v-else
                              label="Contain word"
                              v-model="item.join[0].value"
                              required
                              filled
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="8">
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Task ID"
                      v-model="spiderlink.task_id"
                      filled
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="9">
              <v-container fluid>
                <app-comments-widget
                  :data="appComments"
                  :loading="loadings.comments"
                  @post="uploadNewComment"
                  @delete="deleteComment"
                />
              </v-container>
            </v-tab-item>
            <v-tab-item :key="10">
              <v-container fluid>
                <unique-check-widget
                  :model-value="uniqueCheckData"
                  @update:modelValue="uniqueCheckData = $event"
                />
              </v-container>
            </v-tab-item>
            <v-tab-item :key="11">
              <v-container fluid>
                <v-card rounded="lg" elevation="0" class="border">
                  <v-card-title style="font-size: 14px" class="d-flex">
                    Set links planning
                    <v-spacer />
                    <v-chip
                      label
                      class="flat-chip flat-chip--primary"
                      @click="handleAddLink"
                    >
                      Add new <v-icon right small>mdi-plus</v-icon>
                    </v-chip>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row v-if="links.length === 0" class="py-2">
                      <v-col cols="12" class="d-flex justify-center">
                        <span style="opacity: 0.5"
                          ><v-icon>mdi-calendar-remove</v-icon> Links planing is
                          empty...</span
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        v-for="(link, idx) in links"
                        :key="`${link.id}${idx}`"
                        cols="12"
                      >
                        <v-row dense class="align-center">
                          <v-col>
                            <v-autocomplete
                              v-model="link.type"
                              hide-details
                              :items="filtersData.types"
                              :menu-props="menuProps"
                              dense
                              filled
                              label="Type"
                              placeholder="Select"
                            ></v-autocomplete>
                          </v-col>
                          <v-col>
                            <v-text-field
                              label="Amount"
                              filled
                              placeholder="Enter amount"
                              hide-details
                              dense
                              v-model="link.amount"
                              type="number"
                              append-icon="mdi-number"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-menu
                              :close-on-content-click="false"
                              content-class="my-shadow--e3"
                            >
                              <template #activator="{ on }">
                                <div v-on="on">
                                  <v-text-field
                                    v-model="link.period"
                                    label="Period"
                                    filled
                                    readonly
                                    hide-details
                                    dense
                                  >
                                  </v-text-field>
                                </div>
                              </template>
                              <v-card class="styled-card--default">
                                <v-card-text class="pa-0">
                                  <v-date-picker
                                    v-model="link.period"
                                    type="month"
                                  ></v-date-picker>
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </v-col>
                          <v-col class="flex-shrink-1" style="max-width: 44px">
                            <v-btn icon @click="handleRemoveLinkById(link.id)">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        v-if="links && links.length > 6"
                        cols="12"
                        class="d-flex justify-center align-center"
                      >
                        <v-chip
                          label
                          class="flat-chip flat-chip--primary"
                          @click="handleAddLink"
                        >
                          Add new <v-icon right small>mdi-plus</v-icon>
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large class="px-6 text-normal" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn
            dark
            large
            @click="save_data"
            :disabled="dialog_buttons_disable"
            :loading="dialog_save"
            color="success"
            class="text-normal px-6"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <add-project-dialog
      v-model="isAddProjectDialogVisible"
      @created="fetchData"
    />
  </div>
</template>

<script>
import axios from "axios";
import eventPipe from "../../../events/eventPipe";
import { PROJECT_ID_IDTF } from "@/utils/defaultData";
import Location from "@/mixins/Location";
import AppCommentsWidget from "@/components/UI/AppCommentsWidget";
import UniqueCheckWidget from "../../App/UniqueCheckWidget";
import Project from "../../../mixins/Project";
import { uid } from "@/utils/functions";
import { DEFAULT_MENU_PROPS } from "@/utils/defaultData";
import Loader from "../Loader.vue";
import service from "../../../plugins/service";
import AddProjectDialog from "./AddProjectDialog.vue";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";

export default {
  name: "ProjectEdit",
  props: {
    newDesign: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AddProjectDialog,
    Loader,
    UniqueCheckWidget,
    AppCommentsWidget,
  },
  data: () => ({
    isAddProjectDialogVisible: false,
    searchModel: null,
    menuProps: DEFAULT_MENU_PROPS,
    links: [],
    loading: {
      main_data: true,
      roles: true,
      content_checker: true,
      spiderlink: true,
    },
    appComments: [],
    select: ["mon"],
    items: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
    roles: {
      value: null,
      items: [],
    },
    responsibility: {
      "junior seo": [],
      "chief seo": [],
      "seo manager": [],
    },
    project_data: {
      name: null,
      url: null,
      niche: null,
      status: null,
      country: null,
      language: null,
      regional_feature: null,
      max_links_24_hours: null,
      responsible_user_id: null,
      project_category_id: null,
      publishing_responsible_user_id: null,
      brief_creator_user_id: null,
      content_tl_user_id: null,
      head_of_content_user_id: null,
    },
    subprojects: [],
    analytics: {
      ga: {
        id: null,
        event_category: null,
      },
    },
    subprojectTemplate: {
      name: "Unnamed subproject",
      url_pattern: "/test/",
    },
    content_checker_categories: [
      {
        name: "Test",
        contain: "/test/",
      },
    ],
    content_checker_ignored: [],
    content_checker: {
      ignored: [],
      categories: [],
      status: false,
    },
    serp: {
      competitors: null,
    },
    ltt: {
      users: [],
      min_parameters: {
        guest_post: [],
        crowd: [],
      },
      send_days: [],
      week_days: [],
      user: [],
      redirect: false,
      mail: false,
      last_update_of_anchor_type: "",
      update_of_anchor_type: false,
    },
    mailer: [
      { id: 1, name: "Report submission for indexing links" },
      { id: 2, name: "Deleted links" },
    ],
    brand_analysis: {
      competitors: null,
      organic: {
        lang: null,
        period: "6h",
        top: 20,
      },
      ad: {
        lang: null,
        period: "6h",
        top: 20,
        location: null,
      },
    },
    spiderlink: {
      task_id: 0,
    },
    loadings: {
      comments: false,
      projects: false,
      projectData: false,
    },
    filtersData: {
      project_status: [],
      types: [],
    },
    uniqueCheckData: {},
    tab: null,
    dialog_save: false,
    dialog: false,
    dialog_buttons_disable: false,
    period_parsing: [
      { value: 15, text: "15m" },
      { value: 30, text: "30m" },
      { value: 60, text: "1h" },
      { value: 120, text: "2h" },
      { value: 360, text: "6h" },
    ],
    top_parsing: [
      { value: 20, text: 20 },
      { value: 100, text: 100 },
    ],
  }),
  mixins: [Location, Project, UploadFiltersDataMixin],
  watch: {
    $route(route) {
      if (route.query.project) {
        const projectQuery = route.query.project;
        const allowed_projects = this.$store.state.project.allowed_projects;
        let selected_project = allowed_projects.find((project) => {
          if (project.id === projectQuery) {
            return project;
          }
          if (project.domain === projectQuery) {
            return project;
          }
        });
        this.selected_project = selected_project.id;
      }
    },
  },
  computed: {
    computedAllowedProjects() {
      let projects = [];

      projects = [...this.$store.state.project.allowed_projects];

      const prioritySorting = (a, b) => b.status - a.status;

      projects = projects.toSorted((a, b) => {
        return (b.priority || 0) - (a.priority || 0) || b.status - a.status;
      });

      const activeProjects = projects.filter((project) => project.status === 1);
      const pausedProjects = projects.filter((project) => project.status === 2);
      const disabledProjects = projects.filter(
        (project) => project.status === 0
      );

      const result = [
        ...activeProjects.toSorted(prioritySorting),
        ...pausedProjects.toSorted(prioritySorting),
        ...disabledProjects.toSorted(prioritySorting),
      ];

      return result.map((project) => {
        return {
          text: project.domain,
          value: project.id,
          _ctx: { ...project },
        };
      });
    },

    getFilteredProjects() {
      if (!this.searchModel) return this.allowed_projects;

      const searchStr = String(this.searchModel).toLowerCase();

      return this.allowed_projects.filter((v) => {
        const domain = String(v.domain).toLowerCase();
        const name = String(v.name).toLowerCase();
        const id = String(v.id).toLowerCase();

        return (
          id.includes(searchStr) ||
          domain.includes(searchStr) ||
          name.includes(searchStr)
        );
      });
    },
    getHeaders() {
      return {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };
    },
    is_dark_mode() {
      return this.$vuetify.theme.dark;
    },
    countries() {
      return this.$store.state.reuse.countries;
    },
    languages() {
      return this.$store.state.reuse.languages;
    },
    niches() {
      return this.$store.state.reuse.niches;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
    allowed_projects: {
      get: function () {
        return this.$store.state.project.allowed_projects;
      },
      set: function (projects) {
        this.$store.state.project.allowed_projects = projects;
      },
    },
    getProjectLabel() {
      if (!this._$isActiveProjectAllowed)
        return "No access to the selected project";

      return "Select project";
    },
    selected_project: {
      get: function () {
        const projectId = this.$store.state.project.active_project;
        if (
          projectId === null ||
          projectId === undefined ||
          projectId === "undefined" ||
          projectId === "null"
        )
          return null;

        return parseInt(this.$store.state.project.active_project);
      },
      set: function (value) {
        this.set_active_project(value);
      },
    },
  },
  methods: {
    toggleMenu(item) {
      if (item.menu) {
        return this.$set(item, "menu", false);
      }
      return this.$set(item, "menu", true);
    },
    handleAddProject() {
      this.isAddProjectDialogVisible = true;
    },
    getDomainInitials(name) {
      const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

      let initials = [...name.matchAll(rgx)] || [];

      initials = (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();

      return initials;
    },
    strToRGB(string) {
      let hash = 0;
      if (string.length === 0) return hash;
      for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
      }
      let rgb = [0, 0, 0];
      for (let i = 0; i < 3; i++) {
        rgb[i] = (hash >> (i * 8)) & 255;
      }
      return {
        red: rgb[0],
        green: rgb[1],
        blue: rgb[2],
      };
    },
    // pastelColorByStr(input_str) {
    //   var baseRed = 220;
    //   var baseGreen = 220;
    //   var baseBlue = 220;
    //
    //   //lazy seeded random hack to get values from 0 - 256
    //   //for seed just take bitwise XOR of first two chars
    //   var seed = input_str.charCodeAt(0) ^ input_str.charCodeAt(1);
    //   var rand_1 = Math.abs(Math.sin(seed++) * 10000) % 256;
    //   var rand_2 = Math.abs(Math.sin(seed++) * 10000) % 256;
    //   var rand_3 = Math.abs(Math.sin(seed++) * 10000) % 256;
    //
    //   //build colour
    //   var red = Math.round((rand_1 + baseRed) / 2);
    //   var green = Math.round((rand_2 + baseGreen) / 2);
    //   var blue = Math.round((rand_3 + baseBlue) / 2);
    //
    //   return { red: red, green: green, blue: blue };
    // },
    handleRemoveLinkById(id) {
      this.links = this.links.filter((v) => v.id !== id);
    },
    handleAddLink() {
      this.links.push({
        id: uid(),
        type: null,
        amount: null,
        period: null,
      });
    },
    trashIconAnimationEnter(event) {
      const button = event.target.querySelector('[data-trash-icon=""]');
      button.classList.replace("mdi-delete", "mdi-delete-empty");
    },
    trashIconAnimationLeave(event) {
      const button = event.target.querySelector('[data-trash-icon=""]');
      button.classList.replace("mdi-delete-empty", "mdi-delete");
    },
    set_active_project(id) {
      this.$store.dispatch("update_active_project", id);
      this.$store.dispatch("update_domain");
      eventPipe.$emit("update-active-project");
      this._$setQueryParams(PROJECT_ID_IDTF, id);
    },
    delete_subproject(index) {
      this.subprojects = this.subprojects.filter((v, i) => i !== index);
    },
    delete_category_content_checker(index) {
      this.content_checker_categories = this.content_checker_categories.filter(
        (v, i) => i !== index
      );
    },
    delete_ignored_content_checker(index) {
      this.content_checker_ignored = this.content_checker_ignored.filter(
        (v, i) => i !== index
      );
    },
    save_data() {
      let self = this;

      self.dialog_save = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      self.content_checker.ignored = self.content_checker_ignored;
      self.content_checker.categories = self.content_checker_categories;

      let data = {
        project_data: self.project_data,
        responsibility: {
          ...self.responsibility,
          "chief seo": [self.responsibility["chief seo"]],
        },
        subprojects: self.subprojects,
        analytics: self.analytics,
        brand_analysis: self.brand_analysis,
        roles: self.roles,
        serp: self.serp,
        content_checker: self.content_checker,
        ltt: self.ltt,
        links_planing: self.links,
        spiderlink: self.spiderlink,
        unique_check: self.uniqueCheckData,
      };

      axios
        .put(
          `${self.$store.state.server_url}/project/${self.id_project}`,
          data,
          config
        )
        .then(() => {
          this.$message.notification({
            title: "Done",
            text: "Settings successfully saved.",
            type: "success",
          });
        })
        .catch(() => {
          this.$message.notification({
            title: "Something wrong",
            text: "Try again later",
            type: "error",
          });
        })
        .finally(() => {
          self.dialog_save = false;
        });
    },
    open_dialog() {
      let self = this;

      self.dialog = true;
      self.dialog_buttons_disable = true;
      self.loadings.projectData = true;

      this.fetchComments();

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .get(
          `${self.$store.state.server_url}/project/${self.id_project}`,
          config
        )
        .then((r) => {
          let project_data = r.data.project_data;
          let brand_analysis = r.data.brand_analysis;
          let project_settings = r.data.project_settings;
          let ltt_settings = r.data.ltt;

          this.filtersData.project_status = r.data.project_status;
          this.filtersData.types = r.data.types;

          self.links = r.data.links_planing ?? [];
          self.uniqueCheckData = r.data.unique_check;
          self.project_data.name = project_data.name ?? null;
          self.project_data.redirect_to = project_data.redirect_to;
          self.project_data.status = project_data.status ?? null;
          self.project_data.url = project_data.url ?? null;
          self.project_data.niche = project_data.niche_id ?? null;
          self.project_data.regional_feature =
            project_data.region_sign_url ?? null;
          self.project_data.max_links_24_hours =
            project_data.max_links_24_hours ?? null;
          self.project_data.country = project_data.country_id ?? null;
          self.project_data.language = project_data.country_lang_id ?? null;
          // self.project_data.responsible_user_id = project_data.responsible_user_id ?? null
          self.project_data.project_category_id =
            project_data.project_category_id ?? null;

          self.responsibility = r.data.responsibility;

          self.responsibility["chief seo"] =
            r.data.responsibility["chief seo"][0];

          self.subprojects = r.data.subprojects;

          self.analytics.ga.id = project_settings.analytics.ga_id ?? null;
          self.analytics.ga.event_category =
            project_settings.analytics.ga_event_category ?? null;

          self.ltt.users = ltt_settings.users;
          self.ltt.send_days = ltt_settings.send_days;
          self.ltt.week_days = ltt_settings.week_days;
          self.ltt.min_parameters.guest_post =
            ltt_settings.min_parameters.guest_post;
          self.ltt.min_parameters.crowd = ltt_settings.min_parameters.crowd;

          self.ltt.user = ltt_settings.user_id;
          self.ltt.redirect = ltt_settings.redirect;
          self.ltt.mail = ltt_settings.mail;
          self.ltt.last_update_of_anchor_type =
            ltt_settings.last_update_of_anchor_type;

          self.spiderlink = project_settings.spiderlink ?? {};

          self.serp.competitors = project_settings.serp.competitors ?? null;

          if (project_settings.content_checker !== undefined) {
            self.content_checker_categories =
              project_settings.content_checker.categories ?? [];
            self.content_checker_ignored =
              project_settings.content_checker.ignored ?? [];
          }

          self.content_checker.status =
            !!project_data.available_parser ?? false;

          if (brand_analysis !== null) {
            self.brand_analysis.competitors =
              brand_analysis.competitors ?? null;

            self.brand_analysis.ad.period = brand_analysis.ad_period ?? null;
            self.brand_analysis.ad.top = brand_analysis.ad_top ?? null;

            self.brand_analysis.organic.period =
              brand_analysis.organic_period ?? null;
            self.brand_analysis.organic.top =
              brand_analysis.organic_top ?? null;
          }
        })
        .finally(() => {
          self.dialog_buttons_disable = false;
          self.loading.main_data = false;
          self.loadings.projectData = false;
        });

      if (self.countries.length === 0) {
        this.$store.dispatch("fetch_countries_list");
      }

      if (self.languages.length === 0) {
        this.$store.dispatch("fetch_languages_list");
      }

      if (self.niches.length === 0) {
        this.$store.dispatch("fetch_niches_list");
      }

      if (!self.id_project) return;

      axios
        .get(
          `${self.$store.state.server_url}/project/get-users/${self.id_project}`,
          config
        )
        .then((r) => {
          self.roles.items = r.data.map((v) => {
            return {
              id: v.id,
              name: v.name,
              email: v.email,
            };
          });
          self.roles.value = r.data
            .filter((v) => v.checked === 1)
            .map((v) => v.id);
        })
        .finally(() => {
          self.loading.roles = false;
        });
    },
    async fetchData() {
      eventPipe.$on("replace-project", (e) => (this.selected_project = e));

      try {
        const url = "/project";

        this.loadings.projects = true;

        const response = await service.get(url);

        if (response) {
          this.allowed_projects = response.data;
          this.$store.dispatch("update_domain");
          eventPipe.$emit("update-active-project");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.projects = false;
      }

      // let self = this;
      //
      // let config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: self.$store.state.auth.token,
      //   },
      // };
      //
      // axios.get(`${self.$store.state.server_url}/project`, config).then((r) => {
      //   self.allowed_projects = r.data;
      //   self.$store.dispatch("update_domain");
      //   eventPipe.$emit("update-active-project");
      // });
    },
    async deleteComment(id) {
      this.loadings.comments = true;

      const url =
        this.$store.state.server_url + "/delete-project-comments/" + id;

      const config = this.getHeaders;

      await axios.delete(url, config);

      await this.fetchComments();

      this.loadings.comments = false;
    },
    async uploadNewComment(comment) {
      this.loadings.comments = true;

      const url =
        this.$store.state.server_url +
        "/set-project-comments/" +
        this.$store.getters.active_project;

      const config = this.getHeaders;

      const resp = await axios.post(url, comment, config);
      if (resp) {
        console.log("resp : ", resp);
      }

      await this.fetchComments();

      this.loadings.comments = false;
    },
    async fetchComments() {
      try {
        this.loadings.comments = true;

        const url =
          "/get-project-comments/" + this.$store.getters.active_project;

        const config = this.getHeaders;

        const resp = await service.get(url, true, config);
        if (resp) {
          this.appComments = this.sortByDate(resp.data);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.comments = false;
      }
    },
    sortByDate(arr) {
      return arr.sort((a, b) => {
        const aval = a.date === null ? a.created_at : a.date;
        const bval = b.date === null ? b.created_at : b.date;
        return new Date(aval).getTime() - new Date(bval).getTime();
      });
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryObject = Object.fromEntries(urlParams.entries());

    this.uploadFiltersData(["subprojects", "project_categories", "niches"]);

    if (queryObject[PROJECT_ID_IDTF]) {
      this._$setActiveProject(Number(queryObject[PROJECT_ID_IDTF]), false);
    }
  },
};
</script>

<style lang="scss">
.project-edit {
  &__bg-gradient {
    pointer-events: none;
    position: absolute;
    width: 523px;
    height: 1190px;
    top: -590px;
    left: -120px;
    transform: rotate(74.748deg);
    border-radius: 1190px;
  }
}
</style>
