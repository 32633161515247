import Service from "../../../plugins/service";

export const DomainSubfolderApiService = () => {
  const getAll = () => {
    return Service.get(
      "/domain-subfolders",
      true,
      {},
      { disableTossError: true }
    );
  };

  const create = (payload) => {
    return Service.post(
      "/domain-subfolders",
      payload,
      true,
      {},
      {
        disableTossError: true,
      }
    );
  };

  const update = (payload) => {
    return Service.put(
      `/domain-subfolders/${payload.id}`,
      payload,
      true,
      {},
      {
        disableTossError: true,
      }
    );
  };

  return {
    getAll,
    create,
    update,
  };
};
