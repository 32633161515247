export const CONTENT_FIELD_TYPES = Object.freeze({
  EMAIL: 1,
  USERS: 2,
  URL: 3,
  TEXT_FIELD: 4,
  TEXTAREA: 5,
  LANGUAGE: 6,
  PRICE: 7,
  NUMBER: 8,
  TIME: 9,
  FREELANCER: 10,
});

export const EVENTS = {
  SET_FORM_DATA: "element-control-form:set-form-data",
  UPDATE_CFP_MODAL: "element-control-form:update-modal-data",
};
