<template>
  <div ref="parent">
    <apexcharts
      v-if="series && labels"
      ref="chart"
      height="298"
      type="line"
      :options="getOptions"
      :series="getSeries"
    ></apexcharts>
  </div>
</template>

<script>
import apexcharts from "vue-apexcharts";
export default {
  props: {
    series: {
      type: Array,
    },
    labels: {
      type: Array,
    },
  },
  components: { apexcharts },
  computed: {
    getSeries() {
      return this.series.map((item) => {
        return {
          color: item.color,
          data: item.items.map((v) => +v),
          name: item.title,
        };
      });
    },
    getOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          height: 298,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -5,
          background: {
            enabled: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["rgba(155,155,155,0.15)", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          labels: {
            show: false,
          },
          categories: this.labels,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
        },
      };
    },
  },
};
</script>
