<template>
  <div :class="`${computedColor}--text`">
    {{ getValue }}
    <v-icon
      :color="computedColor"
      small
      style="margin-left: -4px; margin-bottom: 2px"
      >{{ computedIcon }}</v-icon
    >
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    reverseColor: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getValue() {
      if (!this.value) return "";

      const result = String(this.value).replaceAll("-", "");

      if (result === "0") return "";

      return result;
    },
    computedColor() {
      const value = this.reverseColor ? this.value * -1 : this.value;

      if (value > 0) return "success";
      if (value < 0) return "error";

      return "";
    },
    computedIcon() {
      if (this.value > 0) return "mdi-triangle-small-up";
      if (this.value < 0) return "mdi-triangle-small-down";

      return "";
    },
  },
};
</script>
