import { DEFAULT_MOMENT_DATE_FORMAT } from "../../utils/defaultData";

export const pageConfig = {
  breadcrumbs: [
    {
      text: "Home",
      to: "/",
    },
    {
      text: "4DX",
      disabled: true,
    },
    {
      text: "Tribe dashboard",
      disabled: true,
    },
  ],
};

export const RELATED_DASHBOARD_DATA = (types, moment) => ({
  [types.LINKS]: {
    // TODO - на ltt.link-dynamics-report добавить фильтр subprojects
    redirectTo: "ltt.link-dynamics-report",
    filters: [
      {
        from: {
          key: "subprojects",
        },
        to: {
          key: "subprojects",
        },
        formater: (value) => value?.join(",") || value,
      },
      {
        value: [
          moment().startOf("month").format(DEFAULT_MOMENT_DATE_FORMAT),
          moment().endOf("month").format(DEFAULT_MOMENT_DATE_FORMAT),
        ],
        to: {
          key: "date_range",
        },
        formater: (value) => value?.join(",") || value,
      },
    ],
  },
  [types.PAGES_INFO]: {
    redirectTo: "dashboards.pages-info",
    filters: [
      {
        from: {
          key: "subprojects",
        },
        to: {
          key: "sub_project",
        },
        formater: (value) => value?.join(",") || value,
      },
    ],
  },
  [types.CONTENT]: {
    // TODO - на dashboards.content-planing добавить фильтр subprojects
    redirectTo: "dashboards.content-planing",
    filters: [
      {
        value: "17",
        from: {
          key: "data_source",
        },
        to: {
          key: "data_source",
        },
      },
      {
        value: [
          moment().startOf("month").format(DEFAULT_MOMENT_DATE_FORMAT),
          moment().endOf("month").format(DEFAULT_MOMENT_DATE_FORMAT),
        ],
        to: {
          key: "date",
        },
        formater: (value) => value?.join(",") || value,
      },
      {
        from: {
          key: "subprojects",
        },
        to: {
          key: "subprojects",
        },
        formater: (value) => value?.join(",") || value,
      },
    ],
  },
  [types.SW]: {
    redirectTo: "site-workplace.site-worksheet",
    filters: [
      {
        from: {
          key: "subprojects",
        },
        to: {
          key: "sub_project",
        },
        formater: (value) => value?.join(",") || value,
      },
      {
        value: [
          moment().startOf("month").format(DEFAULT_MOMENT_DATE_FORMAT),
          moment().endOf("month").format(DEFAULT_MOMENT_DATE_FORMAT),
        ],
        to: {
          key: "deadline",
        },
        formater: (value) => value?.join(",") || value,
      },
    ],
  },
  [types.POSITIONS]: {
    // TODO One year report принимает только один саб-проект в виде фильтра
    redirectTo: "positions.dashboard.v2",
    filters: [
      {
        from: {
          key: "subprojects",
        },
        to: {
          key: "subproject",
        },
        formater: (value) => value[0],
      },
    ],
  },
  [types.NEED_TOP]: {
    redirectTo: "dashboards.urls-overview",
    filters: [
      {
        from: {
          key: "subprojects",
        },
        to: {
          key: "subproject",
        },
        formater: (value) => value?.join(",") || value,
      },
      {
        from: {
          key: "need_top",
        },
        to: {
          key: "need_top",
        },
        formater: (value) => value?.join(",") || value,
      },
    ],
  },
  [types.CONVERSION]: {
    redirectTo: "analytics.conversion.top-5-pages-conversion",
    filters: [
      {
        from: {
          key: "subprojects",
        },
        to: {
          key: "subproject",
        },
        formater: (value) => value?.join(",") || value,
      },
    ],
  },
});
