<template>
  <v-dialog
    v-model="dialog"
    content-class="my-shadow--e2"
    fullscreen
    scrollable
  >
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2 bold">
        Add domain
        <v-spacer />
        <v-icon @click="dialog = false" small>mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-row dense>
          <v-col cols="4">
            <div>
              Enter string with data and press "Transform string to items"
            </div>
            <v-textarea
              v-model="stringToParse"
              label="Enter string"
              hide-details="auto"
              dense
              outlined
              :error-messages="stringToParseErrors"
            />
            <div class="mt-2">
              <v-alert text rounded="lg" color="info">
                <template #prepend>
                  <v-icon small color="info"> mdi-information-outline </v-icon>
                </template>
                <div class="text-body-2 ml-3">
                  <div><b>Format</b> – Domain Pattern Country Language</div>
                  <div class="mt-2"><b>Example</b></div>
                  <div>domain1.com /b US en</div>
                  <div>domain2.com /b US en</div>
                </div>
              </v-alert>
            </div>
            <div class="d-flex mt-2">
              <v-spacer />
              <v-btn
                class="text-normal"
                @click="
                  handleTransformStringToItems(
                    stringToParse,
                    () => (stringToParse = '')
                  )
                "
              >
                Transform string to items
                <v-icon small right>mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="8">
            <div>Or manually add the domains</div>
            <v-card flat outlined rounded="lg">
              <v-card-title class="text-body-2 py-2 pr-2">
                Domains list
                <v-spacer />
                <div class="d-flex" style="gap: 0.5rem">
                  <v-btn
                    class="text-normal flat-chip flat-chip--error"
                    @click="
                      items = [];
                      validationResult = {};
                    "
                  >
                    Remove all <v-icon small right>mdi-trash-can</v-icon>
                  </v-btn>
                  <v-btn class="text-normal" @click="handleAdd">
                    Add <v-icon small right>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-form class="formRef">
                  <div
                    v-if="!items?.length"
                    class="text-center text-body-2"
                    style="opacity: 0.5"
                  >
                    No items. You can add new one...
                  </div>

                  <transition-group v-else name="slide-y" tag="div" class="row">
                    <v-col
                      v-for="(item, idx) in items"
                      :key="`item-${idx}`"
                      cols="12"
                    >
                      <v-row dense>
                        <v-col cols="fill">
                          <v-text-field
                            v-model="item.domain"
                            label="Domain"
                            hide-details="auto"
                            dense
                            outlined
                            :error-messages="
                              validationResult[`items.${idx}.domain`]
                            "
                            @change="resetErrors(`items.${idx}.domain`)"
                          />
                        </v-col>
                        <v-col cols="fill">
                          <v-text-field
                            v-model="item.pattern"
                            label="Pattern"
                            hide-details="auto"
                            dense
                            outlined
                          />
                        </v-col>
                        <v-col cols="fill">
                          <v-text-field
                            v-model="item.country"
                            label="Country"
                            hide-details="auto"
                            dense
                            outlined
                            :error-messages="
                              validationResult[`items.${idx}.country`]
                            "
                            @change="resetErrors(`items.${idx}.country`)"
                          />
                        </v-col>
                        <v-col cols="fill">
                          <v-text-field
                            v-model="item.language"
                            label="Language"
                            hide-details="auto"
                            dense
                            outlined
                            :error-messages="
                              validationResult[`items.${idx}.language`]
                            "
                            @change="resetErrors(`items.${idx}.language`)"
                          />
                        </v-col>
                        <v-col cols="auto">
                          <v-btn
                            style="min-width: 40px"
                            height="40"
                            class="px-2"
                            color="error"
                            text
                            @click="handleRemove(item)"
                            :loading="pending"
                          >
                            <v-icon small>mdi-close</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </transition-group>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn large class="px-6 text-normal" @click="dialog = false">
          Close
        </v-btn>
        <v-btn
          large
          class="px-6 text-normal"
          color="success"
          @click="handleSave"
          :loading="pending"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../../../mixins/Dialog";
import { findMostPopularDivider, uid } from "../../../../../utils/functions";
import { DomainSubfolderApiService } from "../../../services/DomainSubfolderApiService";
import Notification from "../../../../../mixins/Notification";
import ApiErrorHandlingMixin from "../../../../../mixins/ApiErrorHandlingMixin";

export default {
  props: ["filtersData"],

  mixins: [Dialog, Notification, ApiErrorHandlingMixin],

  data: () => ({
    stringToParse: "",
    stringToParseErrors: [],
    pending: false,
    itemTemplate: {
      domain: "",
      pattern: "",
      country: null,
      language: null,
    },
    items: [],
  }),

  methods: {
    uid,

    handleAdd() {
      this.items.push({ ...this.itemTemplate, _id: uid() });
      this.validationResult = {};
    },

    handleRemove({ _id }) {
      this.items = this.items.filter((item) => item._id !== _id);
      this.validationResult = {};
    },

    handleTransformStringToItems(string, cb) {
      if (!string) return;

      const divider = findMostPopularDivider(string);

      const rows = string.split("\n");

      const items = rows.map((row) => {
        const data = row.split(divider);
        return {
          domain: data[0],
          pattern: data[1],
          country: data[2],
          language: data[3],
          _id: uid(),
        };
      });

      this.items = [...this.items, ...items];

      this.validationResult = {};

      if (cb !== undefined) cb();
    },

    async handleSave() {
      try {
        this.pending = true;

        await DomainSubfolderApiService().create({ items: this.items });

        this.items = [];
        this.dialog = false;

        this.mSuccess({
          title: "Success",
          text: "Domain successfully added.",
        });

        this.$emit("success");
      } catch (e) {
        console.error("Error while saving new items.", e);
        this.handleApiError(e);
      } finally {
        this.pending = false;
      }
    },
  },
};
</script>
