<template>
  <v-dialog v-model="dialog" width="820" content-class="my-shadow--e2">
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2">
        Dynamic by week
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <apexchart
          class="visible-overflow"
          :options="computedOptions"
          :series="computedSeries"
        ></apexchart>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import Dialog from "../../../mixins/Dialog";

export default {
  props: ["data"],

  mixins: [Dialog],

  components: { apexchart: VueApexCharts },

  computed: {
    computedSeries() {
      return this?.data?.series || [];
    },
    computedOptions() {
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        yaxis: {
          max: this.computedMaxSeriesValue,
        },
        xaxis: {
          categories: this?.data?.categories || [],
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      };
    },

    computedMaxSeriesValue() {
      return this?.data?.maxValue || 20;
    },
  },
};
</script>
